import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

const StyledNavbarTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    height: '6px',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 100,
    width: '100%',
    borderRadius: '44px',
    backgroundColor: '#fff',
  },
  '& .MuiTabs-flexContainer': {
    // maxWidth: 40,
    justifyContent: 'space-between',
  },
});

const StyledNavbarTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontFamily: 'Calibri',
    fontSize: '20px',
    textTransform: 'none',
    fontWeight: 100,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',
    marginRight: theme.spacing(1),
    opacity: 0.75,
    color: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      opacity: 1,
      color: '#fff',
      fontWeight: 'bold',
    },
    '&.Mui-selected': {
      opacity: 1,
      color: '#fff',
      fontWeight: 'bold',
    },
    '&.Mui-focusVisible': {
      border: '1px dotted #fff',
      borderRadius: '92px',
    },
  }),
);

const ListerStyledNavbarTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontSize: '20px',
  textTransform: 'none',
  fontWeight: 600,
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
  marginRight: theme.spacing(1),
  opacity: 0.75,
  color: '#C5C2EA',
  '&:hover': {
    opacity: 1,
    color: '#fff',
    fontWeight: 'bold',
  },
  '&.Mui-selected': {
    opacity: 1,
    color: '#fff',
    fontWeight: 'bold',
  },
  '&.Mui-focusVisible': {
    border: '1px dotted #fff',
    borderRadius: '92px',
  },
}));

const GeekTrustStyledNavbarTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '20px',
  textTransform: 'none',
  fontWeight: 400,
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
  marginRight: theme.spacing(1),
  opacity: 0.75,
  color: '#BDBDBD',
  '&:hover': {
    opacity: 1,
    color: '#fff',
    fontWeight: 400,
  },
  '&.Mui-selected': {
    opacity: 1,
    color: '#fff',
    fontWeight: 400,
  },
  '&.Mui-focusVisible': {
    border: '1px dotted #fff',
    borderRadius: '92px',
  },
}));

export default function CompanyEventNavbar({
  navbarIndex,
  logo,
  data,
  route,
  variant,
  ...props
}) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const imageLogo = getImage(logo);
  const location = useLocation();

  useEffect(() => {
    const index = data.findIndex((ele) => ele.anchor == location.hash);
    if (index !== -1) {
      setValue(index);
    }
  }, [location.hash]);

  useEffect(() => {
    setValue(navbarIndex);
  }, [navbarIndex]);

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    } else {
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
    }
  }, [open]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // OnScroll event handler
  // const onScroll = () => {
  //   // Get scroll value
  //   const scroll = document.documentElement.scrollTop;

  //   // If scroll value is more than 0 - add class
  //   if (scroll > 100) {
  //     setScrolling(true);
  //   } else {
  //     setScrolling(false);
  //   }
  // };

  // useEffect(() => {
  //   const throttledOnScroll = throttle(onScroll, 100, {});
  //   window.addEventListener('scroll', throttledOnScroll);
  // }, []);

  const getTabs = (variant) => {
    switch (variant) {
      case 'LISTER':
        return (
          <StyledNavbarTabs
            className="hidden lg:block"
            value={value}
            onChange={handleChange}
            aria-label="Navigation"
          >
            {data.map((ele, idx) => {
              return (
                <ListerStyledNavbarTab
                  onClick={() => {
                    if (Boolean(ele.anchor)) scrollTo(ele.anchor);
                  }}
                  to={`${
                    ele.anchor?.includes('#')
                      ? route + (ele.anchor ? ele.anchor : '/')
                      : ele.anchor
                  }`}
                  key={idx}
                  src={ele.anchor?.includes('#') ? '' : ele.anchor}
                  target={ele.anchor?.includes('#') ? '_self' : '_blank'}
                  component={Link}
                  label={ele.content}
                  className={`mt-2 px-10 ${idx == 1 && '-translate-x-24'}`}
                />
              );
            })}
          </StyledNavbarTabs>
        );

      case 'GEEK_TRUST':
        return (
          <StyledNavbarTabs
            className="hidden lg:block"
            value={value}
            onChange={handleChange}
            aria-label="Navigation"
          >
            {data.map((ele, idx) => {
              return (
                <GeekTrustStyledNavbarTab
                  onClick={() => {
                    if (Boolean(ele.anchor)) scrollTo(ele.anchor);
                  }}
                  to={`${
                    ele.anchor?.includes('#')
                      ? route + (ele.anchor ? ele.anchor : '/')
                      : ele.anchor
                  }`}
                  key={idx}
                  src={ele.anchor?.includes('#') ? '' : ele.anchor}
                  target={ele.anchor?.includes('#') ? '_self' : '_blank'}
                  component={Link}
                  label={ele.content}
                  className={`mt-2 px-10 ${idx == 1 && '-translate-x-24'}`}
                />
              );
            })}
          </StyledNavbarTabs>
        );

      default:
        return (
          <StyledNavbarTabs
            className="hidden lg:block"
            value={value}
            onChange={handleChange}
            aria-label="Navigation"
          >
            {data.map((ele, idx) => {
              return (
                <StyledNavbarTab
                  onClick={() => {
                    if (Boolean(ele.anchor)) scrollTo(ele.anchor);
                  }}
                  to={`${
                    ele.anchor?.includes('#')
                      ? route + (ele.anchor ? ele.anchor : '/')
                      : ele.anchor
                  }`}
                  key={idx}
                  src={ele.anchor?.includes('#') ? '' : ele.anchor}
                  target={ele.anchor?.includes('#') ? '_self' : '_blank'}
                  component={Link}
                  label={ele.content}
                  className={`mt-2 px-10 ${idx == 1 && '-ml-10'} ${
                    idx == 2 && 'ml-10'
                  }`}
                />
              );
            })}
          </StyledNavbarTabs>
        );
    }
  };

  const getFooterImage = (variant) => {
    switch (variant) {
      case 'LISTER':
        return (
          <div className="absolute bottom-8 left-0 right-0 flex justify-center">
            <StaticImage
              imgStyle={{ objectFit: 'contain' }}
              loading="lazy"
              src="../../../images/lister/Hero.png"
              placeholder="blurred"
              width={200}
              className="-mt-8"
              alt="Mobile Navbar"
            />
          </div>
        );

      case 'GEEK_TRUST':
        return (
          <div className="absolute bottom-8 left-0 right-0 flex justify-center">
            <StaticImage
              imgStyle={{ objectFit: 'contain' }}
              loading="lazy"
              src="../../../images/geektrust/Hero.png"
              placeholder="blurred"
              width={300}
              alt="Mobile Navbar"
            />
          </div>
        );

      default:
        return (
          <div className="absolute bottom-0 left-0 right-0 flex justify-center">
            <StaticImage
              imgStyle={{ objectFit: 'contain' }}
              loading="lazy"
              src="../../../images/phonepe/Hero.png"
              placeholder="blurred"
              width={600}
              alt="Mobile Navbar"
            />
          </div>
        );
    }
  };

  const getHeadingStyle = (variant) => {
    switch (variant) {
      case 'LISTER':
        return 'font-nunito-sans text-lister-purple-300';

      case 'GEEK_TRUST':
        return 'font-nunito-sans text-lister-purple-300';

      default:
        return '';
    }
  };

  return (
    <section className={`relative  ${styles.navbar} ${props.className}`}>
      <div className="-mt-8">
        <GatsbyImage
          loading="eager"
          image={imageLogo}
          alt={'Logo'}
          className=" absolute left-0  right-0 z-20 ml-auto mr-auto w-[60px]   lg:w-[100px] "
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <div className="container flex justify-end lg:block">
        {getTabs(variant)}
        <FontAwesomeIcon
          size="2x"
          className="lg:hidden"
          icon={['far', 'bars']}
          onClick={() => {
            setOpen(true);
          }}
        ></FontAwesomeIcon>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{
              ease: 'easeOut',
              type: 'linear',
            }}
            exit={{ x: 500 }}
            className={` z-20 ${
              variant === 'GEEK_TRUST'
                ? styles.geekTrustMobileNavbar
                : styles.mobileNavbar
            }`}
          >
            <div className="relative">
              <div className="absolute right-5 top-3 opacity-50">
                <FontAwesomeIcon
                  size="2x"
                  icon={['fas', 'times']}
                  onClick={() => {
                    setOpen(false);
                  }}
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="-mt-48 flex h-full w-full flex-col items-center justify-center gap-y-7">
              {data.map((ele, idx) => (
                <Link
                  target={ele.anchor?.includes('#') ? '_self' : '_blank'}
                  onClick={() => {
                    setOpen(false);
                    if (Boolean(ele.anchor)) scrollTo(ele.anchor);
                  }}
                  to={`${
                    ele.anchor?.includes('#')
                      ? route + (ele.anchor ? ele.anchor : '/')
                      : ele.anchor
                  }`}
                  key={idx}
                >
                  <h3 className={`${getHeadingStyle(variant)}`}>
                    {ele.content}
                  </h3>
                </Link>
              ))}
            </div>
            {getFooterImage(variant)}
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
