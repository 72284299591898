import React, { useState } from 'react';
import Accordion from './Accordion/index';
import {
  links,
  placementLinks,
  programLinks,
} from '@src/constants/NavigationLinks/index';
import AuthButtons from '../AuthButtons/index';
import { Link } from 'gatsby';

/**
 * NavLinksMobile component renders the navigation links for mobile devices.
 * Parent Component: Navbar
 *
 * @param {boolean} dark - Indicates whether the theme is dark or light.
 * @param {boolean} hideCTA - Indicates whether to hide the call-to-action buttons.
 * @param {boolean} shouldOpenLeadForm - Indicates whether the lead form should be opened.
 * @returns {JSX.Element} - NavLinksMobile component JSX.
 */
export default function NavLinksMobile({ dark, hideCTA, shouldOpenLeadForm }) {
  const [expanded, setExpanded] = useState(false);

  /**
   * handleChange function handles the expansion changes of the accordion.
   *
   * @param {string} panel - Identifier of the accordion panel.
   * @returns {function} - Event handler function.
   */
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {/* Render the accordion for Programs */}
      <div className="programs flex items-center justify-between">
        <Accordion
          name="Program"
          id="programs"
          className="programAccordionSummary"
          expanded={expanded}
          handleChange={handleChange}
          linksArray={programLinks}
          style={{
            padding: '0',
            margin: '0',
            minHeight: '0px',
          }}
        />
      </div>
      {/* Render navigation links */}
      {links.map((el, idx) => (
        <Link to={el.link} key={idx}>
          {el.name}
        </Link>
      ))}
      {/* Render the accordion for Placements */}
      <Accordion
        name="Placement"
        id="placements"
        className="programAccordionSummary"
        linksArray={placementLinks}
        expanded={expanded}
        handleChange={handleChange}
        style={{
          padding: '0',
          margin: '0',
          fontWeight: 'bold',
          fontSize: '18px',
          minHeight: '0px',
        }}
      />
      {/* <Accordion
        name="Resources"
        id="resources"
        className="programAccordionSummary"
        linksArray={resourcesLink}
        expanded={expanded}
        handleChange={handleChange}
        style={{
          padding: '0',
          margin: '0',
          fontWeight: 'bold',
          fontSize: '18px',
          minHeight: '0px',
        }}
      /> */}
      {/* Render authentication buttons */}
      <AuthButtons
        dark={dark}
        hideCTA={hideCTA}
        signInBtnClassName={'w-full'}
      />
    </>
  );
}
