import React from 'react';
import { cn } from '@/lib/utils';
import Layout from '@components/v5/layouts/Layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionHeading from '../SectionHeading';
import useResizer from '@components/extra/useResizer';

export default function MasterclassSectionLayout({
  className,
  heading,
  children,
  sectionImage,
  doesSectionHaveBackground,
  doesSectionHaveListItems,
  isSectionHeaderWrapped,
  isHeadingWithoutBackgroundOnMobile,
}) {
  const isMobile = useResizer();
  return (
    <Layout className={`masterclass ${className}`}>
      <div
        className={cn(
          'flex flex-col md:gap-[40px]',
          doesSectionHaveBackground &&
            'bg-v5-neutral-200 px-3 shadow-[0px_4px_9.4px_0px_#00000014]',
          doesSectionHaveListItems
            ? 'gap-[10px] lg:gap-[12px]'
            : 'gap-[18px] lg:gap-[30px]',
          isHeadingWithoutBackgroundOnMobile ? 'gap-[12px] lg:gap-[30px]' : '',
        )}
      >
        {heading && (
          <div
            className={isSectionHeaderWrapped && isMobile ? 'container' : ''}
          >
            <SectionHeading
              heading={heading}
              doesSectionHaveBackground={doesSectionHaveBackground}
              isHeadingWithoutBackgroundOnMobile={
                isHeadingWithoutBackgroundOnMobile
              }
            />
          </div>
        )}
        {sectionImage && (
          <GatsbyImage
            image={sectionImage}
            alt={'Masterclass Banner'}
            className="lg:mt-2"
          />
        )}
        {children}
      </div>
    </Layout>
  );
}
