import { cn } from '@/lib/utils';
import React from 'react';
import RawHTML from '@components/Common/RawHTML/index';

/**
 * A functional component that renders a layout with a header and a subheader.
 * @param {Object} props - The props object.
 * @param {string} props.header - The HTML content for the header.
 * @param {string} props.sub - The HTML content for the subheader.
 * @param {string} props.containerStyle - Additional styles for the container.
 * @param {string} props.headerStyle - Additional styles for the header.
 * @param {string} props.subStyle - Additional styles for the subheader.
 * @returns {JSX.Element} The rendered TitleDescriptionLayout component.
 */

export default function TitleDescriptionLayout({
  header,
  sub,
  containerStyle,
  headerStyle,
  subStyle,
}) {
  return (
    <div className={cn(containerStyle, 'font-manrope')}>
      <div className={cn(headerStyle, 'font-extrabold')}>
        <RawHTML>{header}</RawHTML>
      </div>
      <div className={cn(subStyle, 'font-medium')}>
        <RawHTML>{sub}</RawHTML>
      </div>
    </div>
  );
}
