export const generateMeta = (heroSectionImg) => {
  return [
    {
      property: `og:image`,
      content: `https://strapi.internal.crio.do/uploads/${heroSectionImg}`,
    },
    {
      property: 'og:url',
      content: `https://www.crio.do/masterclass`,
    },
    {
      name: `twitter:image`,
      content: `https://strapi.internal.crio.do/uploads/${heroSectionImg}`,
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ];
};
