import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { AnimatePresence, motion } from 'framer-motion';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import CompanyEventCTAButton from '../CompanyEventCTAButton/index';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import { navigate } from 'gatsby'; //import navigate from gatsby
import LoadingButton from '@mui/lab/LoadingButton';
import { InView } from 'react-intersection-observer';
import CTA_Arrow from '../../../images/lister/CTA_Arrow.svg';
import GeekTrust_CTA_Arrow from '../../../images/geektrust/CTA_Arrow.svg';

const Input = styled('input')({
  display: 'none',
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '92px 0px 0px 92px',
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    maxWidth: '180px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function CompanyEventCTA({ link, className, ...props }) {
  const [dirty, setDirty] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const inputRef = useRef();

  useEffect(() => {
    if (Boolean(state.email)) {
      setEmail(state.email);
    }
    if (dirty) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
      dispatch({
        type: 'USER_IS_APPLYING',
        payload: true,
      });
    } else {
      dispatch({
        type: 'USER_IS_APPLYING',
        payload: false,
      });
    }
  }, [dirty]);

  const handleSubmit = (email, link, props) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);
    storeEmail(email);
    sendToCMS(email, props);
    fireWebengageEvents(email, props);
    if (link) {
      navigateToTypeform(link, props);
    }
  };

  const storeEmail = (email) => {
    dispatch({ type: 'SET_EMAIL', payload: email });
    GTM.setEmail(email);
  };

  const fireWebengageEvents = (email, props) => {
    GTM.login({
      userId: email,
      email: email,
    });

    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: props.type,
      location: props.location,
      ...(props.meId && { meId: props.meId }),
      ...(props.secondaryType && { secondaryType: props.secondaryType }),
    });
  };
  const sendToCMS = (email, props) => {
    let temp = '';
    var clone = Object.assign({}, props);
    clone.email = email;
    clone.adblocker = typeof window.webengage == 'undefined' ? true : false;
    clone.utm = encodeURIComponent(state.utm);
    clone.url = window.location.href;

    Object.keys(clone).map((ele, idx) => {
      if (clone[ele] != undefined && clone[ele]?.length != 0) {
        temp += `${ele}=${clone[ele]}`;
        if (idx != Object.keys(clone).length - 1) {
          temp += '&';
        }
      }
    });

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&${temp}`,
      );
    }
  };

  const navigateToTypeform = (link, props) => {
    navigate('/registration', {
      state: {
        link: link,
        type: props.type,
        ...(props.secondaryType && { secondaryType: props.secondaryType }),
        location: props.location,
      },
    });
  };

  const getButton = (secondaryType, className, props) => {
    switch (secondaryType) {
      case 'PHONEPE':
        return (
          <>
            <AnimatePresence>
              {dirty ? (
                <div className="overflow-hidden">
                  <motion.div
                    variants={{
                      initial: {
                        y: 50,
                        opacity: 0,
                      },

                      animate: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          ease: 'easeOut',
                          duration: 0.5,
                          type: 'tween',
                        },
                      },
                    }}
                    initial="initial"
                    animate="animate"
                  >
                    <form
                      className={`${
                        className ? className : ''
                      } flex items-center `}
                      onSubmit={(e) => {
                        e.preventDefault();
                        let email = e.target.email.value.trim().toLowerCase();
                        handleSubmit(email, link, props);
                      }}
                    >
                      <FormControl variant="standard" required>
                        <BootstrapInput
                          disabled={loading}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          ref={inputRef}
                          placeholder="Enter email"
                          type="email"
                          name="email"
                          autoFocus
                          required
                        />
                      </FormControl>
                      <LoadingButton
                        type="submit"
                        loading={loading}
                        disabled={loading}
                        variant="contained"
                        style={{
                          borderRadius: '0px 92px 92px 0px',
                          padding: '10px 0px',
                          height: '45px',
                        }}
                        className={`group w-max whitespace-nowrap bg-phonepe-accent-300 font-bold  text-phonepe-primary-purple-800 ${
                          loading && 'bg-opacity-60'
                        } px-3 font-uni-neue capitalize  `}
                        disableElevation
                        {...props}
                      >
                        <div
                          className={`${
                            loading && 'invisible'
                          } flex items-center gap-x-2`}
                        >
                          Apply Now{' '}
                          <FontAwesomeIcon
                            className="text-lg transition-all group-hover:translate-x-1	"
                            icon={['fal', 'long-arrow-right']}
                          ></FontAwesomeIcon>
                        </div>
                      </LoadingButton>
                    </form>
                  </motion.div>
                </div>
              ) : (
                <CompanyEventCTAButton
                  className={className}
                  onClick={() => {
                    setDirty(true);
                  }}
                  {...props}
                ></CompanyEventCTAButton>
              )}
            </AnimatePresence>
          </>
        );

      case 'LISTER':
        return (
          <>
            <AnimatePresence>
              {dirty ? (
                <InView
                  onChange={(isVisible) => {
                    if (!isVisible) {
                      setDirty(false);
                    }
                  }}
                >
                  <div className="overflow-hidden">
                    <motion.div
                      variants={{
                        initial: {
                          y: 50,
                          opacity: 0,
                        },

                        animate: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            ease: 'easeOut',
                            duration: 0.5,
                            type: 'tween',
                          },
                        },
                      }}
                      initial="initial"
                      animate="animate"
                    >
                      <form
                        className={`${
                          className ? className : ''
                        } flex items-center `}
                        onSubmit={(e) => {
                          e.preventDefault();
                          let email = e.target.email.value.trim().toLowerCase();
                          handleSubmit(email, link, props);
                        }}
                      >
                        <FormControl variant="standard" required>
                          <BootstrapInput
                            disabled={loading}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            ref={inputRef}
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            autoFocus
                            required
                          />
                        </FormControl>
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          disabled={loading}
                          variant="contained"
                          style={{
                            borderRadius: '0px 92px 92px 0px',
                            padding: '10px 0px',
                            height: '45px',
                          }}
                          className={`group w-max whitespace-nowrap bg-lister-other-200 font-bold  text-lister-purple-100 ${
                            loading && 'bg-opacity-60'
                          } px-3 font-nunito-sans capitalize  `}
                          disableElevation
                          {...props}
                        >
                          <div
                            className={`${
                              loading && 'invisible'
                            } flex items-center gap-x-2`}
                          >
                            Apply Now{' '}
                            <CTA_Arrow className="text-lg transition-all group-hover:translate-x-1	" />
                            {/* <FontAwesomeIcon
                              className="text-lg transition-all group-hover:translate-x-1	"
                              icon={['fal', 'long-arrow-right']}
                            ></FontAwesomeIcon> */}
                          </div>
                        </LoadingButton>
                      </form>
                    </motion.div>
                  </div>
                </InView>
              ) : (
                <CompanyEventCTAButton
                  className={className}
                  onClick={() => {
                    setDirty(true);
                  }}
                  {...props}
                ></CompanyEventCTAButton>
              )}
            </AnimatePresence>
          </>
        );

      case 'GEEK_TRUST':
        return (
          <>
            <AnimatePresence>
              {dirty ? (
                <InView
                  onChange={(isVisible) => {
                    if (!isVisible) {
                      setDirty(false);
                    }
                  }}
                >
                  <div className="overflow-hidden">
                    <motion.div
                      variants={{
                        initial: {
                          y: 50,
                          opacity: 0,
                        },

                        animate: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            ease: 'easeOut',
                            duration: 0.5,
                            type: 'tween',
                          },
                        },
                      }}
                      initial="initial"
                      animate="animate"
                    >
                      <form
                        className={`${
                          className ? className : ''
                        } flex items-center `}
                        onSubmit={(e) => {
                          e.preventDefault();
                          let email = e.target.email.value.trim().toLowerCase();
                          handleSubmit(email, link, props);
                        }}
                      >
                        <FormControl variant="standard" required>
                          <BootstrapInput
                            disabled={loading}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            ref={inputRef}
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            autoFocus
                            required
                          />
                        </FormControl>
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          disabled={loading}
                          variant="contained"
                          style={{
                            borderRadius: '0px 92px 92px 0px',
                            padding: '10px 0px',
                            height: '45px',
                          }}
                          className={`group w-max whitespace-nowrap bg-geek-trust-green-200 font-bold  text-geek-trust-purple-200 ${
                            loading && 'bg-opacity-60'
                          } px-3 font-nunito-sans capitalize  `}
                          disableElevation
                          {...props}
                        >
                          <div
                            className={`${
                              loading && 'invisible'
                            } flex items-center gap-x-2`}
                          >
                            Apply Now{' '}
                            <GeekTrust_CTA_Arrow className="text-lg transition-all group-hover:translate-x-1	" />
                            {/* <FontAwesomeIcon
                              className="text-lg transition-all group-hover:translate-x-1	"
                              icon={['fal', 'long-arrow-right']}
                            ></FontAwesomeIcon> */}
                          </div>
                        </LoadingButton>
                      </form>
                    </motion.div>
                  </div>
                </InView>
              ) : (
                <CompanyEventCTAButton
                  className={className}
                  onClick={() => {
                    setDirty(true);
                  }}
                  {...props}
                ></CompanyEventCTAButton>
              )}
            </AnimatePresence>
          </>
        );

      default:
        return <></>;
    }
  };
  return getButton(props.secondaryType, className, props);
}
