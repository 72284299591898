import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { EScholarship } from '@src/constants/sale/index';

const SaleStrip = ({ children, data }) => {
  const { title, highlightText, subtitle, program } = data;

  return (
    <section className="bg-sale-blue-300">
      <div className="pt-10 pb-10 ">
        <div className="container">
          <div className="flex flex-col justify-center gap-x-12 md:flex-row">
            <div className="mx-4 mb-4 inline-block rounded-md py-1 text-center text-lg text-yearOfDoingSale-neutral-100 md:hidden">
              {highlightText}
            </div>
            <div className="flex items-center justify-center">
              <div className="mb-4 w-[300px] px-16 md:mb-0 md:px-0">
                <StaticImage
                  loading="lazy"
                  src={'../../images/v5/Sale/MiddleStrip-Desktop.png'}
                  placeholder="blurred"
                  width={400}
                  alt="Cyber Sale - Build Professional Projects"
                />
                {/* <StaticImage
                  loading="lazy"
                  src={'../images/year-of-doing-sale/Motif.png'}
                  placeholder="blurred"
                  width={400}
                  alt="Cyber Sale - Build Professional Projects"
                /> */}
              </div>
            </div>
            <div className=" flex flex-col justify-center text-center md:text-left">
              <div
                className="mb-4 hidden rounded-md py-1 text-yearOfDoingSale-neutral-100 md:block"
                style={{ width: 'fit-content' }}
              >
                {highlightText}
              </div>
              <h2
                className={`px-4 font-dmSans text-xl font-normal leading-snug text-sale-orange-200 md:px-0 md:text-4xl`}
              >
                {title}
              </h2>
              <div className="mt-2 mb-6 font-inter text-yearOfDoingSale-neutral-100">
                {program === EProgramIDs.FREE_TRIAL ? (
                  <p className="">
                    Get upto{' '}
                    <u className="font-bold">
                      Rs. {EScholarship[EProgramIDs.FREE_TRIAL]} scholarship
                    </u>
                    . <br className="block md:hidden" />
                    <span className="text-sm font-normal md:text-base">
                      Limited Time Offer.
                    </span>
                  </p>
                ) : (
                  subtitle
                )}
              </div>
              <div className={`flex justify-center md:mt-4 md:justify-start`}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SaleStrip;
