import {
  calculateSessionEndTime,
  generateMeta,
  generateMetaDescription,
  generateScript,
  generateTitle,
} from '@src/utils/Masterclass/registerPageSeo';
import useRegisterMetadata from './useRegisterMetadata';
import React from 'react';

export default function useMasterclassRegisterSeo() {
  // Destructure necessary functions from useRegisterMetadata hook
  const {
    getEventDetails,
    getCurrentProgram,
    getEventCardSessionImageForSeo,
    getEventSlug,
  } = useRegisterMetadata();

  // Function to generate SEO data for the registration page
  const getRegisterationPageSeo = () => {
    // Get event details
    const {
      sessionName,
      isPastEvent,
      sessionHostName,
      sessionStartDateTime,
      createdAt,
    } = getEventDetails();

    // Get additional metadata
    const program = getCurrentProgram();
    const eventSlug = getEventSlug();
    const eventCardSessionImage = getEventCardSessionImageForSeo();
    const sessionEndTime = calculateSessionEndTime(sessionStartDateTime);
    const script = generateScript(
      sessionName,
      isPastEvent,
      program,
      sessionStartDateTime,
      sessionEndTime,
      eventSlug,
      eventCardSessionImage,
      createdAt,
      sessionHostName,
    );

    // Generate SEO data
    return {
      title: generateTitle(sessionName, isPastEvent),
      metaDescription: generateMetaDescription(
        sessionName,
        program,
        isPastEvent,
      ),
      meta: generateMeta(eventCardSessionImage, eventSlug),
      script: <script type="application/ld+json">{script}</script>,
      canonicalUrl: `https://www.crio.do/masterclass/register/${eventSlug}`,
    };
  };

  return { getRegisterationPageSeo };
}
