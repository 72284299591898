import React, { useState, useContext, useEffect } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import LeadGenerationService from '../../../utils/lead-generation';
import {
  ERROR_LEAD_SOURCE,
  leadStage,
  LEAD_GEN_CHATBOT,
  LEAD_GEN_DEVICE_DESKTOP,
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_PATHNAME_FALLBACK,
  whitelistedLeadSources,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
} from '@src/constants/LeadGenerationConstants/index';
import { GTM } from '../../../src/analytics/gtm';
import { gtmEvents } from '../../../src/analytics/gtmEventsConstant';
import { Loading } from 'react-simple-chatbot';
import { useLocation } from '@reach/router';
import useResizer from '@components/extra/useResizer';

// This component acts as a wrapper to send async request to Lead Squared;
export default function ChatBotQuery(props) {
  const globalDispatch = useContext(GlobalDispatchContext);
  const [loading, setLoading] = useState(false);

  // Global state
  const globalState = useContext(GlobalStateContext);
  const storedParams = globalState.utm;
  const searchParam = new URLSearchParams(storedParams);

  // Path variables
  const location = useLocation();
  const pathName = location?.pathname;

  // Check if the current lead if of type QA
  const isMQALead =
    pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathName === LEAD_GEN_AUTOMATION_EDGE_PATH;

  // UTM variables
  const utmSource = searchParam.get('utm_source');
  const utmMedium = searchParam.get('utm_medium');
  const utmPublisher = searchParam.get('utm_publisher');
  const utmCampaign = searchParam.get('utm_campaign');
  const utmNetwork = searchParam.get('utm_network');
  const utmTerm = searchParam.get('utm_term');
  const utmContent = searchParam.get('utm_content');
  const gclID = searchParam.get('gclid');
  const lcID = searchParam.get('li_fat_id');
  const fbclID = searchParam.get('fbclid');
  const isMobile = useResizer();

  const userLocation = globalState.userLocation;

  const generateLeadWrapper = async () => {
    setLoading(true);

    const email = props.steps.email.value;
    const phone = '+91' + props.steps.phone.value;

    GTM.login({
      userId: email,
      email: email,
      phone: phone,
    });

    //GTM Event for Email Capture
    if (!globalState.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: 'CHATBOT',
      });
      globalDispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    const data = {
      email: email,
      phone: phone,
      location: LEAD_GEN_CHATBOT,
      formURL: pathName || LEAD_GEN_PATHNAME_FALLBACK,
      deviceType: isMobile ? LEAD_GEN_DEVICE_MOBILE : LEAD_GEN_DEVICE_DESKTOP,
      applicationStage: leadStage.OTHER_LEAD,
      dataSource: LEAD_GEN_DATA_SOURCE,
      program: isMQALead ? 'QA Automation' : 'Software Development',
    };

    // add UTM data
    if (utmSource) {
      if (whitelistedLeadSources.includes(utmSource))
        data.utmSource = utmSource;
      else data.utmSource = ERROR_LEAD_SOURCE;
    }
    if (utmMedium) data.utmMedium = utmMedium;
    if (utmPublisher) data.utmPublisher = utmPublisher;
    if (utmCampaign) data.utmCampaign = utmCampaign;
    if (utmContent) data.utmContent = utmContent;
    if (utmNetwork) data.utmNetwork = utmNetwork;
    if (utmTerm) data.utmTerm = utmTerm;
    if (gclID) data.gclID = gclID;
    if (lcID) data.lcID = lcID;
    if (fbclID) data.fbclID = fbclID;

    // Add user location data
    extendLeadObjectWithUserLocationData(data, userLocation);

    const lead = await LeadGenerationService.generateLead(data);
    const leadID = lead.data.leadID;

    // Set email, phone & docID details in global context
    globalDispatch({
      type: 'SET_EMAIL',
      payload: data.email,
    });
    GTM.setEmail(email);
    globalDispatch({
      type: 'SET_PHONE',
      payload: data.phone,
    });
    globalDispatch({
      type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
      payload: leadID,
    });

    setLoading(false);
    props.triggerNextStep();
  };

  useEffect(() => {
    generateLeadWrapper();
  }, []);

  return <div>{loading ? <Loading /> : <></>}</div>;
}
