import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { createWidget } from '@typeform/embed';
import React, { useContext, useEffect, useRef } from 'react';
import '@typeform/embed/build/css/widget.css';

export default function MyTypeform(props) {
  const typeformRef = useRef(null);
  const state = useContext(GlobalStateContext);
  useEffect(() => {
    let params = '';
    if (Boolean(props.params)) {
      params += Object.keys(props.params)
        .map((key) => `${key}=${encodeURIComponent(props.params[key])}`)
        .join('&');
    }
    if (state.utm?.length > 0) {
      params += '&' + state.utm;
    }
    createWidget(
      `${props.url}?${
        props.hideWelcomeScreen ? 'typeform-welcome=0' : ''
      }&${params}`,

      {
        container: typeformRef.current,
        hideFooter: props.hideFooter || false,
        hideHeaders: props.hideHeaders || false,
        hideScrollbars: props.hideScrollbars || false,
        height: props.height ? props.height : '100%',
        onSubmit: props.onSubmit,
        onReady: props.onReady,
      },
    );
    setTimeout(() => {
      if (document.querySelectorAll("[data-qa='mobile-modal']").length > 0) {
        document.querySelectorAll("[data-qa='mobile-modal']")[0].style.display =
          'none';
      }
    }, 1000);
  }, [typeformRef]);

  return (
    <div
      ref={typeformRef}
      //   className="typeform-widget"
      style={{ height: props.height ? props.height : '100%', width: '100%' }}
    ></div>
  );
}
