import React from 'react';
import CurriculumDetails from '../CurriculumDetails/index';

export default function SuperDreamJobCurriculum({
  curriculum,
  type,
  superDreamJobRange,
}) {
  return (
    <>
      <h1
        className="mt-10 mb-12 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl"
        id="#advanced"
      >
        Additional{' '}
        <span className="text-v5-green-100">advanced curriculum</span> to crack{' '}
        <br className="hidden sm:block"></br>super-dream jobs (
        {superDreamJobRange}) <br className="hidden sm:block"></br>at Unicorns
        and tech giants
      </h1>
      <div className="my-10 rounded-[20px] bg-v5-green-500 text-white">
        <div className="flex w-full flex-wrap items-stretch gap-y-4  gap-x-4 pt-4 text-v5-neutral-200 sm:px-6">
          <CurriculumDetails curriculumData={curriculum} type={type} />
        </div>
      </div>
    </>
  );
}
