import React from 'react';
import * as styles from './index.module.scss';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import twConfig from '../../../../utils/tailwind-fullConfig';

const StyledScheduleTabs = styled((props) => (
  <Tabs
    variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    // maxWidth: 40,
    backgroundColor: twConfig.theme.colors['phonepe-primary-purple']['700'],
    borderRadius: '15px',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
});

const StyledScheduleTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontFamily: 'Calibri',
    fontSize: '20px',
    textTransform: 'none',
    fontWeight: 100,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',
    color: twConfig.theme.colors['phonepe-primary-purple']['150'],
    whiteSpace: 'nowrap',
    '&.MuiTab-root': { minWidth: '10px' },
    '&.Mui-selected': {
      color: twConfig.theme.colors['phonepe-primary-purple']['800'],
      backgroundColor: twConfig.theme.colors['phonepe-primary-pink']['100'],
      fontWeight: 'bold',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#fff',
    },
  }),
);

const ListerStyledScheduleTabs = styled((props) => (
  <Tabs
    variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    // maxWidth: 40,
    backgroundColor: twConfig.theme.colors['lister-purple']['250'],
    borderRadius: '30px',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
});

const ListerStyledScheduleTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontSize: '20px',
  textTransform: 'none',
  fontWeight: 800,
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
  color: twConfig.theme.colors['lister-purple']['500'],
  whiteSpace: 'nowrap',
  '&.MuiTab-root': { minWidth: '10px' },
  '&.Mui-selected': {
    color: twConfig.theme.colors['lister-purple']['100'],
    backgroundColor: twConfig.theme.colors['lister-other']['300'],
    fontWeight: 800,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff',
  },
}));

const GeekTrustStyledScheduleTabs = styled((props) => (
  <Tabs
    variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    // maxWidth: 40,
    backgroundColor: twConfig.theme.colors['geek-trust']['green']['100'],
    borderRadius: '30px',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
});

const GeekTrustStyledScheduleTab = styled((props) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '20px',
  textTransform: 'none',
  fontWeight: 500,
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
  color: twConfig.theme.colors['geek-trust']['neutral']['400'],
  whiteSpace: 'nowrap',
  '&.MuiTab-root': { minWidth: '10px' },
  '&.Mui-selected': {
    color: twConfig.theme.colors['geek-trust']['neutral']['100'],
    backgroundColor: twConfig.theme.colors['geek-trust']['green']['200'],
    fontWeight: 800,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff',
  },
}));

export default function CompanyEventScheduleTabs({
  data,
  index,
  onChange,
  variant,
}) {
  switch (variant) {
    case 'LISTER':
      return (
        <ListerStyledScheduleTabs
          value={index}
          className={`w-full  md:block`}
          onChange={(event, index) => onChange(index)}
          aria-label="Navigation"
        >
          {data.map((ele, idx) => {
            return (
              <ListerStyledScheduleTab
                key={idx}
                label={ele.label}
                className={`${styles.tab} text-[16px] md:text-lg`}
              />
            );
          })}
        </ListerStyledScheduleTabs>
      );

    case 'GEEK_TRUST':
      return (
        <GeekTrustStyledScheduleTabs
          value={index}
          className={`w-full  md:block`}
          onChange={(event, index) => onChange(index)}
          aria-label="Navigation"
        >
          {data.map((ele, idx) => {
            return (
              <GeekTrustStyledScheduleTab
                key={idx}
                label={ele.label}
                className={`${styles.tab} text-[16px] md:text-lg`}
              />
            );
          })}
        </GeekTrustStyledScheduleTabs>
      );

    default:
      return (
        <StyledScheduleTabs
          value={index}
          className={`w-full  md:block`}
          onChange={(event, index) => onChange(index)}
          aria-label="Navigation"
        >
          {data.map((ele, idx) => {
            return (
              <StyledScheduleTab
                key={idx}
                label={ele.label}
                className={`${styles.tab} text-[16px] md:text-lg`}
              />
            );
          })}
        </StyledScheduleTabs>
      );
  }
}
