import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import React from 'react';
import { useLocation } from '@reach/router';

export default function ApplyAtCrioTopStrip() {
  const location = useLocation();

  const handleTopStripClick = () => {
    // Scroll to the #workwithus section
    const workWithUsSection = document.getElementById('workwithus');
    if (workWithUsSection) {
      workWithUsSection.scrollIntoView({ behavior: 'smooth' });
    }

    // Track the event:
    GTM.track(gtmEvents.TOP_STRIP_CTA_CLICK, {
      pathname: location.pathname,
      location: 'top_strip',
    });
  };

  return (
    <div
      className={
        'flex min-h-[44px] w-full cursor-pointer items-center justify-between bg-v5-green-100 pl-4 pr-0 text-center font-rubik text-[12px] text-v5-green-700 md:gap-6 md:py-0 md:px-0 md:text-[18px]'
      }
      onClick={handleTopStripClick}
    >
      <div class="w-full">
        <div>
          Join Our Journey At Crio.Do |{' '}
          <strong>
            <u>Apply Now</u>
          </strong>
        </div>
      </div>
    </div>
  );
}
