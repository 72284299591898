import React, { useContext, useState } from 'react';
import '@szhsin/react-menu/dist/index.css';
import CrioButton from '@components/v5/CrioButton/index';
import { graphql, useStaticQuery } from 'gatsby';
import BottomSheet from '@components/BottomSheet/index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { GlobalDispatchContext } from '@src/context/GlobalContextProvider';
import NavbarLayout from './NavbarLayout/index';
import CrioLogo from './CrioLogo/index';
import MobileMenuToggle from './MobileMenuToggle/index';
import NavLinksDesktop from './NavLinksDesktop/index';
import ResponsiveLayout from './ResponsiveLayout/index';
import NavLinksMobile from './NavLinksMobile/index';

/**
 * Navbar component renders the navigation bar.
 * Parent Component: NavigationV2
 *
 * @param {boolean} hideCTA - Indicates whether to hide the call-to-action buttons.
 * @param {boolean} dark - Indicates whether the theme is dark or light.
 * @param {string} CTAProgram - Program identifier for the call-to-action button.
 * @returns {JSX.Element} - Navbar component JSX.
 */
const Navbar = ({ hideCTA, dark, CTAProgram }) => {
  // State variables for managing the menu toggle and bottom sheet visibility
  const [open, setOpen] = useState(false);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const dispatch = useContext(GlobalDispatchContext);

  // Fetching data for the trial program
  const programQueryData = useStaticQuery(graphql`
    query {
      allStrapiPrograms {
        ...allStrapiProgramsFragment
      }
    }
  `);

  const trialProgram = programQueryData.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID === 'FREE_TRIAL',
  );

  // Props for the bottom sheet component
  const bottomSheetProps = {
    program: trialProgram,
    type: trialProgram.PrimaryProgramID,
    meId: trialProgram.PrimaryProgramID,
    link: trialProgram.typeform,
    location: 'HOME_PROJECTS',
    openEvent: 'FREE_ME_START_NOW_CLICKED',
    closeEvent: 'FREE_ME_EMAIL_FORM_CLOSED',
  };

  // Function to handle closing the bottom sheet
  const onClose = (bottomSheetProps) => {
    setShowBottomSheet(false);
    dispatch({
      type: 'USER_IS_APPLYING',
      payload: false,
    });
    let event = bottomSheetProps.closeEvent;
    // Track the closing event in Google Tag Manager
    GTM.track(gtmEvents[event], {
      type: bottomSheetProps.type,
      location: bottomSheetProps.location,
      ...(bottomSheetProps.meId && { meId: bottomSheetProps.meId }),
      ...(bottomSheetProps.secondaryType && {
        secondaryType: bottomSheetProps.secondaryType,
      }),
    });
  };

  return (
    <>
      {/* Render the navbar layout */}
      <NavbarLayout>
        <ResponsiveLayout dark={dark}>
          {/* Render the Crio logo */}
          <CrioLogo dark={dark} />

          <MobileMenuToggle open={open} setOpen={setOpen} />
          <NavLinksDesktop dark={dark} hideCTA={hideCTA} />
        </ResponsiveLayout>
        {/* Render navigation links for mobile if open */}
        {open && (
          <ResponsiveLayout isMobile dark={dark}>
            <NavLinksMobile dark={dark} hideCTA={hideCTA} />
          </ResponsiveLayout>
        )}
      </NavbarLayout>
      {/* Render bottom sheet if showBottomSheet is true */}
      {showBottomSheet && (
        <BottomSheet
          onClose={() => onClose(bottomSheetProps)}
          {...bottomSheetProps}
        >
          {/* Render a button inside the bottom sheet */}
          {React.cloneElement(
            <CrioButton variant="primary" className="w-full">
              Book Your Trial
            </CrioButton>,
            {
              ...bottomSheetProps,
            },
          )}
        </BottomSheet>
      )}
    </>
  );
};

export default Navbar;
