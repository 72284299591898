import React, { useEffect, useState } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import Skeleton from '@mui/material/Skeleton';
import loadable from '@loadable/component';
import { motion, useAnimation } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import pMinDelay from 'p-min-delay';

const ProjectsCarousel = loadable(
  () => pMinDelay(import('../ProjectsCarousel/index'), 300),
  {
    fallback: (
      <div className="flex min-h-[380px] w-full justify-center sm:h-[425px]">
        <LoadingSkeleton></LoadingSkeleton>
      </div>
    ),
  },
);

const ProjectsSection = ({ projects, ...props }) => {
  return (
    <SectionLayout
      heading={
        <>
          Modern Work
          <br /> Experience-Based
          <br /> Learning Approach
        </>
      }
      description={
        <>
          At Crio, you learn and grow exactly how you would on a real job. You
          will start from the
          <br className="hidden md:block" /> fundamentals, receive support from
          our mentors and community, and{' '}
          <strong className="font-bold text-black">
            build your way to the top
          </strong>
          <br className="hidden md:block" /> - through professional work-like
          Full-stack and Backend web development projects.
        </>
      }
      {...props}
    >
      <div className="container">
        <div className="flex w-full flex-col items-center gap-4 ">
          <FadeInWhenVisible
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <ProjectsCarousel projects={projects} {...props} />
          </FadeInWhenVisible>
        </div>
      </div>
    </SectionLayout>
  );
};

function LoadingSkeleton() {
  const classes = useStyles();

  return (
    <div className=" flex h-full w-full items-center justify-between gap-x-6 overflow-hidden">
      {Array(3)
        .fill()
        .map((ele, idx) => (
          <div key={idx} className={`${classes.avatarSkeletonContainer}`}>
            <Skeleton className={classes.avatarLoader} animation="wave" />
          </div>
        ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  avatarSkeletonContainer: {
    height: 0,
    overflow: 'hidden',
    position: 'relative',
    minWidth: '255px',
    width: '100%',
    borderRadius: '20px',
    height: '400px',
    borderRadius: '20px',
  },
  avatarLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'scale(1)',
  },
}));

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [hasComeInViewOnce, setHasComeInViewOnce] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setHasComeInViewOnce(true);
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="w-full"
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      //   exit="hidden"
    >
      {hasComeInViewOnce ? props.children : ''}
    </motion.div>
  );
}

export default ProjectsSection;
