import { dataScienceProjects } from './data-science';

export const projectsData = {
  DATA_ANALYTICS: {
    projectTitle:
      'Industry-relevant Projects to Experience Working as Top Data Analysts',
    projectDescription: `<p>Learn and grow like you would on a real job with Crio’s <strong class="text-black font-bold">Data Analytics Program</strong>. Gain hands-on experience with real-world projects designed to prepare you for a successful career in data analytics</p>`,
    projects: dataScienceProjects,
  },
  SOFTWARE_DEVELOPMENT: {
    projectTitle: 'Modern Work-Experience Based Learning Approach ',
    projectDescription:
      'Learn and grow like you would on a real job. Start from the fundamentals, get support from our mentors and community, and <strong class="font-bold text-black">build your way to the top</strong> - through professional work-like Full-stack and Backend web development projects.',
    projects: [
      {
        image: 'CardOne',
        skills: ['MongoDB', 'React JS', 'Node JS', 'Express JS'],
        subtitle:
          'Build a functional and <br class="sm:block hidden"/>scalable ecommerce app',
        tag: 'Guided Project',
        title:
          'Work like Full Stack Developers  <br class="sm:block hidden"/>at Amazon',
      },
      {
        image: 'CardTwo',
        skills: ['React JS', 'Node JS', 'Rest API', 'Express JS'],
        subtitle:
          'Build a highly responsive, seamless <br class="sm:block hidden"/>video streaming service',
        tag: 'Independent Project',
        title:
          'Build an OTT platform <br class="sm:block hidden"/>like Netflix Engineers',
      },
      {
        image: 'CardSix',
        skills: ['HTML', 'CSS', 'Rest API', 'Flexbox'],
        subtitle:
          'Build a fully functional news <br class="sm:block hidden"/>aggregator like google news',
        tag: 'Independent Project',
        title:
          'Experience building a <br class="sm:block hidden"/>Google News like app',
      },
      {
        image: 'CardThree',
        skills: ['HTML', 'CSS', 'Rest API', 'Flexbox'],
        subtitle:
          'Build a highly responsive <br class="sm:block hidden"/>frontend for a travel app',
        tag: 'Independent Project',
        title:
          'Work like Frontend <br class="sm:block hidden"/>Developers at AirBnB',
      },
    ],
  },
  QA_AUTOMATION: {
    projectTitle: 'Build Modern Work-like Professional Projects',
    projectDescription:
      'Learn QA Automation Testing the right way. <br class="sm:block hidden"/> Build a diverse set of guided as well as independent work-like professional projects to master Web UI Testing, API Testing, Performance Testing, Microservices Testing, DevOps, Selenium, and more - just like engineers at top tech companies.',
    projects: [
      {
        image: 'QACardNew',
        skills: ['AWS', 'API Testing', 'DB Testing', 'Microservices Testing'],
        subtitle:
          'Test an e-commerce platform’s LIVE <br class="sm:block hidden"/>distributed backend system on cloud',
        tag: 'Advanced Guided Project',
        title:
          'Play the role of an <br class="sm:block hidden"/>SDET at Flipkart',
      },
      {
        image: 'QACardTwo',
        skills: [
          'Selenium Framework',
          'Page Object Model',
          'JS Actions',
          'Extent Report',
        ],
        subtitle:
          'Architect a Selenium Framework and <br class="sm:block hidden"/> automate test cases for a travel company',
        tag: 'Guided Project',
        title:
          'Experience working like a <br class="sm:block hidden"/>Test Engineer at AirBnB',
      },
      {
        image: 'QACardThree',
        skills: ['Selenium Framework', 'REST Assured', 'Postman', 'TestNG'],
        subtitle:
          'Automate API test cases and<br class="sm:block hidden"/> run them along with UI tests',
        tag: 'Guided Project',
        title: 'Gain real work ex <br class="sm:block hidden"/>in API Testing',
      },
      {
        image: 'QACardOne',
        skills: ['Java', 'Selenium', 'TestNG', 'XPath', 'Apache POI'],
        subtitle:
          'Create automated test cases using<br class="sm:block hidden"/> Selenium for a e-commerce platform',
        tag: 'Guided Project',
        title:
          'Work like QA Engineers <br class="sm:block hidden"/>in Flipkart',
      },
    ],
  },
};
