import React from 'react';
import GlassContainer from '../GlassContainer/index';
import Highlight from './Highlight/index';
import Layout from '@components/v5/layouts/Layout/index';
import { cn } from '@/lib/utils';

export default function GlassHighlights(props) {
  const { highlights, className, containerClassName } = props;

  return (
    <Layout
      className={cn(
        'container mt-12 flex flex-col gap-4 md:mt-12 md:flex-row md:gap-8',
        className,
      )}
    >
      {highlights.map((highlight) => (
        <GlassContainer
          className={cn('w-full px-0 md:max-w-[300px]', containerClassName)}
        >
          <Highlight key={highlight.title} highlight={highlight} />
        </GlassContainer>
      ))}
    </Layout>
  );
}
