import React from 'react';

/**
 * MenuContainer component for wrapping menu items.
 *
 * @param {object} props - The props for the component.
 * @param {React.ReactNode} props.children - The children elements to be wrapped within the menu container.
 * @returns {JSX.Element} - Menu container component JSX.
 */
export default function MenuContainer({ children }) {
  return (
    <div className="hidden h-full items-center gap-4 font-manrope text-sm font-bold md:flex">
      {children}
    </div>
  );
}
