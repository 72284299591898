import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';
import { cn } from '@/lib/utils';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function AboutCrioDo() {
  const { getAboutCrioDo, getAboutCrioImg, getUpcomingEvents } =
    useRegisterMetadata();
  const { events } = getUpcomingEvents() || [];

  const aboutCrioDo = getAboutCrioDo();
  const aboutCrioImg = getAboutCrioImg();
  return (
    <MasterclassSectionLayout
      className={cn(
        'container relative -top-12 grid grid-cols-1 pt-[30px] md:static lg:grid-cols-[1.4fr_1fr]',
        events.length === 0 ? 'md:pb-[120px]' : '',
      )}
      heading={'About Crio.Do'}
      sectionImage={aboutCrioImg}
    >
      <RawHTML className="font-rubik text-[10px] leading-[16px] md:text-[18px] md:leading-[28px]">
        {aboutCrioDo}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
