import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * MenuHeading component renders the heading of a menu.
 *
 * @param {Object} props - The props passed to the component.
 * @param {string} name - The name of the menu heading.
 * @param {function} setState - Function to set the state of the menu.
 * @param {React.RefObject} ref - Ref object for the component, forwarded from the parent.
 * @returns {JSX.Element} - Returns the JSX for the MenuHeading component.
 */

const MenuHeading = forwardRef(({ name, setState }, ref) => {
  return (
    <div
      ref={ref} // Forward the ref to the parent component(<MenuPopover/>)
      onMouseEnter={() => setState('open')} // control the state of the menu, set to open on mouse hover
      className="-mr-4 cursor-pointer text-sm "
    >
      {/* Render the name of the menu */}
      {name}
      {/* Render the dropdown icon */}
      <span className="ml-2 mt-1">
        <FontAwesomeIcon
          icon={['fal', 'angle-down']}
          size={'lg'}
        ></FontAwesomeIcon>
      </span>
    </div>
  );
});

export default MenuHeading;
