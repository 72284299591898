import { useFormContext } from 'react-hook-form';
import React from 'react';
// import img from '../../../../images/ROI/Graph.png';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  performCalculations,
  performCalculationsMobile,
} from '@src/utils/ROI/roi';
import InsightBarLayout from '@components/ROI/Layouts/InsightBar/index';
import { GRAPH_INSIGHT_BAR } from '@src/constants/ROI/index';
import CustomTooltip from './CustomTooltip/index';
import useResizer from '@components/extra/useResizer';
import CustomDot from './CustomDot/index';

export default function SalaryGrowthGraph() {
  const { watch } = useFormContext();
  const isMobile = useResizer();
  const formData = watch();

  // const { newCTC, currentCTC } = performCalculations(formData);
  const { newCTC, currentCTC } = isMobile
    ? performCalculationsMobile(formData)
    : performCalculations(formData);

  const calculateData = () => {
    let data = [
      {
        name: '0 yr',
        uv: (currentCTC / 100000).toFixed(1),
        pv: (currentCTC / 100000).toFixed(1),
      },
    ];

    data.push({
      name: '1 yr',
      uv: newCTC,
      pv:
        formData.currentWorkingStatus === 'working'
          ? (data[0].pv * 1.12).toFixed(1)
          : 3,
    });
    data.push({
      name: '2 yrs',
      uv: (data[1].uv * 1.12).toFixed(1),
      pv: (data[1].pv * 1.12).toFixed(1),
    });

    for (let i = 4; i <= 12; i += 2) {
      const pv = data[data.length - 1].pv * 1.12 * 1.12;
      const uv = data[data.length - 1].uv * 1.12 * 1.12;
      data.push({
        name: `${i} yrs`,
        uv: uv.toFixed(1),
        pv: pv.toFixed(1),
      });
    }
    return data;
  };

  const data = calculateData();

  const uvValues = data.map((item) => parseFloat(item.uv));
  const pvValues = data.map((item) => parseFloat(item.pv));
  const minValue = Math.min(...uvValues, ...pvValues);
  const maxValue = Math.max(...uvValues, ...pvValues);

  const renderCustomDot =
    (stroke, active = false) =>
    ({ cx, cy }) =>
      <CustomDot cx={cx} cy={cy} stroke={stroke} active={active} />;

  return (
    <div
      className="relative z-[999] flex h-[325px] flex-col items-center justify-center rounded-[10px] border border-solid border-[#B9CFCA80] bg-white md:h-full md:py-[6px]"
      style={
        !isMobile
          ? {
              backgroundImage:
                'linear-gradient(#B9CFCA4D 0.5px, transparent 0.5px), linear-gradient(to right, #B9CFCA4D 0.5px, transparent 0.5px)',
              backgroundSize: '19px 19px',
              backgroundColor: 'rgba(255, 255, 255, 0)',
            }
          : {}
      }
    >
      <div className="absolute top-6 flex items-center gap-10 md:gap-3">
        {GRAPH_INSIGHT_BAR.map((props) => (
          <InsightBarLayout {...props} />
        ))}
      </div>
      <div className="absolute left-2 flex -rotate-90 transform items-center gap-3 md:left-4">
        <div className="font-rubik text-[8px] font-semibold leading-none text-v5-neutral-400 md:text-[12px]">
          Salary
        </div>
      </div>
      <div className="absolute bottom-4 flex items-center gap-3 md:bottom-6">
        <div className="font-rubik text-[8px] font-semibold leading-none text-v5-neutral-400 md:text-[12px]">
          Time Period
        </div>
      </div>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className=" mt-[9px] mr-2 rounded-xl"
      >
        <AreaChart
          width={800}
          height={300}
          data={data}
          margin={{
            top: 70,
            right: isMobile ? 20 : 60,
            left: isMobile ? 20 : 60,
            bottom: isMobile ? 40 : 60,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2DF8C5" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#EDEDED" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#C0C0C0" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            stroke="#DBDFDE"
            strokeWidth={2}
            tick={{
              fill: '#525252',
              fontFamily: isMobile ? 'Manrope' : 'Rubik',
              fontSize: isMobile ? 10 : 12,
              lineHeight: isMobile ? 10 : 18,
              fontWeight: 600,
              dy: 10,
            }}
            interval={0}
          />
          <YAxis
            stroke="#DBDFDE"
            strokeWidth={2}
            tickFormatter={(value) => `₹${value} L`}
            tick={{
              fill: '#525252',
              fontFamily: isMobile ? 'Manrope' : 'Rubik',
              fontSize: isMobile ? 10 : 12,
              lineHeight: isMobile ? 10 : 18,
              fontWeight: 600,
              dx: -10,
            }}
            domain={[minValue, maxValue]}
            tickCount={9}
          />
          <CartesianGrid
            stroke="rgba(185, 207, 202, 0.2)"
            vertical={false}
            strokeWidth={2}
          />
          <Tooltip
            cursor={{ stroke: '#7A8B87', strokeWidth: 2 }}
            label={true}
            content={<CustomTooltip />}
          />
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#02BF91"
            strokeWidth={4}
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={renderCustomDot('#009378')}
            activeDot={renderCustomDot('#009378', true)}
          />
          <Area
            type="monotone"
            dataKey="pv"
            stroke="#B9CFCA"
            strokeWidth={4}
            fillOpacity={1}
            fill="url(#colorPv)"
            dot={renderCustomDot('#7A8B87')}
            activeDot={renderCustomDot('#7A8B87', true)}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
