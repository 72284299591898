import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function Layout({ className, children, id, ...props }) {
  return (
    <section
      id={id}
      className={twMerge(
        `${className?.includes('p') ? className : 'z-[999] py-10 sm:py-12'} ${
          className ? className : ''
        } `,
      )}
      {...props}
    >
      {children}
    </section>
  );
}
