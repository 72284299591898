import topStripDb from '../data/top_strip.json';

const useTopStripData = () => {
  //Top strip data
  const { top_strip } = topStripDb;
  const strips = top_strip?.top_strip_data?.data;
  const top_strips = strips?.filter(
    (strip) => strip.attributes.isActiveStrip === true,
  );
  const top_strip_data = top_strips[0]?.attributes;

  return top_strip_data;
};

export default useTopStripData;
