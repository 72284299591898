import RawHTML from '@components/Common/RawHTML';
import React from 'react';

export default function CertGenHeroLeftSection({
  VideoTitle,
  VideoDescription,
}) {
  return VideoTitle && VideoDescription ? (
    <div className="flex flex-col items-center gap-6 py-4 md:items-start">
      <div className="text-center text-[28px] font-bold leading-none md:text-start md:text-[42px] xl:text-6xl">
        {VideoTitle}
      </div>
      <div className="text-center text-[20px] font-bold leading-[20px] text-v5-neutral-500 md:text-left md:text-4xl md:leading-[54px]">
        Fill out the form to{' '}
        <span className="text-v5-green-300">Download your certificate</span>{' '}
      </div>
      <div className=" text-center text-xs font-normal text-v5-neutral-500 md:text-left md:text-2xl xl:leading-[38px]">
        <RawHTML>{VideoDescription}</RawHTML>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center gap-6 py-6 md:items-start">
      <div className="text-[28px] font-bold leading-none md:text-[68px]">
        Congratulations!
      </div>
      <div className="text-center text-[20px] font-bold leading-[20px] text-v5-neutral-500 md:text-left md:text-[42px] md:leading-[54px]">
        Here's your <span className="text-v5-green-300">#LearnByDoing</span>{' '}
        Certificate
      </div>
      <div className=" text-center text-xs font-normal text-v5-neutral-500 md:text-left md:text-[20px] md:leading-[38px]">
        We hope you enjoyed your upskilling journey!
        <br />
        At Crio, we empower aspiring developers to gain job-ready tech skills by
        building real-world, professional projects. Our hands-on approach
        ensures you're not just learning, but doing—preparing you to excel in
        the tech industry.
      </div>
    </div>
  );
}
