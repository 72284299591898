import React from 'react';
import MenuContainer from './MenuContainer/index';
import NavigationLinks from './NavigationLinks/index';
import AuthButtons from '../AuthButtons/index';

/**
 * NavLinksDesktop component renders the navigation links and authentication buttons for desktop view.
 * Parent Component: Navbar
 *
 * @param {boolean} dark - Indicates whether the dark theme is enabled.
 * @param {boolean} hideCTA - Indicates whether to hide the CTA button.
 * @returns {JSX.Element} - NavLinksDesktop component JSX.
 */
export default function NavLinksDesktop({ dark, hideCTA }) {
  return (
    <MenuContainer>
      {/* Render navigation links */}
      <NavigationLinks />
      {/* Render authentication buttons */}
      <AuthButtons
        dark={dark}
        hideCTA={hideCTA}
        signInBtnClassName={`${dark ? 'border-white text-white' : ''}`}
      />
    </MenuContainer>
  );
}
