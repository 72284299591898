import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection';
import useResizer from '@components/extra/useResizer';

export default function CrioFeedback() {
  const isMobile = useResizer();
  return (
    <MasterclassSectionLayout
      className={`grid grid-cols-1 pt-[30px] lg:grid-cols-[58%_1fr] lg:pt-[60px] xl:grid-cols-[1.4fr_1fr] ${
        isMobile ? '' : 'container'
      }`}
      heading={`Learner's Review`}
      doesSectionHaveListItems={true}
      isSectionHeaderWrapped={true}
    >
      {/* Set a fixed width of 58% for the swiper to prevent it from expanding to full width */}
      <VideoTestimonialSection
        disableGreenBanner
        disableCareerHandbook
        disableSectionHeading
        numberOfTestimonials={2}
        removeDescription={true}
        className="lg:max-w-[651px]"
      ></VideoTestimonialSection>
    </MasterclassSectionLayout>
  );
}
