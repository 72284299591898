import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const dataScienceCurriculumData = {
  curriculum: [
    {
      data: [
        '<p>Establish a strong <strong>Python programming foundation</strong> for your <strong>data analytics career</strong> by mastering <strong>essential coding skills</strong> and <strong>core programming concepts</strong>.  <ul> <li>Gain proficiency in Python syntax, data structures, and control flow statements.</li> <li>Write reusable code using functions, modules, and file-handling techniques.</li> <li>Confidently solve programming problems and apply Python library methods.</li> <li>Handle errors and exceptions effectively in your programs.</li> <li>Understand object-oriented programming (OOP) and make API calls for data handling.</li> <li>Grasp basic Statistics and Probability for data interpretation.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon1.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '8 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon1.webp',
      skills: [
        'Python Foundations',
        'Data Structures',
        'Control Flow',
        'Functions',
        'Modules',
        'File Handling',
        'Libraries',
        'Error Handling',
        'Object Oriented Programming',
        'API invocation',
        'JSON',
        'Statistics',
        'Probability',
      ],
      tag: '',
      title: 'Programming Foundations',
      anchor: 'programming-foundations',
    },
    {
      data: [
        '<p>Unlock the power of <strong>data analytics</strong> and create compelling <strong>data visualizations</strong> using <strong>Python, SQL, Excel</strong>, and top industry tools like <strong>Tableau</strong> and <strong>Power BI</strong> <ul> <li>Understand Data Analytics and Data Science, and explore how they solve industry problems.</li> <li>Gain knowledge of various data sources, formats (JSON, XML, CSV), and the role of Metrics and KPIs in analysis.</li> <li>Master Excel skills, including formulas, pivot tables, charts, and integrating AI plugins for automation.</li> <li>Learn to use Python libraries like NumPy, Pandas, and SciPy for numerical computing, data manipulation, and scientific computations.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon2.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '16 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon2.webp',
      skills: [
        'Data Analytics',
        'Data Sources',
        'Metrics',
        'Excel',
        'Excel with AI',
        'SQL Queries',
        'Data Manipulation',
        'Data Aggregation',
        'Joins',
        'Subqueries',
        'Query Optimization',
        'NumPy',
        'Pandas',
        'SciPy',
        'Data Formats',
        'SQL Integration',
        'Data Cleaning',
        'Matplotlib',
        'Data Visualization',
        'Seaborn',
        'Tableau',
        'Power BI',
      ],
      title: 'Data Analytics and Data Visualization',
      anchor: 'data-analytics-and-data-visualization',
    },
    {
      data: [
        '<p>Gain real-world experience by working on <strong>domain-specific projects</strong> and <strong>case studies</strong>, showcasing your <strong>data analytical</strong> skills across areas like <strong>eCommerce, finance</strong>, and more. <ul> <li>Perform end-to-end Exploratory Data Analysis (EDA) on real-world datasets, using AI to enhance insights and streamline processes.</li> <li>Load, inspect, clean, and transform data using Python libraries like NumPy and Pandas while leveraging AI for faster data cleaning and transformation.</li> <li>Visualize data using Seaborn, Tableau, and AI-driven tools to create insightful visualizations and dashboards.</li> <li>Use AI-powered techniques to extract hidden patterns and fast-track analysis, integrating data storytelling for compelling insights.</li> <li>Work on case studies using AI to derive actionable insights, and present findings through interactive dashboards and AI-assisted storytelling.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon3.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '8 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon3.webp',
      skills: [
        'Data Storytelling',
        'Exploratory Data Analysis',
        'Case Studies',
        'Business Applications',
      ],
      title: 'Industry Projects and Case Studies',
      anchor: 'programming-essentials',
    },
    {
      data: [
        '<p>Build a strong foundation in <strong>logic building</strong> and enhance your <strong>problem-solving skills</strong> by mastering essential <strong>data structures</strong> and <strong>algorithm techniques</strong> critical for real-world applications. <ul> <li>Build logic through solving array, string, math, recursion, and implementation problems relevant to data analytics.</li> <li>Understand and apply Time & Space complexity in solving analytical problems.</li> <li>Get introduced to fundamental data structures like LinkedLists and Trees, and their traversal techniques.</li> <li>Learn problem patterns that involve arrays and how they relate to data manipulation in analytics.</li> <li>Solve commonly asked problems across these patterns, focusing on logic building for data-driven decision-making.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon4.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '8 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon4.webp',
      skills: [
        'Logic Building',
        'Recursion',
        'Collections',
        'Introduction to Data Structures',
        'Space and Time Complexity',
        'Matrix',
        'Two Pointers',
        'Sliding Window',
        'Prefix Sum',
        'Bit Manipulation',
        'Sorting',
        'Binary Search',
      ],
      title: 'Data Structures and Algorithms',
      anchor: 'data-structures-and-algorithms',
    },
    {
      data: [
        '<p>Dive into the future of <strong>artificial intelligence</strong> with <strong>Generative AI, Machine Learning</strong>, and operationalize your ML workflows using <strong>ML Ops techniques</strong>.</p> <ul> <li>Understand the fundamentals of machine learning, including key algorithms like linear regression, decision trees, and clustering.</li> <li>Learn cloud computing and containerization (Docker) for machine learning deployments, mastering Docker basics like building images and managing containers.</li> <li>Master Python APIs for dynamic data retrieval, web scraping using Beautiful Soup, and explore Generative AI applications in data analysis.</li> <li>Gain proficiency in Linux commands, shell scripting, and version control with Git for managing data analysis and machine learning projects.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon5.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '8 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon5.webp',
      skills: [
        'Machine Learning',
        'Cloud Computing',
        'Containerization',
        'CI/CD',
        'MLOps',
        'API Integration',
        'Web Scraping',
        'Prompt Engineering',
        'LLMs',
        'GenAI for Data Analysis',
        'Linux',
        'Git',
        'Advanced Python',
        'Beautiful Soup',
        'Automation',
      ],
      title: 'GenAI, ML and MLOps',
      anchor: 'genai-ml-and-mlops',
    },
    {
      data: [
        '<p>Master <strong>Big Data</strong> concepts and <strong>data engineering</strong> practices, including <strong>ETL processes</strong> and <strong>data pipeline</strong> creation using tools like <strong>Apache Spark</strong> and <strong>PySpark</strong>. <ul> <li>Get introduced to Big Data concepts and the challenges of managing large datasets.</li> <li>Learn about Data Warehouses and Data Lakes and their importance in data storage and management.</li> <li>Understand Data Pipelines and ETL (Extract, Transform, Load) processes for Big Data workflows.</li> <li>Master PySpark and MLlib for handling Big Data tasks efficiently.</li> <li>Explore the Apache toolkit, including Hadoop, Spark, and Kafka, for scalable data processing.</li> <li>Gain familiarity with Big Data and Data Engineering tools available on AWS, GCP, and Azure.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon6.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '4 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon6.webp',
      skills: [
        'Big Data',
        'Data Warehouses',
        'Data Lakes',
        'Data Pipelines',
        'ETL',
        'PySpark',
        'Apache Toolkit',
      ],
      title: 'Big Data and Data Engineering',
      anchor: 'big-data-and-data-engineering',
    },
    {
      data: [
        '<p>Prepare to succeed in <strong>data analytics interviews</strong> with focused <strong>interview preparation, mock interviews,</strong> and <strong>aptitude assessments.</strong> <ul> <li>Perfect your interview skills for Data Analysis roles through multiple AI-driven practice sessions.</li> <li>Practice the most commonly asked technical questions, including logic building and data manipulation challenges, to ace your interview.</li> <li>Take part in timed mock interviews and solve take-home assignments focused on real-world data problems and AI applications.</li> <li>Build confidence for upcoming interviews by engaging in mock sessions, focusing on AI integration and problem-solving techniques.</li> <li>Master Aptitude, Statistics, and Probability assessments with an emphasis on logical reasoning and AI-enhanced data insights.</li> </ul> </p>',
      ],
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/DataScience/Icon7.webp"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      duration: '4 weeks',
      image: '../../images/v5/CurriculumIcons/DataScience/Icon7.webp',
      skills: [
        'Conceptual Interview Questions Prep',
        'Take-Home Challenges',
        'Mock Interviews',
        'Aptitude',
      ],
      title: 'Interview Blitz',
      anchor: 'interview-blitz',
    },
  ],
};
