import React from 'react';
import ApplyNowCTA from '@components/Common/ApplyNowCTA/index';
import useResizer from '@components/extra/useResizer';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import { useCustomToast } from '@components/extra/useCustomToast';

const DownloadPlacementStats = ({ children }) => {
  const isMobile = useResizer();

  const placementDownloadToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-xs">
        You will receive the stats in your email shortly.
        <br /> (In case you have your adblocker turned on, please write to us at{' '}
        <a href="mailto:programs@criodo.com" className="font-bold">
          programs@criodo.com
        </a>{' '}
        to receive the stats)
      </p>
    </div>,
  );

  return (
    <div
      className={`mb-10 flex flex-col items-center justify-between gap-4 rounded-lg bg-crio-green-600 py-4 px-4 text-center md:flex-row md:px-6 md:leading-snug`}
    >
      <h4
        className="text-md px-3 text-center font-normal md:px-0 md:text-left md:text-2xl"
        style={{ fontFamily: 'Rubik' }}
      >
        {children}
      </h4>
      <div className="mt-4 md:mt-0">
        <ApplyNowCTA
          location="CAREER_SERVICES"
          text="Download Placement Stats"
          width={isMobile ? '250px' : '300px'}
          className="shimmer-btn bg-crio-yellow-500 py-2 px-2 text-crio-green-900"
          applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900 py-4 w-[200px]"
          openEvent="DOWNLOAD_PLACEMENT_STAT_CLICKED"
          // innerText="Download"
          onSubmit={() => {
            placementDownloadToast();
            GTM.track(gtmEvents.DOWNLOAD_PLACEMENT_STAT_APPLIED_SUCCESSFULLY, {
              location: 'DOWNLOAD_PLACEMENT_STAT_CARD',
            });
          }}
        />
      </div>
    </div>
  );
};

export default DownloadPlacementStats;
