import axios from 'axios';

const webinarApi = {
  url: 'https://63934u7gji.execute-api.ap-south-1.amazonaws.com/register',
};

class WebinarJamService {
  register = async (payload, service) => {
    const response = await axios({
      method: 'post',
      url: `${webinarApi.url}?service=${service}`,
      data: payload,
    });
    return response.data;
  };
}

export default new WebinarJamService();
