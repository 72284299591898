import React, { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';

// Custom hook for OTP input logic

export const useOTPInput = (length) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  // Handles changes for individual input updates
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus next input
    if (element.value !== '' && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handles bulk updates (e.g., Web OTP autofill)
  const handleBulkChange = (digits) => {
    const newOtp = [...otp];
    for (let i = 0; i < digits.length && i < length; i++) {
      newOtp[i] = digits[i];
      if (inputRefs.current[i]) {
        inputRefs.current[i].value = digits[i]; // Update input field value
      }
    }
    setOtp(newOtp);
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value) {
      // Focus previous input on backspace
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const resetOTP = () => {
    const clearedOtp = new Array(length).fill('');
    setOtp(clearedOtp);
    inputRefs.current.forEach((input) => {
      if (input) input.value = ''; // Clear all input fields
    });
  };

  useEffect(() => {
    // Focus first input on mount
    inputRefs?.current[0]?.focus();
  }, []);

  return {
    otp,
    inputRefs,
    handleChange,
    handleBackspace,
    resetOTP,
    handleBulkChange, // Expose bulk update handler
  };
};

// Custom hook for timer logic
export const useResendTimer = (initialTime) => {
  const [showTimer, setShowTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const timerRef = useRef(null);

  const startTimer = () => {
    setShowTimer(true);
    setTimeLeft(initialTime);

    if (timerRef.current) clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setShowTimer(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  return { showTimer, timeLeft, startTimer };
};

// PhoneEditInput component
export const PhoneEditInput = ({ error, onPhoneChange }) => {
  return (
    <PhoneInput
      country="in"
      placeholder="Phone"
      name="phone"
      countryCodeEditable={false}
      defaultErrorMessage={error}
      className={'lead-form open-form'}
      onChange={(value) => {
        // value includes country code, e.g., "911234567890"
        onPhoneChange(value);
      }}
      isValid={() => {
        if (typeof window !== 'undefined' && document) {
          error
            ? document.documentElement.style.setProperty(
                '--phone-input-color',
                '#F3736A',
              )
            : document.documentElement.style.setProperty(
                '--phone-input-color',
                '#7A8B87',
              );
        }
        return !error;
      }}
      inputProps={{
        required: true,
      }}
      inputStyle={{
        width: '100%',
        color: '#000',
        background: '#fff',
        border: error ? '1px solid #E84D42' : 'none',
        outline: 'none',
      }}
    />
  );
};

// OTPInput component
export const OTPInput = ({
  otp,
  inputRefs,
  handleChange,
  handleBackspace,
  error,
  success,
}) => {
  // Only show error/success colors if the OTP is complete
  const isComplete = otp.every((digit) => digit !== '');

  const getBorderColor = () => {
    if (!isComplete) return 'transparent';
    if (error) return '#E84D42';
    if (success) return '#02BF91';
    return 'transparent';
  };

  const getTextColor = () => {
    if (!isComplete) return 'black';
    if (error) return '#E84D42';
    if (success) return '#02BF91';
    return 'black';
  };

  return otp.map((digit, index) => (
    <input
      key={index}
      type="text"
      ref={(el) => (inputRefs.current[index] = el)}
      value={digit}
      onChange={(e) => handleChange(e.target, index)}
      onKeyDown={(e) => handleBackspace(e, index)}
      maxLength={1}
      style={{
        border: `1px solid ${getBorderColor()}`,
        color: getTextColor(),
      }}
      className={`h-12 w-10 rounded border-2 text-center text-lg font-semibold
          transition-colors focus:outline-none`}
    />
  ));
};
