import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const curriculumData = {
  curriculum: [
    {
      title: 'Programming Foundations',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/One.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 to 8 weeks',
      tag: null,
      data: [
        'Kickstart your Programmer journey by creating a solid foundation of programming and language skills. Start using standard industry developer technologies.',
      ],
      skills: null,
      anchor: 'programming-foundations',
      cardTitle: null,
      cards: [
        {
          image: null,
          data: [
            'Start with Hello World and get familiar with the syntax and constructs of Java or JavaScript based on the specialization you choose',
          ],
          skills: [
            'Loops',
            'Conditionals',
            'Arrays',
            'Objects',
            'Strings',
            'Data Types',
            'Libraries',
          ],
          title: 'Language Fundamentals',
        },
        {
          image: null,
          data: [
            'Gain skills that are must-haves for any developer through hands-on activities and live workshops.',
          ],
          skills: ['HTTP', 'Rest API', 'Deployment', 'Linux', 'GIT', 'SQL'],
          title: 'Developer Essentials',
        },
        {
          image: {
            childImageSharp: {
              gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                  fallback:
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC6ElEQVQ4y3WVS0hUURjH//eOEoEEbVtHC2lVi4LooYEwo7UIE3xMoahjvl8z99wZa0KyB6bO3DuNtUlqE20kwWgVBbULIiEqEBQJo1qIKzOI+4/7vlfHxeGec8/5fud7H0ATgKZUQlMWoSlb0AShKwY0YUA35yWGtWedoS1jyZoMuLBNX8A5qCv0vu7w1oLexd4lyqbFsuj2j21o7gFXQ4VyQWGkkKJkAT24YZ21NTQsWZuxCEtl3dnQA6boig2ZyhCT14mZdAAq7K8r4ypisgI+o70ZMDOXZnShlcWPMVY+67LWUkGEobowAgoRzg3+hmMmpjNsfNVC/jvM+T/VPPCll3L2CuWpYaKg+lBdhKG+33ygNfIqX6+eYW4jSiyrxEqKZfEoI92NRFF1TS4B3JUW9qFILsVj7xLEjyTxU6H0tI04WU001RMP9gDqHjCcFpI3F5TnEsSNRuL0ecqxGuJmD1FMO0AvZUoANWGDzDGjEoUMy8YTlMf7iOwAMdJBOZsgJnoJLaChF1TFBQYSNq8SOZWH5gZ5cKaPUrbL9pc5HmUIbYwVt9opz47YUF31I+2mUbAq9hdH+eRzDfm3kgNLF4i7vZR1R/Npleeet7N2OU6sJikt9FO6N+j60w+sCTMrAffH2PamkeQRTvyqI9ZTjNxpYPlQ3PLZPj3J6NerjHwfJtaSlJb6Wd5xkfLtXhMaMtmAmXdTwqh4O8SjvzuJdUGsJInmOkq1USKfolQUxLcksTZKbAji5TXiVBWlnhbiYcZA3jfZsKtCMfBYEB+SxPtuIllPHD9LNF22zcopxIsU8WmQmG8nLsWIE1WE0knMpkNAxwdmUIQh51PEYCsRrSPiDcTkiF0ZeWufmBwimhuIWB0x0EoUAgGxSs9rDk5jMB1r5pl5galZqMwUe21G3ZwX0zuag9gKty/dMd/vIjsbwc4u4863nfliuMGGc2onpNS6ZIMNPwF2ThqhbrzXE6DvfgL+AxeqJsiziNC8AAAAAElFTkSuQmCC',
                },
                backgroundColor: 'transparent',
                images: {
                  fallback: {
                    src: '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png',
                    srcSet:
                      '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f159a/Six.png 600w',
                    sizes: '(min-width: 300px) 300px, 100vw',
                  },
                  sources: [
                    {
                      srcSet:
                        '/static/d55c8df9a40f9b3fe9147dc1b15cb291/5de9a/Six.webp 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f0b89/Six.webp 600w',
                      type: 'image/webp',
                      sizes: '(min-width: 300px) 300px, 100vw',
                    },
                  ],
                },
                width: 300,
                height: 300,
              },
            },
          },
          data: null,
          skills: null,
          title: null,
        },
      ],
    },
    {
      title: 'Data Structures and Algorithms',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '20 weeks',
      tag: null,
      data: [
        'Master essential Data Structures and Algorithms concepts from an interview perspective through our carefully designed curriculum, exclusive videos, in-depth mentorship, live sessions, and mock assessments.',
      ],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      options: [
        { tag: 'dsa-basics', title: 'Basics' },
        { tag: 'dsa-intermediate', title: 'Intermediate' },
        { tag: 'dsa-advanced', title: 'Advanced' },
      ],
      cards: [
        {
          data: [
            '<div>Build problem solving skills with the commonly used Data Structures. Learn the basics of <strong class="">Space and Time complexity</strong> which drive optimization.<ul><li>Programming Essentials</li><li>Develop logic building skills by solving simple math, recursion, implementation problems</li><li>Learn Time & Space complexity</li><li>Get introduced to data structures & traversals - Linkedlist & Trees</li></ul></div>',
          ],
          skills: [
            'Logic Building',
            'Collections',
            'Space and Time Complexity',
          ],
          title: 'DSA Basics',
          subCards: null,
          tag: 'dsa-basics',
        },
        {
          data: [
            '<p>Master problem solving patterns and practice reducing the most frequently asked problems to known patterns.</p>',
          ],
          title: '',
          skills: [],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn various patterns of problem solving in arrays & strings and apply them in a wide variety of problems.</li><li>Learn the art of debugging that is critical for your timed interviews.</li></ul>',
          ],
          title: 'Arrays & Strings',
          skills: [
            'Matrix',
            'Two Pointers',
            'Sliding Window',
            'Sorting',
            'Prefix Sum',
            'Bit Manipulation',
            'Binary Search',
          ],
          tag: 'dsa-intermediate',
        },

        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn Linked List implementation and applications</li><li>Apply Queue, Stack to solve real world problems</li></ul>',
          ],
          title: 'Stack, Queue and Linked List',
          skills: [
            'Data Structure Application',
            'Linked List',
            'Stack',
            'Queue',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Apply Hash to solve real world problems</li><li>Learn BFS/DFS to solve multiple Tree problems effectively</li></ul>',
          ],
          title: 'Hash, Trees',
          skills: [
            'Data Structure Application',
            'Hash',
            'Binary Tree',
            'Binary Search Tree',
            'BFS',
            'DFS',
            'Recursion',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<p>Step up your <b>interview cracking ability</b> by learning advanced Data structures and Algorithms through our carefully designed curriculum, exclusive videos, in-depth mentorship, live sessions, practice with curated problems and timed assessments.</p>',
          ],
          title: '',
          skills: [],
          tag: 'dsa-advanced',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Deep dive into graph problem patterns and implement common algorithms like Prim’s, Kruskal’s and Dijkstra\'s</li><li>Learn common problem solving approaches like greedy & backtracking</li></ul>',
          ],
          title: 'Heap, Graphs, Greedy and Backtracking',
          skills: [
            'Graph BFS',
            'Graph DFS',
            'Heaps',
            'Shortest Path',
            'Graph Partitioning',
            'Greedy Method',
            'Advanced recursion and backtracking',
            'Topological Sort',
          ],
          tag: 'dsa-advanced',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn the art of DP and practice problems using Memoization and Tabulation</li><li>Get introduced to advanced DS like Trie, Segment Trees and solve related problems</li></ul>',
          ],
          title: 'Dynamic Programming and Advanced DS',
          skills: [
            'DP Memoization',
            'DP Tabulations',
            'Knapsack',
            'Spanning Tree',
            'Trie',
            'Segment Tree',
          ],
          tag: 'dsa-advanced',
        },
      ],
    },
    {
      title: 'Tech Stack Specialization & Work-like Projects',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Three.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '16 to 28 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'tech-stack',
      cardTitle: null,
      showOptionsAboveTitle: true,
      options: [
        { tag: 'fullstack-specialization', title: 'Fullstack Specialization' },
        { tag: 'backend-specialization', title: 'Backend Specialization' },
      ],
      cards: [
        {
          data: [
            '<p>Build products like developers at AirBnB, Amazon & Netflix to specialize in <b>Full-Stack Development</b></p>',
          ],
          title: '',
          skills: [],
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a responsive front end for <strong>QTrip</strong>, an AirBnB-like travel planning website, and learn HTML, CSS, Bootstrap and more</li></ul>',
          ],
          skills: ['HTML', 'CSS', 'Flexbox', 'Bootstrap'],
          title:
            'Build an AirBnB-like static frontend to master HTML, CSS & more',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Add life to the web pages of <strong>QTrip</strong> by making them dynamic and by integrating with a backend and 3rd party plugins.</li></ul>',
          ],
          skills: [
            'API Calls',
            'Routing',
            'Filters',
            'Data Persistence',
            '3rd Party Libraries',
          ],
          title:
            'Build an AirBnB-like dynamic frontend to master Rest API, Routing, Filters & more',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from QTrip Static and QTrip Dynamic to build your first independent project, <strong>XBoard</strong> - a newsfeed that aggregates articles from popular media outlets.</li></ul>',
          ],
          skills: [
            'JavaScript',
            'DOM Manipulation',
            'Developer Tools',
            'Binary Search Tree',
            'REST',
            'Deployment',
          ],
          title: 'Build a Google News-like newsfeed aggregator',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build the frontend for <strong>QKart</strong> - an online ecommerce store to learn React, and create a rich shopping experience.</li></ul>',
          ],
          skills: [
            'Components',
            'Component lifecycle',
            'Component state management',
            'Component styling',
            'Props',
            'Routing',
            'React Hooks',
            'Material UI',
          ],
          title:
            'Build a React powered frontend for an Amazon-like shopping app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings in React to build an independent project, the frontend of <strong>QTify</strong> - an audio-streaming platform like Spotify.</li></ul>',
          ],
          skills: ['Figma', 'Material UI', 'React', 'REST API', 'Deployment'],
          title: 'Build the frontend for a Spotify like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable NodeJS backend for <strong>QKart</strong> using the Express.js framework</li></ul>',
          ],
          skills: ['Express JS', 'Node JS', 'MongoDB', 'REST API'],
          title: 'Build a Node JS powered backend for a Flipkart-like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from QKart Backend to build your second independent project using Express and Node JS, the backend for <strong>XFlix</strong> - a video streaming platform like Netflix.</li></ul>',
          ],
          skills: [
            'Express JS',
            'Node JS',
            'MongoDB',
            'REST API',
            'Deployment',
          ],
          title: 'Build the backend for a Netflix-like OTT app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply Java and OOP knowledge to build a Calculator application <strong>QCalc</strong></li></ul>',
          ],
          skills: ['Java', 'OOP', 'Classes', 'Packages', 'Git', 'Gradle'],
          title: 'Start by building your first multi file Java project',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply all your Java knowledge to independently build a <strong>XURL</strong> - a URL shortener application like bit.ly.</li></ul>',
          ],
          skills: ['Data Structures', 'Java', 'OOP', 'Classes'],
          title: 'Implement Java skills to build a Bit.ly like URL shortener',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Start your backend development journey by building <strong>QMoney</strong>, a visual stock portfolio analyser.</li></ul>',
          ],
          skills: [
            'OOP',
            'JSON',
            'Consuming REST API',
            'Refactoring',
            'Gradle',
            'Exception Handling',
            'JUnit',
            'Jackson',
          ],
          title:
            'Build the backend for a stock portfolio analyser like Zerodha',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Model classes and relationships to build a company hierarchy with specified constraints - <strong>XCompany</strong></li></ul>',
          ],
          skills: ['Object Modeling', 'Machine Coding'],
          title:
            'Problem solve by applying Object Modeling and Machine Coding skills',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable, distributed, Java backend for <strong>QEats</strong> - a Swiggy-like food ordering app.</li></ul>',
          ],
          skills: [
            'MVCS',
            'Spring Boot',
            'REST APIs',
            'MongoDB',
            'Mockito',
            'Scientific Debugging',
            'Spring Security',
          ],
          title: 'Build a scalable backend for a Swiggy-like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from the entire program so far to build a fully functional Java backend for <strong>XMeme</strong>(a page where users can post and view Memes).</li></ul>',
          ],
          skills: ['Backend', 'REST API', 'SpringBoot', 'MongoDB'],
          title: 'Apply your learnings to build a Giphy like app',
          tag: 'fullstack-specialization',
        },
        {
          data: [
            '<p>Build products like developers at Google, Swiggy & Zerodha to specialize in <b>Backend Development</b></p>',
          ],
          title: '',
          skills: [],
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply Java and OOP knowledge to build a Calculator application <strong>QCalc</strong></li></ul>',
          ],
          skills: ['Java', 'OOP', 'Classes', 'Packages', 'Git', 'Gradle'],
          title: 'Start by building your first multi file Java project',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply all your Java knowledge to independently build a <strong>XURL</strong> - a URL shortener application like bit.ly.</li></ul>',
          ],
          skills: ['Data Structures', 'Java', 'OOP', 'Classes'],
          title: 'Implement Java skills to build a Bit.ly like URL shortener',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Start your backend development journey by building <strong>QMoney</strong>, a visual stock portfolio analyser.</li></ul>',
          ],
          skills: [
            'OOP',
            'JSON',
            'Consuming REST API',
            'Refactoring',
            'Gradle',
            'Exception Handling',
            'JUnit',
            'Jackson',
          ],
          title:
            'Build the backend for a stock portfolio analyser like Zerodha',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Model classes and relationships to build a company hierarchy with specified constraints - <strong>XCompany</strong></li></ul>',
          ],
          skills: ['Object Modeling', 'Machine Coding'],
          title:
            'Problem solve by applying Object Modeling and Machine Coding skills',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Build a scalable, distributed, Java backend for <strong>QEats</strong> - a Swiggy-like food ordering app.</li></ul>',
          ],
          skills: [
            'MVCS',
            'Spring Boot',
            'REST APIs',
            'MongoDB',
            'Mockito',
            'Scientific Debugging',
            'Spring Security',
          ],
          title: 'Build a scalable backend for a Swiggy-like app',
          tag: 'backend-specialization',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Apply your learnings from the entire program so far to build a fully functional Java backend for <strong>XMeme</strong>(a page where users can post and view Memes).</li></ul>',
          ],
          skills: ['Backend', 'REST API', 'SpringBoot', 'MongoDB'],
          title: 'Apply your learnings to build a Giphy like app',
          tag: 'backend-specialization',
        },
      ],
    },
    {
      title: 'Tech Stack Specialisation (Advanced)',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Four.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 to 8 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'tech-stack-advanced',
      cardTitle: null,
      showOptionsAboveTitle: true,
      options: [
        { tag: 'frontend-advanced', title: 'Frontend Advanced' },
        { tag: 'backend-advanced', title: 'Backend Advanced' },
      ],
      cards: [
        {
          data: [
            '<p>Take your Frontend developer skills to the next level by learning <b>architectural design</b> aspects</p>',
          ],
          title: '',
          skills: [],
          tag: 'frontend-advanced',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Master essential concepts required to be a strong FE developer through a series of hands-on workshops, activities and projects.</li><li>Learn concepts like <strong>Caching, Security, Website performance optimization, Redux State Management, Server Side Rendering</strong> using NextJS and others</li></ul>',
          ],
          skills: ['Redux', 'NextJS', 'CDN', 'Advanced CSS', 'SEO'],
          spanToWidth: true,
          title: null,
          tag: 'frontend-advanced',
        },
        {
          data: [
            '<p>Take your Backend developer skills to the next level by working on advanced features</p>',
          ],
          title: '',
          skills: [],
          tag: 'backend-advanced',
        },
        {
          data: [
            '<ul style="padding-left:20px"><li>Make the Java backend for <strong>QEats</strong>, scale-able</li><li>Build advanced search features, solve for performance bottlenecks and improve REST API performance</li></ul>',
          ],
          skills: ['Caching', 'Redis', 'JMeter', 'Multi-threading'],
          spanToWidth: true,
          title:
            'Work on a Swiggy like application and improve its performance',
          tag: 'backend-advanced',
        },
      ],
    },
    {
      title: 'System Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Five.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '8 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'system-design',
      cardTitle: null,
      options: [
        { tag: 'lld-foundation', title: 'LLD Foundations' },
        { tag: 'hld-foundation', title: 'HLD Foundations' },
      ],
      cards: [
        {
          image: null,
          data: [
            '<ul style="padding-left:20px"><li>Build <strong>QContest</strong>, a hackerrank-like contest platform by applying your <strong>Core Java</strong> and Low Level design skills</li></ul>',
          ],
          title:
            'Build a Hackerrank-like App to master Basics of Low Level Design',
          skills: [
            'SOLID Principles',
            'Object Oriented Design',
            'Object Modeling',
            'Clean Architecture',
            'Design Patterns',
          ],
          tag: 'lld-foundation',
        },
        {
          image: null,
          data: [
            '<ul style="padding-left:20px"><li>Build the JukeBox application, which can play songs from a playlist, using clean architecture</li></ul>',
          ],
          title:
            'Create an Spotify like application by applying machine coding techniques',
          skills: ['OOP', 'Machine Coding'],
          tag: 'lld-foundation',
        },
        {
          data: [
            '<div>Understand essential components/concepts of a modern tech stack used universally by internet companies with bonus hands-on exercises</div>',
          ],
          skills: [
            'Architecture of Web Applications',
            'Docker',
            'Kafka',
            'Micro-Services',
            'CI/CD',
            'Cloud',
            'CAP Theorem',
            'SQL/NoSQL',
            'Scalability',
            'Load Balancing',
            'Partitioning',
            'Caching',
          ],
          title: 'HLD Foundations',
          tag: 'hld-foundation',
        },
      ],
    },
    {
      title: 'Interview Blitz',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        'Practice multiple timed mock assessments',
        'Perfect the art of cracking DSA interviews through multiple DSA Blitz sessions',
        'Prepare to defend every single skill keyword added to your resume',
      ],
      skills: [
        'DSA Mocks',
        'DSA Blitz',
        'P2P Interviews',
        'Take home Prep',
        'Specialization Specific Prep',
      ],
      anchor: 'interview',
      cardTitle: null,
      cards: null,
    },
    {
      title: 'Crio Externship',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Seven.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        'Unlike regular boot camps where you wrap up your learnings with a Capstone project, we go one step further. You will actually work on a real project statement given by some of the top tech companies in India. Here are some of the past companies who provided externships for our learner community,',
      ],
      skills: [
        'CRED',
        'Jumbotail',
        'Groww',
        'Vicara',
        'Slice',
        'Recko',
        'and many more..',
      ],
      anchor: 'externship',
      cardTitle: null,
      cards: null,
    },
    {
      title: 'AI Tools for Developers',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Gear.png"
          placeholder="blurred"
          width={300}
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '4 weeks',
      tag: null,
      data: [
        '<p>Foundations of LLM and Generative AI</p><ul><li>Understand the core principles and technologies behind large language models and generative AI.</li><li>Explore popular models including Multi-modal models.</li><li>Focus on Prompt Engineering to achieve optimal results.</li></ul><p>Mastering OpenAI APIs</p><ul><li>Hands-on sessions to seamlessly integrate OpenAI APIs into your applications.</li><li>Models, Pricing, Realtime vs Batch APIs and Rate Limiting.</li><li>Cloud AI Offerings (e.g. Azure OpenAI).</li></ul><p>Building LLM RAG Pipelines and Fine-Tuned Models</p><ul><li>Learn to create and optimize Retrieval-Augmented Generation (RAG) pipelines and custom fine-tuned models.</li><li>Use Function Calling model.</li></ul><p>Boosting Productivity with AI Tools</p><ul><li>Discover AI tools that automate creating, testing, reviewing, and debugging code for peak efficiency.</li></ul>',
      ],
      skills: [
        'Prompt Engineering',
        'GenAI',
        'LLM',
        'RAG',
        'Fine-tuned Model',
        'OpenAI',
        'Agentic Workflows',
        'CrewAI',
        'Cursor',
        'LangChain',
      ],
      anchor: 'ai-tools',
      cardTitle: null,
      cards: null,
    },
  ],
  advancedCurriculum: [
    {
      title: 'Low Level Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Eleven.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '11 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      options: [
        { tag: 'lld-intermediate', title: 'LLD Intermediate' },
        { tag: 'lld-advanced', title: 'LLD Advanced' },
      ],
      cards: [
        {
          data: [
            '<p>Master the art of designing and developing industry-grade applications using object-oriented design principles & design patterns. Get ready to crack LLD & machine coding rounds with a structured approach that’s easy to follow.</p>',
          ],
          title: '',
          skills: [],
          tag: 'lld-intermediate',
        },
        {
          data: [
            '<ul><li>Apply OOPs concepts in practical scenarios</li><li>Practice creating relationships (IS-A, HAS-A, USES-A) and cardinality for different use cases</li><li>Understand the importance of OOPs concepts through a series of Design Critique sessions</li></ul>',
          ],
          skills: [
            'Encapsulation',
            'Abstraction',
            'Inheritance',
            'Polymorphism',
            'Relationships',
            'Cardinality',
          ],
          title: 'Applied OOPs Foundations',
          subCards: null,
          tag: 'lld-intermediate',
        },
        {
          data: [
            '<ul><li>Learn tips & tricks to build reusable, modular and extensible software</li><li>Learn to write bug-free code by designing testable components</li><li>Apply SOLID design principles when designing your solution</li><li>Understand the importance of Design patterns and how they can simplify problem solving</li><li>Learn important design patterns like Singleton, Factory, Builder, Strategy</li><li>Practice converting Product Requirement Docs (PRD) into class diagrams and sequence diagrams by applying design principles and patterns</li><li>Go deeper into the world of schema design and understand how it can impact extensibility, speed & querying capabilities of your software</li></ul>',
          ],
          title: 'Designing clean and reusable industry grade applications',
          skills: [
            'Testable Code',
            'SOLID Principles',
            'Object Oriented Design',
            'Design Patterns',
            'Singleton',
            'Factory',
            'Builder',
            'Strategy',
            'Class Diagram',
            'Sequence Diagram',
            'Machine Coding',
            'Schema Design',
            'Normalization',
          ],
          tag: 'lld-intermediate',
        },
        {
          data: [
            '<p>Learn the art of designing robust individual components of a large system, starting with Requirements. Evaluate the key tradeoffs and apply structured design templates to problem statements across multiple domains.</p>',
          ],
          title: '',
          skills: [],
          tag: 'lld-advanced',
        },
        {
          data: [
            '<ul><li>Learn best practices for designing APIs - using the right HTTP status, versioning, naming conventions, query vs path parameters and more</li><li>Learn how to add concurrency to your application, take care of communication between different threads and ensure data consistency</li><li>Understand how latency/throughput impacts software design and the difference between I/O and CPU intensive applications</li><li>Learn the secret behind building large sub-systems incrementally</li></ul>',
          ],
          title: 'LLD Advanced',
          skills: [
            'API Design',
            'API Versioning',
            'Query param',
            'Path param',
            'Threads',
            'Data consistency',
            'I/O vs CPU intensive application',
            'Latency',
          ],
          tag: 'lld-advanced',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Apply Hash to solve real world problems</li><li>Learn BFS/DFS to solve multiple Tree problems effectively</li></ul>',
          ],
          title: 'Hash, Trees',
          skills: [
            'Data Structure Application',
            'Hash',
            'Binary Tree',
            'Binary Search Tree',
            'BFS',
            'DFS',
            'Recursion',
          ],
          tag: 'dsa-intermediate',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Deep dive into graph problem patterns and implement common algorithms like Prim’s, Kruskal’s and Dijkstra\'s</li><li>Learn common problem solving approaches like greedy & backtracking</li></ul>',
          ],
          title: 'Heap, Graphs, Greedy and Backtracking',
          skills: [
            'Graph BFS',
            'Graph DFS',
            'Heaps',
            'Shortest Path',
            'Graph Partitioning',
            'Greedy Method',
            'Advanced recursion and backtracking',
            'Topological Sort',
          ],
          tag: 'dsa-advanced',
        },
        {
          data: [
            '<ul style="padding-left: 30px;"><li>Learn the art of DP and practice problems using Memoization and Tabulation</li><li>Get introduced to advanced DS like Trie, Segment Trees and solve related problems</li></ul>',
          ],
          title: 'Dynamic Programming and Advanced DS',
          skills: [
            'DP Memoization',
            'DP Tabulations',
            'Knapsack',
            'Spanning Tree',
            'Trie',
            'Segment Tree',
          ],
          tag: 'dsa-advanced',
        },
      ],
    },
    {
      title: 'High Level Design',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Six.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '11 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      options: [
        { tag: 'hld-choice', title: 'Architecture & HLD Choice' },
        { tag: 'hld-concepts', title: 'Distributed System Concepts' },
        { tag: 'hld-cloud', title: 'Scaling with cloud' },
      ],
      cards: [
        {
          data: [
            '<p>Understand various design choices available across compute, data-store, and communication required to build a large scale distributed system like Flipkart and learn the art of making the right trade-offs. You will be working hands-on with ZCommerce, Crio’s distributed microservices based scalable application on the cloud.</p><ul><li>Understand the ZCommerce Microservice architecture and contrast it with other styles like event-driven, big-compute, big-data, and more</li><li>Learn why ZCommerce uses both EC2 (IaaS) & Elastic Beanstalk (PaaS) and what other options were evaluated</li><li>Classify workloads in ZCommerce as read-heavy, write-heavy, transactional etc to figure out the right data store for each workload</li><li>Understand the advantages of choosing REST API over gRPC</li><li>Decrease the response time of ZCommerce website using CDN</li></ul>',
          ],
          skills: [
            'Microservices architecture',
            'Event-driven architecture',
            'Containers',
            'Serverless',
            'Virtual Machines',
            'SQL vs NoSQL',
            'Blob',
            'Time Series DB',
            'Columnar DB',
            'CAP Theorem',
            'Transactions',
            'Data Consistency',
            'REST API',
            'gRPC',
            'Static Content Hosting',
            'CDN',
            'S3',
          ],

          title: '',
          subCards: null,
          tag: 'hld-choice',
        },
        {
          data: [
            '<ul><li>Learn tips & tricks to build reusable, modular and extensible software</li><li>Learn to write bug-free code by designing testable components</li><li>Apply SOLID design principles when designing your solution</li><li>Understand the importance of Design patterns and how they can simplify problem solving</li><li>Learn important design patterns like Singleton, Factory, Builder, Strategy</li><li>Practice converting Product Requirement Docs (PRD) into class diagrams and sequence diagrams by applying design principles and patterns</li><li>Go deeper into the world of schema design and understand how it can impact extensibility, speed & querying capabilities of your software</li></ul>',
          ],
          title: 'Designing clean and reusable industry grade applications',
          skills: [
            'Testable Code',
            'SOLID Principles',
            'Object Oriented Design',
            'Design Patterns',
            'Singleton',
            'Factory',
            'Builder',
            'Strategy',
            'Class Diagram',
            'Sequence Diagram',
            'Machine Coding',
            'Schema Design',
            'Normalization',
          ],
          tag: 'lld-intermediate',
        },
        {
          data: [
            '<p>Solve multiple real world scenarios by applying various distributed system concepts and cloud design patterns.</p><ul><li>Get a bird’s-eye view of ZCommerce Microservices and how they communicate with each other</li><li>Find bugs in the ordering flow by tracing API requests across different subsystems from the browser all the way to the database using Browser Dev tool and Kibana</li><li>Figure out why a user is not able to access specific pages by understanding authorization policies enforced by API Gateway</li><li>Understand how to handle Clickstream data traffic spikes effectively using Kafka</li><li>Avoid duplicate orders from user scenarios such as accidental multiple clicks using idempotency pattern</li><li>Keep the checkout experience lightning fast by processing non-critical user flows in an asynchronous manner</li><li>Handle retries and throttling when integrating with rate-limited external APIs</li><li>Create Lambdas to extend order processing functionality and trigger them through webhooks</li></ul>',
          ],
          title: '',
          skills: [
            'Code Comprehension',
            'Swagger',
            'Distributed Tracing',
            'Kibana',
            'Authentication',
            'Authorization',
            'API Gateway',
            'Message Queues',
            'Queue-based Load Leveling',
            'Idempotency pattern',
            'Pub-sub pattern',
            'Rate Limiting',
            'Retry Pattern',
            'Third party API',
            'Lambda',
            'Webhook',
            'Kafka Partition',
            'Cloud Design Patterns',
          ],
          tag: 'hld-concepts',
        },
        {
          data: [
            '<p>After setting up the initial system, start solving scale and performance challenges to ensure best-in-class user experience.</p><ul><li>Troubleshoot multiple API performance issues by analyzing metrics across different layers using Grafana and MySQL Workbench</li><li>Design optimal solutions to manage load for BigBillionDay type events using caching</li><li>Auto-scale notification service to send flash sale alerts to millions of users using horizontal scaling and Load Balancer</li><li>Understand how to handle Clickstream data traffic spikes are handled effectively using Kafka</li><li>Understand how large companies manage fast & concurrent launches leveraging CI/CD pipelines, different environments (dev, stage, prod, test) and Dev-Ops best practices</li><li>Do a rolling update for the order processing lambda function you have written using a Load Balancer</li><li>Understand important aspects of a high availability system such as Replication, Sharding, Circuit Breaker</li></ul>',
          ],
          title: '',
          skills: [
            'API Performance',
            'Monitoring',
            'Metrics',
            'Grafana',
            'MySQL Workbench',
            'DB Indexes',
            'Caching',
            'Cache Invalidation',
            'Auto-Scaling',
            'Horizontal Scaling',
            'Vertical Scaling',
            'DevOps',
            'CI/CD Pipeline',
            'Load Balancer',
            'Rolling Update',
            'Replication',
            'Sharding',
            'Circuit Breaker',
            'Resource Pooling',
          ],
          tag: 'hld-cloud',
        },
      ],
    },
    {
      title: 'Advanced Interview Blitz',
      staticImage: (
        <StaticImage
          imgStyle={{ objectFit: 'contain' }}
          loading="lazy"
          src="../images/v5/CurriculumIcons/Two.png"
          placeholder="blurred"
          alt="Mobile Navbar"
        />
      ),
      image: null,
      duration: '2 weeks',
      tag: null,
      data: [],
      skills: [],
      anchor: 'dsa',
      cardTitle: null,
      options: [
        { tag: 'low-level-design', title: 'Low Level Design' },
        { tag: 'hld-challenges', title: 'HLD Challenges' },
      ],
      cards: [
        {
          data: [
            '<p>Prepare to take on LLD interviews with confidence</p><ul><li>Learn the art of cracking machine coding rounds with focus on coming up with quick designs and structured execution</li><li>Optimize code by using better data structures</li><li>Practice design questions such as BookMyShow, Splitwise, and Trello, commonly asked in LLD rounds conducted by leading product companies</li></ul>',
          ],
          skills: [
            'LLD',
            'Machine Coding',
            'Schema Design',
            'Object Modeling',
            'Interview Preparation',
          ],
          title: '',
          subCards: null,
          tag: 'low-level-design',
        },
        {
          data: [
            '<p>Prepare to take on HLD interviews with confidence.</p><ul><li>Utilize the practical knowledge gained to solve real world design problems often asked in interviews</li><li>Learn how to effectively design systems prioritizing right use-cases and making the appropriate tradeoffs</li><li>Practice commonly asked interview questions like design Twitter timeline, Notification system, BookMyShow, Hotstar</li></ul>',
          ],
          title: '',
          skills: ['HLD', 'System Design', 'Interview Preparation'],
          tag: 'hld-challenges',
        },
      ],
    },
    // {
    //   title: 'Advanced Backend Engineering',
    //   staticImage: (
    //     <StaticImage
    //       imgStyle={{ objectFit: 'contain' }}
    //       loading="lazy"
    //       src="../images/v5/CurriculumIcons/One.png"
    //       placeholder="blurred"
    //       alt="Mobile Navbar"
    //     />
    //   ),
    //   image: null,
    //   duration: '4 weeks',
    //   tag: null,
    //   data: [
    //     'Build advanced features for QEats, a Swiggy-like food ordering app. Solve for performance bottlenecks in the backend and improve REST API performance.',
    //   ],
    //   skills: [
    //     'Spring Security',
    //     'Caching',
    //     'Redis',
    //     'JMeter',
    //     'Multi-threading',
    //   ],
    //   cardTitle: null,
    //   cards: null,
    // },
    // {
    //   title: 'Advanced System Design',
    //   staticImage: (
    //     <StaticImage
    //       imgStyle={{ objectFit: 'contain' }}
    //       loading="lazy"
    //       src="../images/v5/CurriculumIcons/Two.png"
    //       placeholder="blurred"
    //       alt="Mobile Navbar"
    //     />
    //   ),
    //   image: null,
    //   duration: '6 weeks',
    //   tag: null,
    //   data: [
    //     'Master Low Level Design and get an introduction into various components/concepts in High Level Design through workshops, projects, and hands-on activities.',
    //   ],
    //   skills: null,
    //   cardTitle:
    //     'Curriculum to crack System Design interviews for super-dream jobs',
    //   cards: [
    //     {
    //       image: null,
    //       data: [
    //         '<p>Build a Hackerrank like contest platform by applying your Core Java and Low Level design skills<ul><li>Design & implement the classes required for a contest to work.</li><li>Implement the business logic of running a simulated contest.</li></ul></p>',
    //       ],
    //       skills: [
    //         'Machine Coding',
    //         'SOLID Principles',
    //         'Object Oriented Design',
    //         'Clean Architecture',
    //         'Design Patterns',
    //         'Schema Design',
    //       ],
    //       title: 'Build a Hackerrank-like App to master Low Level Design',
    //     },
    //     {
    //       image: null,
    //       data: [
    //         'Learn the art of cracking Machine Coding rounds with solid Low Level Design(LLD) skills',
    //       ],
    //       skills: null,
    //       title: 'Build Low Level Design for a iTunes-like App',
    //     },
    //     {
    //       image: null,
    //       data: [
    //         'Understand essential components/concepts of a modern tech stack used universally by internet companies with bonus hands-on exercises.',
    //       ],
    //       skills: [
    //         'Docker',
    //         'Kafka',
    //         'CDN',
    //         'Load Balancer',
    //         'Deployment',
    //         'Micro-Services',
    //         'CI/CD',
    //         'Cloud',
    //       ],
    //       title: 'High Level Design Concepts',
    //     },
    //     {
    //       image: {
    //         childImageSharp: {
    //           gatsbyImageData: {
    //             layout: 'constrained',
    //             placeholder: {
    //               fallback:
    //                 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC6ElEQVQ4y3WVS0hUURjH//eOEoEEbVtHC2lVi4LooYEwo7UIE3xMoahjvl8z99wZa0KyB6bO3DuNtUlqE20kwWgVBbULIiEqEBQJo1qIKzOI+4/7vlfHxeGec8/5fud7H0ATgKZUQlMWoSlb0AShKwY0YUA35yWGtWedoS1jyZoMuLBNX8A5qCv0vu7w1oLexd4lyqbFsuj2j21o7gFXQ4VyQWGkkKJkAT24YZ21NTQsWZuxCEtl3dnQA6boig2ZyhCT14mZdAAq7K8r4ypisgI+o70ZMDOXZnShlcWPMVY+67LWUkGEobowAgoRzg3+hmMmpjNsfNVC/jvM+T/VPPCll3L2CuWpYaKg+lBdhKG+33ygNfIqX6+eYW4jSiyrxEqKZfEoI92NRFF1TS4B3JUW9qFILsVj7xLEjyTxU6H0tI04WU001RMP9gDqHjCcFpI3F5TnEsSNRuL0ecqxGuJmD1FMO0AvZUoANWGDzDGjEoUMy8YTlMf7iOwAMdJBOZsgJnoJLaChF1TFBQYSNq8SOZWH5gZ5cKaPUrbL9pc5HmUIbYwVt9opz47YUF31I+2mUbAq9hdH+eRzDfm3kgNLF4i7vZR1R/Npleeet7N2OU6sJikt9FO6N+j60w+sCTMrAffH2PamkeQRTvyqI9ZTjNxpYPlQ3PLZPj3J6NerjHwfJtaSlJb6Wd5xkfLtXhMaMtmAmXdTwqh4O8SjvzuJdUGsJInmOkq1USKfolQUxLcksTZKbAji5TXiVBWlnhbiYcZA3jfZsKtCMfBYEB+SxPtuIllPHD9LNF22zcopxIsU8WmQmG8nLsWIE1WE0knMpkNAxwdmUIQh51PEYCsRrSPiDcTkiF0ZeWufmBwimhuIWB0x0EoUAgGxSs9rDk5jMB1r5pl5galZqMwUe21G3ZwX0zuag9gKty/dMd/vIjsbwc4u4863nfliuMGGc2onpNS6ZIMNPwF2ThqhbrzXE6DvfgL+AxeqJsiziNC8AAAAAElFTkSuQmCC',
    //             },
    //             backgroundColor: 'transparent',
    //             images: {
    //               fallback: {
    //                 src: '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png',
    //                 srcSet:
    //                   '/static/d55c8df9a40f9b3fe9147dc1b15cb291/9c1f1/Six.png 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f159a/Six.png 600w',
    //                 sizes: '(min-width: 300px) 300px, 100vw',
    //               },
    //               sources: [
    //                 {
    //                   srcSet:
    //                     '/static/d55c8df9a40f9b3fe9147dc1b15cb291/5de9a/Six.webp 300w,\n/static/d55c8df9a40f9b3fe9147dc1b15cb291/f0b89/Six.webp 600w',
    //                   type: 'image/webp',
    //                   sizes: '(min-width: 300px) 300px, 100vw',
    //                 },
    //               ],
    //             },
    //             width: 300,
    //             height: 300,
    //           },
    //         },
    //       },
    //       data: null,
    //       skills: null,
    //       title: null,
    //     },
    //   ],
    // },
  ],
};
