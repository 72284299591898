import RoundedLayout from '@components/v5/layouts/RoundedLayout';
import React from 'react';
import WhyCrioSectionV4 from '@components/v5/WhyCrioSectionV4';
import data from '../data/data.json';

function CertGenProgramsSection() {
  return (
    <RoundedLayout
      roundedBg="my-10 "
      className="bg-[#E6F4F2] py-10 text-black"
      both
    >
      <WhyCrioSectionV4
        headingClassName="text-3xl xl:text-[42px] leading-[54px]"
        descriptionClassName="text-xl text-black"
        heading={data.programs.heading}
        description={data.programs.description}
      ></WhyCrioSectionV4>
    </RoundedLayout>
  );
}

export default CertGenProgramsSection;
