import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import RawHTML from '@components/Common/RawHTML';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function HostInfo() {
  const { getSessionHostInfo } = useRegisterMetadata();
  const sessionHostInfo = getSessionHostInfo();

  if (!sessionHostInfo) return null;
  return (
    <MasterclassSectionLayout
      className="container grid grid-cols-1 pt-[30px] md:pt-[60px] lg:grid-cols-[1.4fr_1fr]"
      heading={'Meet Out Host'}
      doesSectionHaveBackground={true}
      doesSectionHaveListItems={true}
    >
      <RawHTML className="pl-2 font-rubik text-[8px] leading-[14px] text-v5-neutral-600 md:text-[18px] md:leading-[28px]">
        {sessionHostInfo}
      </RawHTML>
    </MasterclassSectionLayout>
  );
}
