export const HOME_PAGE_SEO_TITLE = 'Crio.Do | Webinars and Masterclasses';

export const HOME_PAGE_SEO_DESCRIPTION =
  'Discover top certified webinars and masterclasses in software development, data analytics, and QA automation. Register today to learn from industry experts and advance your career with Crio';

export const HOME_PAGE_CANONICAL_URL = 'https://www.crio.do/masterclass';

export const HOME_PAGE_SCRIPT = `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Explore Crio.Do’s Webinars and Masterclasses",
  "url": "https://www.crio.do/masterclass",
  "description": "Explore Crio's certified free webinars and masterclasses in software development, data analytics, and QA automation. Discover learning opportunities and register to advance your career.",
  "publisher": {
    "@type": "Organization",
    "name": "Crio",
    "url": "https://www.crio.do",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.crio.do/images/logo.png",
      "width": 250,
      "height": 60
    }
  },
  "sameAs": [
    "https://www.facebook.com/criodo",
    "https://twitter.com/crio_do",
    "https://www.instagram.com/crio.do/",
    "https://www.youtube.com/@CrioDo",
    "https://www.linkedin.com/company/criodo/"
  ],
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://www.crio.do/events?query={search_term_string}",
    "query-input": "required name=search_term_string"
  }
}`;
