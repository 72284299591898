import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';

export default function InfoCardV3({
  icon,
  title,
  description,
  dark,
  gradient,
}) {
  let image = '';
  if (icon?.childImageSharp) {
    image = getImage(icon);
  }

  const getCardClass = () => {
    let className =
      ' flex-1  relative p-8 flex flex-col  gap-4 rounded-[20px] bg-[#003333] md:min-h-[250px]';

    if (gradient) {
      className = className.concat(
        ' gradient-card-dark border-gradient border-gradient-radius20 shadow-md blur-[5px]',
      );
    }

    return className;
  };

  return (
    <div className={getCardClass()}>
      <div className="flex items-center justify-start gap-6">
        <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#004246] p-2.5">
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <GatsbyImage
              loading="lazy"
              image={image}
              alt={'Fellowship Icon'}
              className="h-full w-full"
              imgClassName="object-contain"
            />
          )}
        </div>
        {title && (
          <div className="text-2xl font-semibold leading-tight text-[#2DF8C5]">
            <RawHTML>{title}</RawHTML>
          </div>
        )}
      </div>

      {description && (
        <p className="text-lg leading-relaxed text-[#e5e5e5]">
          <RawHTML>{description}</RawHTML>
        </p>
      )}
    </div>
  );
}
