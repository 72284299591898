import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clickEventEntityType, useWebEngage } from '@src/hooks/useWebEngage';
import { useLocation } from '@reach/router';
import UrgencyTopStrip from '@components/UrgencyTopStrip/index';
import { ERoutes } from '@src/constants/routes/index';
import ApplyAtCrioTopStrip from '@components/ApplyAtCrioTopStrip/index';
import useTopStripValidPages from '@src/hooks/useTopStripValidPages';

const TopStrip = () => {
  const validPaths = useTopStripValidPages();

  const { captureUserClickEvent } = useWebEngage();

  // Path variables
  const location = useLocation();
  const pathName = location?.pathname;

  const isUrgencyTopStripVisible = validPaths.includes(pathName);
  const isAboutUsPage = pathName === `${ERoutes.ABOUT}/`;

  const onPlacementButtonClick = () => {
    window.open('/placements');

    captureUserClickEvent(
      'Get our Placement Stats',
      { placementLocation: 'TOP_STRIP' },
      clickEventEntityType.ANCHOR_TAG,
    );
  };

  if (isAboutUsPage) {
    return <ApplyAtCrioTopStrip />;
  }

  if (isUrgencyTopStripVisible) {
    return <UrgencyTopStrip />;
  }

  return (
    <div
      className="flex h-[44px] w-[100dvw] cursor-pointer items-center border-b-[1px] border-white bg-v5-green-100 px-6 text-sm sm:text-base md:h-[40px]"
      onClick={onPlacementButtonClick}
    >
      <div className="flex flex-1 flex-col items-center justify-center text-center md:flex-row">
        <span className="font-rubik">
          Find out where Crio grads get placed.&nbsp;
        </span>

        <span className="flex items-center justify-center font-rubik font-bold underline">
          Get our Placement Stats{' '}
          <span className="ml-2">
            <FontAwesomeIcon icon={['fas', 'angle-right']}></FontAwesomeIcon>
          </span>
        </span>
      </div>
    </div>
  );
};

export default TopStrip;
