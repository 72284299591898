import { graphql, useStaticQuery } from 'gatsby';

export default function useGraphqlImage() {
  const masterclassEventsImgNode = useStaticQuery(graphql`
    query {
      Thumbnail: allFile(
        filter: { relativePath: { regex: "images/masterclass/" } }
      ) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);
  return {
    masterclassEventsImgNode,
  };
}
