import React from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import { makeStyles } from '@mui/styles';

const fullConfig = resolveConfig(tailwindConfig);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `none`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: props.isExpanded ? '100%' : 'auto',
    overflow: 'hidden',
    background: fullConfig.theme.colors['v5']['green']['700'],
    // border: props.isExpanded ? '1px solid white !important' : '',
  }),
  content: {
    margin: '8px 0px',
  },
}));

export default Accordion;
