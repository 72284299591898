import { cn } from '@/lib/utils';
import CrioButton from '@components/v5/CrioButton/index';
import Cookies from 'js-cookie';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

// import usePhoneVerification from '@src/hooks/usePhoneVerification';
import { getCountryCodeAndPhone } from '@src/utils/helper';
import LeadGenerationService from '../../../../utils/lead-generation';

import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import useResizer from '@components/extra/useResizer';

export default function OTPVerification({ onChangePage, setIsPhoneVerified }) {
  const { phone, email } = useContext(GlobalStateContext);
  const isMobile = useResizer();
  const [otp, setOtp] = useState('');
  const [OTPlessSignin, setOTPlessSignin] = useState(null);
  const [currentOTPColor, setCurrentOTPColor] = useState('#525252');
  const [currentBorderColor, setCurrentBorderColor] = useState('#B9CFCA');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(15);
  const [OTPLessResult, setOTPLessResult] = useState(null);
  const [retryCount, setRetryCount] = useState(
    parseInt(Cookies.get('retryCount')) || -1,
  );

  useEffect(() => {
    if (retryCount > 0) Cookies.set('retryCount', retryCount, { expires: 15 });
    if (retryCount >= 3) {
      const onRetryCountExhaust = async () => {
        const leadAndActivityPayload = {
          LeadDetails: [
            {
              Attribute: 'EmailAddress',
              Value: email,
            },
          ],
          Activity: {
            ActivityEvent: ELeadSquaredActivityCode.OTP_VERIFICATION,
            Fields: [
              { SchemaName: 'mx_Custom_1', Value: '' },
              { SchemaName: 'mx_Custom_2', Value: 'FAILURE' },
              { SchemaName: 'mx_Custom_3', Value: 'OTP Limit Exhausted' },
            ],
          },
        };
        await LeadGenerationService.queueLeadAndActivity(
          leadAndActivityPayload,
        );
      };
      onRetryCountExhaust();
    }
  }, [retryCount]);

  useEffect(() => {
    console.log('OTPlessSignin', OTPlessSignin);
  }, [OTPlessSignin]);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'otpless-sdk';
    script.src = 'https://otpless.com/v2/headless.js';
    script.setAttribute('data-appid', 'NMKOH3MB90BPT9IRDN6M');
    script.onload = () => {
      initializeSDK();
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const timer =
      timeLeft > 0
        ? setInterval(() => {
            setTimeLeft(timeLeft - 1);
          }, 1000)
        : null;

    if (timeLeft === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timeLeft]);

  const initializeSDK = () => {
    window.otpless = (otplessUser) => {
      console.log(otplessUser);
    };

    setOTPlessSignin(new window.OTPless(window.otpless));
  };

  const sendOTP = async () => {
    const { countryCode, phoneNum } = getCountryCodeAndPhone(phone);

    setRetryCount((prev) => prev + 1);
    try {
      const res = await OTPlessSignin.initiate({
        channel: 'PHONE',
        phone: phoneNum,
        countryCode: countryCode,
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (OTPlessSignin && retryCount < 3) {
      setTimeout(() => {
        sendOTP();
      }, 4000);
    }
  }, [OTPlessSignin]);

  useEffect(() => {
    async function onVerifyOTP() {
      if (otp.length === 6) {
        const { countryCode, phoneNum } = getCountryCodeAndPhone(phone);
        const leadAndActivityPayload = {
          LeadDetails: [
            {
              Attribute: 'EmailAddress',
              Value: email,
            },
          ],
          Activity: {
            ActivityEvent: ELeadSquaredActivityCode.OTP_VERIFICATION,
          },
        };

        const res = await OTPlessSignin.verify({
          channel: 'PHONE',
          phone: phoneNum,
          otp: otp,
          countryCode: countryCode,
        });

        setOTPLessResult(res);

        const currPhone = localStorage.getItem('oldPhone');

        if (res.success) {
          setCurrentBorderColor('#02BF91');
          setCurrentOTPColor('#02BF91');
          leadAndActivityPayload.Activity.Fields = [
            { SchemaName: 'mx_Custom_1', Value: otp },
            {
              SchemaName: 'mx_Custom_2',
              Value:
                !currPhone || currPhone === phone
                  ? 'SUCCESS'
                  : 'Verified with new number',
            },
            { SchemaName: 'mx_Custom_3', Value: res.response.verification },
          ];
          localStorage.removeItem('oldPhone');
          Cookies.set('isPhoneVerified', true, { expires: 15 });
        } else {
          setCurrentBorderColor('#E84D42');
          setCurrentOTPColor('#E84D42');
          setErrorMessage('The entered OTP seems to be incorrect.');
          leadAndActivityPayload.Activity.Fields = [
            { SchemaName: 'mx_Custom_1', Value: '' },
            { SchemaName: 'mx_Custom_2', Value: 'FAILURE' },
            { SchemaName: 'mx_Custom_3', Value: res.response.errorMessage },
          ];
        }
        await LeadGenerationService.queueLeadAndActivity(
          leadAndActivityPayload,
        );
      } else {
        setCurrentBorderColor('#B9CFCA');
        setCurrentOTPColor('#525252');
        setErrorMessage('');
      }
    }
    onVerifyOTP();
  }, [otp]);

  return (
    <div className="flex flex-col items-center text-center font-manrope md:items-start md:text-left">
      <div className="mb-2 text-xl font-extrabold leading-4 md:text-4xl md:leading-[50px]">
        Welcome to Crio.Do
      </div>
      <div className="mb-2 text-[10px] font-normal leading-[18px] text-v5-neutral-500 md:text-[22px] md:leading-9">
        We've sent you a 6 digit OTP on{' '}
        <strong className="font-bold">{phone}</strong>
      </div>
      <div className="mb-7 text-[10px] font-normal leading-[18px] text-v5-neutral-500 md:text-lg md:leading-9">
        <span>Not the right phone number?&nbsp;</span>
        <span
          className="cursor-pointer font-extrabold text-v5-green-200 underline"
          onClick={() => {
            onChangePage('editPhone');
          }}
        >
          Edit
        </span>
      </div>
      <div className="relative pb-10">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: isMobile ? '30px' : '50px',
            height: isMobile ? '30px' : '50px',
            marginRight: '16px',
            fontSize: isMobile ? '12px' : '22px',
            fontWeight: '700',
            color: `${currentOTPColor}`,
            borderRadius: '4px',
            border: `1.5px solid ${currentBorderColor}`,
          }}
          containerStyle="w-[260px] md:w-[450px]"
        />
        <div className="absolute font-normal text-[#E84D42] md:text-[12px] md:leading-9">
          {errorMessage}
        </div>
      </div>
      <div className="mb-8">
        <CrioButton
          variant="primary"
          className={`flex h-[40px] justify-between px-8 text-xl md:h-[60px] md:w-[300px]`}
          onClick={() => {
            if (OTPLessResult?.success) {
              onChangePage('signInPage');
            }
          }}
        >
          <div className="text-base md:ml-14 md:text-2xl">{'Continue'}</div>
        </CrioButton>
      </div>

      {retryCount >= 3 ? (
        <div className="text-[10px] font-normal leading-relaxed text-v5-neutral-500 md:text-[18px] md:leading-normal">
          <div>Maximum number of attempts has been exhausted.</div>
          <div>
            Click here to{' '}
            <strong
              className="cursor-pointer text-v5-green-200 underline"
              onClick={() => onChangePage('signInPage')}
            >
              Sign-In
            </strong>
          </div>
        </div>
      ) : (
        <div className="flex gap-2 text-[10px] leading-[18px] text-v5-neutral-500 md:text-lg md:leading-9">
          <div>
            <span>Did not receive the OTP?&nbsp;</span>
            <span
              className={cn(
                timeLeft > 0
                  ? 'cursor-not-allowed font-extrabold text-v5-neutral-500'
                  : 'cursor-pointer font-extrabold text-v5-green-200',
              )}
              style={{
                color: timeLeft > 0 ? '#525252' : '#02BF91',
              }}
              onClick={() => {
                if (retryCount < 3 && timeLeft === 0) {
                  setTimeLeft(15);
                  sendOTP();
                }
              }}
            >
              {retryCount >= 3 ? 'Retry Exhausted' : 'Resend'}
            </span>
          </div>
          {timeLeft !== 0 && retryCount < 3 && (
            <span className="font-extrabold text-v5-green-200">
              0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
