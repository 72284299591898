import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { AnimatePresence, motion } from 'framer-motion';
import { navigate } from 'gatsby'; //import navigate from gatsby
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';

function BottomSheet({ children, ...props }) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const wrapperRef = useRef();

  const [isVisible, setIsVisible] = useState(true);

  const sendToCMS = (email, props) => {
    let temp = '';
    var clone = Object.assign({}, props);
    clone.email = email;
    clone.adblocker = typeof window.webengage == 'undefined' ? true : false;
    clone.utm = encodeURIComponent(state.utm);
    clone.url = window.location.href;

    Object.keys(clone).map((ele, idx) => {
      if (clone[ele] != undefined && clone[ele]?.length != 0) {
        temp += `${ele}=${clone[ele]}`;
        if (idx != Object.keys(clone).length - 1) {
          temp += '&';
        }
      }
    });

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&${temp}`,
      );
    }
  };

  const handleEmailSubmit = (e, props) => {
    e.preventDefault();
    let email = e.target.email.value.toLowerCase().trim();
    dispatch({
      type: 'SET_EMAIL',
      payload: email,
    });
    GTM.setEmail(email);

    GTM.login({
      userId: email,
      email: email,
    });
    sendToCMS(email, props);

    //GTM Event for Email Capture
    if (!state.isEmailEventSubmitted) {
      GTM.track(gtmEvents.EMAIL_SUBMITTED, {
        type: props.type,
        location: props.location,
        ...(props.secondaryType && { secondaryType: props.secondaryType }),
      });

      dispatch({
        type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
        payload: true,
      });
    }

    navigate('/registration', {
      state: {
        program: props.program,
        link: props.link,
        type: props.type,
        ...(props.secondaryType && { secondaryType: props.secondaryType }),
      },
    });
  };

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = 'no';
    return () => {
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
    };
  }, []);
  useOnClickOutside(wrapperRef, () => {
    if (isVisible) {
      setIsVisible(false);
      props.onClose();
    }
  });

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  return (
    <div>
      <div
        className="bottom-sheet-root"
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence>
          {isVisible && (
            <motion.div
              ref={wrapperRef}
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ type: 'tween', stiffness: 100 }}
              exit={{ y: 1000 }}
              className="bottom-sheet rounded-t-[30px] text-lg font-light text-gray-800"
            >
              <div
                className="flex w-full justify-end"
                onClick={() => {
                  setIsVisible(false);
                  setTimeout(() => props.onClose(), 100);
                }}
              >
                {' '}
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  size="1x"
                ></FontAwesomeIcon>
              </div>

              <div className="pt-6">
                <p className="mb-3 ">Enter your email ID</p>
                <form
                  onSubmit={(e) => {
                    handleEmailSubmit(e, props);
                  }}
                >
                  <input
                    defaultValue={state.email}
                    autoFocus
                    type="email"
                    name="email"
                    className="email-input-bottom-sheet mb-12"
                    required
                  ></input>
                  <button className="w-full" type="submit">
                    {children}
                  </button>
                </form>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <style>
        {`
        .bottom-sheet-root{
            height:100vh;
            width:100vw;
            z-index:999;
            position:fixed;
            background-color: rgba(0, 0, 0, .5);
            overflow: hidden;
        }
         .bottom-sheet{
             position:fixed;
             bottom:0;
             width:100vw;
            background:#fff;
            padding:20px 30px 30px 30px;
            z-index:999;
            // box-shadow: 0px 0px 1px 100vmax rgba(0,0,0,0.6);
            overflow: hidden;

         }
        

         .email-input-bottom-sheet{
             border-bottom:1px solid #999;
             width:100%;

         }
          `}
      </style>
    </div>
  );
}

export default BottomSheet;
