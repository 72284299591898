import React from 'react';

/**
 * NavbarLayout component for rendering the layout of the navbar.
 * Parent Component: Navbar
 *
 * @param {object} props - The props for the component.
 * @param {string} props.classname - Additional CSS class for styling the navbar.
 * @param {React.ReactNode} props.children - The children elements to be rendered within the navbar layout.
 * @returns {JSX.Element} - Navbar layout component JSX.
 */
export default function NavbarLayout({ children, classname = '' }) {
  return <nav className={classname}>{children}</nav>;
}
