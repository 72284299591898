import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import InfoCardV3 from '../InfoCardV3';

const WhyCrioSectionV4 = React.memo(function WhyCrioSectionV4(props) {
  return (
    <SectionLayout
      heading={'An All-round Effective Way to Supercharge your Tech Career'}
      description={
        <>
          <strong className="font-bold text-black">
            Hands-on, work experience-based learning
          </strong>{' '}
          is at the heart of everything you do at Crio. Build software projects
          like the top 1% Tech Professionals and{' '}
          <strong className="font-bold text-black">learn all the skills</strong>{' '}
          you need to{' '}
          <strong className="font-bold text-black">land the best</strong>{' '}
          Fullstack/Backend Developers or SDET Jobs.
        </>
      }
      {...props}
    >
      <div className=" container mx-auto px-4">
        <div className="flex justify-center">
          <div className="grid w-full  grid-cols-1 place-items-center gap-4 md:grid-cols-2">
            {(props.data || data).map((ele, idx) => (
              <a href={ele.canonicalUrl}>
                {' '}
                <InfoCardV3
                  gradient={props.gradient}
                  dark={true}
                  key={idx}
                  icon={ele.image}
                  title={ele.title}
                  description={ele.description}
                  fellowship={ele.fellowship}
                ></InfoCardV3>
              </a>
            ))}
          </div>
        </div>
      </div>
    </SectionLayout>
  );
});

export default WhyCrioSectionV4;

let data = [
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointOne.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Fellowship Program in Software Development',
    description:
      'Upskill and land your dream career as a coveted Full-Stack or Backend developer',
    canonicalUrl: 'https://www.crio.do/software-development-fellowship-program',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointTwo.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Fellowship Program in QA Automation (SDET)',
    description:
      'Get practical work experience of automation testing & get software testing roles in top companies.',
    fellowship: true,
    canonicalUrl: 'https://www.crio.do/qa-automation-testing',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointThree.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Fellowship Program in NextGen Data Analytics with Al',
    description:
      'Master the in-demand, Al-powered data analytics skills to land top data analyst roles',
    canonicalUrl: 'https://www.crio.do/ai-data-analytics',
  },

  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointFour.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Fellowship Program In System Design',
    description:
      'Master advanced software development and design skills with hands-on experience in System Design',
    canonicalUrl: 'https://www.crio.do/fellowship-program-system-design',
  },
];
