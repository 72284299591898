import React from 'react';
import * as styles from './FloatingBar.module.scss';

export default function FloatingBar(props) {
  return (
    <div
      className={`${styles.bar} w-10/12 bg-crio-neutral-75 px-10 py-5 text-crio-green-900 md:w-max`}
    >
      <div className="row block justify-evenly text-center md:flex md:text-left ">
        {props.data.map((val, idx) => (
          <div className="py-3 md:px-5 md:py-0" key={idx}>
            <p>{val.title}</p>
            <h5>{val.description}</h5>
          </div>
        ))}
      </div>
    </div>
  );
}
