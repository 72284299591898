import React, { useState } from 'react';
import * as styles from './ProjectCategoryCard.module.scss';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Arrow from '../../images/project-arrow.svg';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

export default function ProjectCategoryCard(props) {
  const [hover, setHover] = useState(false);

  const onCategoryCardClicked = () => {
    GTM.track(gtmEvents.PROJECT_CATEGORY_CLICKED, {
      categoryUrl: props.url,
      meCategoryId: props.meCategoryId,
    });
  };

  return (
    <Link to={props.url}>
      <div
        className="pr-3"
        onClick={onCategoryCardClicked}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={`${styles.categoryTile} relative mb-1 h-[168px] text-white md:h-[200px]`}
        >
          <div className="">
            <GatsbyImage
              loading="lazy"
              image={props.image}
              alt={props.meCategoryId}
              layout="fullWidth"
              imgStyle={{ objectFit: 'contain' }}
              className="absolute z-0 w-full "
            />
            <div className=" absolute top-0 left-0 z-10 h-full w-full">
              <div className="  items-around flex  flex-col justify-around p-5 pt-3">
                <h4 className="font-light">{props.meCategoryId}</h4>
                <h4>Projects</h4>
                <div
                  className="flex h-full items-end justify-end text-right"
                  data-nosnippet
                >
                  <div className={`${styles.arrow} absolute bottom-3 right-3 `}>
                    <Arrow className="h-[30px] w-[30px]"></Arrow>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
