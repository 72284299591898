import React, { useContext } from 'react';
import ApplyNowCTA from '@components/Common/ApplyNowCTA/index';
import * as styles from './ProjectContribute.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { GTM } from '@src/analytics/gtm';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

export default function ProjectContribute() {
  const { captureUserClickEvent } = useWebEngage();
  const state = useContext(GlobalStateContext);

  return (
    <section className="relative">
      <span id="contribute" className="anchor"></span>
      <div className={`py-10 ${styles.contribute}`}>
        <div className="container ">
          <div className="row ">
            <div className="col-12 col-lg-3  flex flex-col items-center md:block">
              <StaticImage
                loading="lazy"
                src="../../images/projects/SubmitProjects.png"
                placeholder="blurred"
                width={300}
                alt="Submit Project"
              />
            </div>
            <div className="col-12 col-lg-8 order-md-last order-first mb-5 text-center  md:block   md:text-left lg:pl-5">
              {' '}
              <h3 className="mb-4">Submit your Projects</h3>
              <p className="mb-5  text-center md:text-left">
                Would you like to contribute your own projects to Crio Projects
                Hub? Share your project with us and we will work with you to
                feature it here.
              </p>
              <div
                className="mb-5 flex justify-center md:justify-start"
                onClick={() => {
                  captureUserClickEvent('Submit Project');

                  GTM.leadSquaredEvent(
                    ELeadSquaredActivityCode.SUBMIT_PROJECT_SUBMITTTED,
                    state?.email,
                  );
                }}
              >
                <ApplyNowCTA
                  width={150}
                  type={'PROJECTS_PAGE'}
                  link={'V8NZk3pp'}
                  location="PROJECTS_PAGE"
                  text="Submit Project"
                  innerText="Submit Project"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                  className="bg-crio-green-200 "
                  applyNowInsideClassName="bg-crio-green-200 text-white"
                ></ApplyNowCTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
