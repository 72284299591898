// extracted by mini-css-extract-plugin
export var accordion = "Projects-module--accordion--fb00e";
export var button = "Projects-module--button--13cf3";
export var categoryName = "Projects-module--categoryName--70e29";
export var categorySection = "Projects-module--categorySection--5d4f8";
export var custom = "Projects-module--custom--6812e";
export var infoBanner = "Projects-module--infoBanner--92202";
export var more = "Projects-module--more--75ce0";
export var project = "Projects-module--project--07f3a";
export var projectSearch = "Projects-module--projectSearch--61ebd";
export var searchParent = "Projects-module--searchParent--b640a";
export var searchSubtitle = "Projects-module--searchSubtitle--c2f7d";
export var skillPill = "Projects-module--skillPill--c630b";