import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import * as styles from './card.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function AccordionV2(props) {
  const [open, setOpen] = useState(props.open);
  let badge = { type: '', color: '', bgColor: '' };
  if (props.type === 'FREE_TRIAL') {
    badge.type = 'Free Trial';
    badge.color = 'text-crio-green-950';
    badge.bgColor = 'bg-crio-green-50';
    badge.circleColor = 'bg-crio-green-950';
  } else if (props.type === 'INDEPENDENT_PROJECT') {
    badge.type = 'Independent project';
    badge.color = 'text-crio-green-25';
    badge.bgColor = 'bg-v5-green-200';
    badge.circleColor = 'bg-crio-green-25';
  } else if (props.type === 'INTERNSHIP_GRADE_PROJECT') {
    badge.type = 'Internship-grade project';
    badge.color = 'text-crio-green-150';
    badge.bgColor = 'bg-crio-green-600';
    badge.circleColor = 'bg-crio-green-150';
  }
  return (
    <div
      className={` sticky cursor-pointer items-center rounded-lg px-6 py-6 text-left ${
        props.className
      } ${props.bgColor ? props.bgColor : 'bg-crio-neutral-100'}`}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="flex items-center justify-between">
        <div>
          {badge.type && (
            <div className="mb-4">
              <span
                className={`${badge.bgColor} ${badge.color} flex w-max items-center rounded-lg px-2 py-1 text-xs`}
              >
                <div>
                  <div
                    className={`${styles.circle} ${badge.circleColor} mr-1`}
                  ></div>
                </div>
                <div>{badge.type}</div>
              </span>
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center">
                <GatsbyImage
                  loading="lazy"
                  image={props.image}
                  alt={'Takeaway'}
                  className={styles.circleImage}
                />
                {/* <Image
                  className={styles.circleImage}
                  filename={`strapi/${props.image}`}
                ></Image> */}
              </div>
              <div>
                <h6 className={`${styles.customFont} text-crio-green-900`}>
                  {props.title}
                </h6>
                {props.duration && (
                  <p className="text-sm text-crio-neutral-600 ">
                    {props.duration}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {' '}
          {open ? (
            <FontAwesomeIcon icon={['fas', 'arrow-up']} size="lg" />
          ) : (
            <FontAwesomeIcon icon={['fas', 'arrow-down']} size="lg" />
          )}
        </div>
      </div>
      {open && (
        <div className={`mt-3 ${styles.customFont}`}>
          <p className="font-light leading-relaxed">{props.description}</p>
          {props.skills?.length > 0 && (
            <div
              className={`${styles.customFont} mt-5 mb-5 flex flex-wrap text-sm font-semibold`}
            >
              {props.skills.map((ele, idx) => (
                <div key={idx} className={styles.pill}>
                  {ele.Name}
                </div>
              ))}
            </div>
          )}
          {props.takeaways?.length > 0 && (
            <div className="mb-5">
              {props.takeaways.map((ele, idx) => (
                <div className="mb-2 ml-3 flex md:ml-2" key={idx}>
                  <div>
                    {' '}
                    <FontAwesomeIcon
                      icon={['fas', 'star']}
                      size="1x"
                      className="mr-3 text-crio-green-600"
                    ></FontAwesomeIcon>
                  </div>
                  <div>
                    <ReactMarkdown>{ele.description}</ReactMarkdown>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
