import React from 'react';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';

import { GatsbyImage } from 'gatsby-plugin-image';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function EventRegistrationHero() {
  const { getBannerImage, getEventDetails, onSubmitL2, onSubmitOtp } =
    useRegisterMetadata();

  const { isPastEvent } = getEventDetails();

  return (
    <div className={`w-screen pt-20 md:pt-8`}>
      <div className="shadow-[-1px_23px_25.9px_-21px_#0000001A] md:pt-8 ">
        <GatsbyImage
          image={getBannerImage()}
          alt="Crio Masterclass"
          className="h-full w-full"
        />
        {/* Desktop Lead Gen Open Form: Scrollable and sticky */}
        <div className="absolute right-[8%] top-0 hidden h-[calc(100%-200px)] w-screen justify-end lg:flex">
          <LeadGenerationOpenForm
            isOpenForm={true}
            pageTwoButtonText={isPastEvent ? 'Watch Now' : 'Register Now'}
            applicationPage="masterclass"
            onSubmitL2={onSubmitL2}
            onSubmitPageTwo={onSubmitOtp}
            openFormClassName={'md:w-[30vw]'}
            wrapperClassName={'lg:sticky top-24 h-[450px] z-[1000]'}
          />
        </div>
      </div>

      {/* Mobile view lead gen open form: */}
      <LeadGenerationOpenForm
        isOpenForm={true}
        pageTwoButtonText={isPastEvent ? 'Watch Now' : 'Register Now'}
        applicationPage="masterclass"
        onSubmitL2={onSubmitL2}
        onSubmitPageTwo={onSubmitOtp}
        wrapperClassName={`lg:hidden container pt-9`}
        openFormClassName={'mx-auto'}
      />
    </div>
  );
}
