import React from 'react';
import { Button } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';

function CopyButton({ textToCopy, className }) {
  function copyToClipboard() {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success('Link copied to clipboard');
      },
      (err) => {
        console.log('Error copying to clipboard: ', err);
      },
    );
  }
  return (
    <Button
      onClick={copyToClipboard}
      className={twMerge(
        `natural sm flex items-center whitespace-nowrap rounded-[4px] border-2 border-v5-yellow-200 bg-v5-yellow-200 py-[0px] px-[13px] font-manrope text-[8px] font-bold capitalize leading-[14px] text-black hover:bg-v5-yellow-100 md:py-3 md:px-[40px] md:text-[14px] lg:rounded-[10px] ${className}`,
      )}
      type="contained"
    >
      Copy
    </Button>
  );
}

export default CopyButton;
