import React from 'react';
const CTA = ({ cta, timer }) => {
  if (!cta.isVisible) {
    return null;
  }
  const textColor = cta.color || '#000000';
  return (
    <span>
      &nbsp;|&nbsp;
      <span
        className={`underline ${cta.isBold ? 'font-bold' : ''}`}
        style={{ color: textColor }}
      >
        {cta.ctaLabelText}
      </span>
      {timer?.isVisible && <span>&nbsp;|&nbsp;</span>}
    </span>
  );
};

export default CTA;
