import React from 'react';
import FspCarousel from '../FspCarousel/index';
import { useLocation } from '@reach/router';
import RawHTML from '@components/Common/RawHTML/index';
import { cn } from '@/lib/utils';

export default function AdvancedCurriculum({
  CustomAccordion,
  superDreamJobRange,
  data,
  curriculum,
  gradient,
  heading,
  description,
  externalClassName,
  tag,
  extendedAccordionClass,
  forceNoHeading,
  ...props
}) {
  const location = useLocation();

  const getTagName = () => {
    switch (tag) {
      case 'lld':
        return 'LOW LEVEL DESIGN';
      case 'hld':
        return 'HIGH LEVEL DESIGN';
      case 'dsa':
        return '<span class="md:w-full w-[100px]">DATA STRUCTURES & ALGORITHMS</span><span class="md:ml-4 w-max min-w-[80px] rounded-full bg-v5-yellow-200 p-1 px-2 text-center md:text-xs text-[10px] font-semibold uppercase text-v5-neutral-600">&nbsp;OPTIONAL</span>';
      default:
        return null;
    }
  };

  const getTagCss = () => {
    switch (tag) {
      case 'lld':
        return 'bg-v5-lime-100 text-v5-green-500';
      case 'hld':
        return 'bg-v5-lime-100 text-v5-green-500';
      case 'dsa':
        return 'bg-v5-green-400 text-v5-neutral-200 md:pb-3 md:pt-3';
      default:
        return null;
    }
  };

  return (
    <div
      className={`relative z-[999] rounded-[50px] ${
        gradient
          ? 'border-gradient-radius50 border-gradient gradient-card-dark'
          : 'bg-v5-green-400'
      } p-4 text-white sm:p-6 ${externalClassName && externalClassName}`}
    >
      {getTagName() && (
        <div
          className={cn(
            `absolute top-0 left-0 flex translate-x-[3px] items-center rounded-tl-[50px] rounded-br-[50px] p-6 py-[7px] pl-8 pr-8 text-[10px] font-bold md:translate-x-[2px] md:text-[14px]`,
            getTagCss(),
          )}
        >
          <RawHTML className="flex items-center">{getTagName()}</RawHTML>
        </div>
      )}

      {!forceNoHeading && heading ? (
        <RawHTML>{heading}</RawHTML>
      ) : (
        !forceNoHeading && (
          <h1 className="my-10 mt-14 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl">
            Additional{' '}
            <span className="text-v5-green-100">advanced curriculum</span> to
            crack <br className="hidden sm:block"></br>super-dream jobs (
            {superDreamJobRange}) <br className="hidden sm:block"></br>at
            Unicorns and tech giants
          </h1>
        )
      )}

      {description && (
        <p className="mb-5 text-center leading-7 text-v5-neutral-300">
          {description}
        </p>
      )}

      {data && (
        <div className="mb-16">
          <FspCarousel data={data} {...props}></FspCarousel>
        </div>
      )}

      <div
        className={`flex w-full flex-wrap items-stretch gap-y-4  gap-x-4 text-v5-neutral-200 ${
          forceNoHeading && 'pt-14'
        }`}
      >
        {curriculum?.map((ele, idx) => {
          return (
            <div className="w-full" key={idx} id={ele?.anchor}>
              <CustomAccordion
                extendedAccordionClass={extendedAccordionClass}
                gradient={gradient}
                data={ele}
                open={location.hash == '#' + ele?.anchor}
              ></CustomAccordion>
            </div>
          );
        })}
      </div>
    </div>
  );
}
