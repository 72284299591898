import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import LeadGenerationService from '../../../../utils/lead-generation';
import { GlobalDispatchContext } from '../../../context/GlobalContextProvider';

import toast from 'react-hot-toast';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; //root-file
import { faAddressCard, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import {
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_DEVICE_DESKTOP,
  LEAD_GEN_LOCATION_FALLBACK,
  LEAD_GEN_PATHNAME_FALLBACK,
  leadStage,
  generateDropDownValues,
  profiles,
  whitelistedLeadSources,
  ERROR_LEAD_SOURCE,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
  LEAD_GEN_PROGRAM_ID_QA,
  getGraduationYearList,
  jobDomains,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
} from '../../../constants/LeadGenerationConstants/index';
import { useLocation } from '@reach/router';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import useResizer from '@components/extra/useResizer';

import FormDropDown from '@components/LeadGenerationForm/FormComponents/FormDropDown';
import FormInput from '@components/LeadGenerationForm/FormComponents/FormInput';
import FormSubmitButton from '@components/LeadGenerationForm/FormComponents/FormSubmitButton';
import FormPhoneInput from '@components/LeadGenerationForm/FormComponents/FormPhoneInput';
import { GTM } from '@src/analytics/gtm';

// Regex for Indian phone numbers; TODO: create a seperate regex array for all-country validation
const indianRegex = new RegExp('^[6-9][0-9]{9}$');

// Yup schema to validate page-1 form fields
const pageOneSchema = yup.object({
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is a required field'),
  phone: yup
    .string()
    .required('Phone is a required field')
    .test('Check Indian Number', function () {
      let contact = this.parent['phone'];

      if (contact && contact.startsWith('91')) {
        let contactWithoutCountryCode = contact.substring(2, contact.length);
        return indianRegex.test(contactWithoutCountryCode);
      }

      return contact.length >= 8; // temporary logic to validate at-least 8 digit phone number (8 digits include country code)
    }),
});

const pageOneExtendedSchema = pageOneSchema.shape({
  whatsAppUpdates: yup.boolean().required(),
});

const pageOneExtraFieldsExtendedSchema = pageOneSchema.shape({
  graduationYear: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be a positive number')
    .integer('Must be an integer')
    .required('Graduation Year is a required field'),
  currentlyWorking: yup.string().required('Profile is a required field'),
});

export default function EventFormBody({
  buttonLocation,
  isOnePageForm,
  closeDialog,
  isOpenForm,
  pageOneGradYear,
  pageOneWorkingProfessional,
  programType,
}) {
  // State variables
  const globalDispatch = useContext(GlobalDispatchContext);

  const [loading, setLoading] = useState(false);
  const isMobile = useResizer();

  // Global state
  const globalState = useContext(GlobalStateContext);
  const userEventSlugsAppliedFor = globalState?.userEventSlugsAppliedFor || [];
  const storedParams = globalState.utm;
  const userLocation = globalState.userLocation;
  const searchParam = new URLSearchParams(storedParams);

  // Path variables
  const location = useLocation();
  const pathName = location?.pathname;

  // Check if the current lead if of type QA
  const isMQALead =
    pathName === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathName === LEAD_GEN_AUTOMATION_EDGE_PATH ||
    programType === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2Lead =
    pathName === '/software-development-fellowship-program-plus/';

  // UTM variables
  const utmSource = searchParam.get('utm_source');
  const utmMedium = searchParam.get('utm_medium');
  const utmPublisher = searchParam.get('utm_publisher');

  const utmCampaign = searchParam.get('utm_campaign');
  const utmTerm = searchParam.get('utm_term');
  const utmContent = searchParam.get('utm_content');
  const gclID = searchParam.get('gclid');
  const lcID = searchParam.get('li_fat_id');
  const fbclID = searchParam.get('fbclid');

  // Form Schema and watch variables
  const hookForm = useForm({
    defaultValues: {
      email: '',
      phone: '',
      ...(!isOnePageForm && { whatsAppUpdates: true }),
      ...(isOnePageForm &&
        pageOneGradYear &&
        pageOneWorkingProfessional && {
          graduationYear: 2022,
          currentlyWorking: '',
        }),
    },
    resolver:
      isOnePageForm && pageOneGradYear && pageOneWorkingProfessional
        ? yupResolver(pageOneExtraFieldsExtendedSchema)
        : isOnePageForm
        ? yupResolver(pageOneSchema)
        : yupResolver(pageOneExtendedSchema),
    mode: 'all',
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      data.program = isSde2Lead
        ? 'Software Development Plus'
        : isMQALead
        ? 'QA Automation'
        : 'Software Development';

      data.phone = '+'.concat(data.phone);
      data.email = data.email.toLowerCase();
      data.formURL = pathName || LEAD_GEN_PATHNAME_FALLBACK;
      data.location = buttonLocation || LEAD_GEN_LOCATION_FALLBACK;
      data.deviceType = isMobile
        ? LEAD_GEN_DEVICE_MOBILE
        : LEAD_GEN_DEVICE_DESKTOP;
      data.applicationStage = leadStage.OTHER_LEAD;
      data.dataSource = LEAD_GEN_DATA_SOURCE;

      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          data.utmSource = utmSource;
        else data.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) data.utmMedium = utmMedium;
      if (utmPublisher) data.utmPublisher = utmPublisher;
      if (utmCampaign) data.utmCampaign = utmCampaign;
      if (utmContent) data.utmContent = utmContent;
      if (utmTerm) data.utmTerm = utmTerm;
      if (gclID) data.gclID = gclID;
      if (lcID) data.lcID = lcID;
      if (fbclID) data.fbclID = fbclID;

      // Add user location data
      extendLeadObjectWithUserLocationData(data, userLocation);

      //GTM Event for Email Capture
      if (!globalState.isEmailEventSubmitted) {
        GTM.emailSubmission(data.email, data.phone);

        globalDispatch({
          type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
          payload: true,
        });
      }

      const lead = await LeadGenerationService.generateLead(data);
      const leadID = lead.data.leadID;

      // Set email, phone & docID details in global context
      globalDispatch({
        type: 'SET_EMAIL',
        payload: data.email,
      });
      globalDispatch({
        type: 'SET_PHONE',
        payload: data.phone,
      });
      globalDispatch({
        type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
        payload: leadID,
      });

      globalDispatch({
        type: 'SET_USER_EVENT_SLUGS_APPLIED_FOR',
        payload: [...userEventSlugsAppliedFor, pathName],
      });

      setLoading(false);
      if (closeDialog) closeDialog();
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-1',
      });
    }
  };

  // Wrapper function for click handler based on form type
  const submitPageOne = async (data) => {
    // if (onSubmitPageOne) await onSubmitLeadPageOne(data);
    // else await onSubmitLeadGenFormPageOne(data);

    await onSubmit(data);
  };

  const currentlyWorkingWatcher = hookForm.watch('currentlyWorking');

  const isCurrentlyWorking = currentlyWorkingWatcher === 'Yes';

  return (
    <FormProvider {...hookForm}>
      <form
        autoComplete="off"
        className={`flex flex-col gap-4`}
        onSubmit={hookForm.handleSubmit(submitPageOne)}
      >
        <FormInput
          icon={<FontAwesomeIcon icon={faAddressCard} />}
          name="name"
          label="Name"
          isOpenForm={isOpenForm}
        />

        <FormInput
          icon={<FontAwesomeIcon icon={faEnvelope} />}
          label="Email"
          name="email"
          error={hookForm.formState.errors?.email?.message}
          isOpenForm={isOpenForm}
        />
        <FormPhoneInput
          error={hookForm.formState.errors?.phone?.message}
          validation={
            hookForm.formState.dirtyFields?.phone &&
            !hookForm.formState.errors?.phone
          }
          isOpenForm={isOpenForm}
        />
        <FormDropDown
          icon={<FontAwesomeIcon icon="fa-solid fa-graduation-cap" />}
          name="graduationYear"
          label="Graduation Year"
          menu_items={getGraduationYearList()}
          isOpenForm={isOpenForm}
        />
        <FormDropDown
          icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
          label="Are you working?"
          menu_items={generateDropDownValues(profiles)}
          name="currentlyWorking"
          isOpenForm={isOpenForm}
        />
        {isCurrentlyWorking && (
          <FormDropDown
            icon={<FontAwesomeIcon icon={faBriefcase} />}
            menu_items={generateDropDownValues(jobDomains)}
            name="jobDomain"
            label="Job Domain"
            isOpenForm={isOpenForm}
          />
        )}
        <FormSubmitButton
          text={'Confirm Your Seat'}
          loading={loading}
          className={'mt-3 w-full'}
        />
      </form>
    </FormProvider>
  );
}
