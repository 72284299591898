import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';
import Layout from '../Layout/index';

export default function SectionLayout({
  heading,
  description,
  children,
  preContent,
  postContent,
  removeDescription,
  dark,
  headingClassName,
  descriptionClassName,
  ...props
}) {
  return (
    <Layout {...props}>
      <div className="container relative z-[999] text-center">
        {heading && (
          <h2
            className={`z-[300] mb-5 font-manrope font-extrabold capitalize leading-snug md:px-32 ${
              dark ? 'text-v5-neutral-200' : ''
            }`}
          >
            <RawHTML className={headingClassName}>{heading}</RawHTML>
          </h2>
        )}

        {!removeDescription &&
          (description ? (
            <p
              className={`mb-10 leading-loose sm:px-44 ${
                dark ? 'text-v5-neutral-100' : 'text-v5-neutral-400 '
              } font-light`}
            >
              <RawHTML className={descriptionClassName}>{description}</RawHTML>
            </p>
          ) : (
            <div className="mb-10"></div>
          ))}
      </div>
      {preContent}
      {children}
      {postContent}
    </Layout>
  );
}
