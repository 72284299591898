import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import CustomAccordion from './CustomAccordion';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

export default function CurriculumDetails({ curriculumData, type }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const handleAccordionInView = () => {
    GTM.track(gtmEvents.SCROLL_TO_CURRICULUM_SECTION_V2, {
      type: type,
    });
  };

  useEffect(() => {
    if (inView) {
      handleAccordionInView();
    }
  }, [inView]);

  return (
    <>
      {curriculumData?.map((ele, idx) => {
        return (
          <div
            className="w-full"
            key={idx}
            id={ele?.anchor}
            ref={idx === 0 ? ref : null}
          >
            <CustomAccordion
              ref={idx === 0 ? ref : null}
              data={ele}
              currentAccordionIndex={idx}
            ></CustomAccordion>
          </div>
        );
      })}
    </>
  );
}
