// extracted by mini-css-extract-plugin
export var arrow = "phonepe-module--arrow--fe734";
export var block = "phonepe-module--block--6fb64";
export var blockTitle = "phonepe-module--blockTitle--e7432";
export var caption = "phonepe-module--caption--bf84f";
export var divider = "phonepe-module--divider--f6bcc";
export var floatingBar = "phonepe-module--floatingBar--ff9cf";
export var floatingBarContent = "phonepe-module--floatingBarContent--96dbe";
export var floatingBarText = "phonepe-module--floatingBarText--124c5";
export var footer = "phonepe-module--footer--3a571";
export var hero = "phonepe-module--hero--bb569";
export var heroTitle = "phonepe-module--heroTitle--5f910";
export var highlights = "phonepe-module--highlights--ddcdb";
export var joinDetails = "phonepe-module--joinDetails--6634b";
export var preFooter = "phonepe-module--preFooter--0dfe8";
export var schedule = "phonepe-module--schedule--49cb9";
export var scheduleContent = "phonepe-module--scheduleContent--c0616";
export var scheduleContentCaption = "phonepe-module--scheduleContentCaption--06dd6";
export var scheduleContentTitle = "phonepe-module--scheduleContentTitle--8be7a";
export var subCaption = "phonepe-module--subCaption--8d346";