// extracted by mini-css-extract-plugin
export var accordion = "TrialDeveloperExperience-module--accordion--41d5e";
export var banner = "TrialDeveloperExperience-module--banner--f2b75";
export var bannerMobile = "TrialDeveloperExperience-module--bannerMobile--647b0";
export var build = "TrialDeveloperExperience-module--build--122b0";
export var button = "TrialDeveloperExperience-module--button--18e69";
export var card = "TrialDeveloperExperience-module--card--3f2df";
export var circle = "TrialDeveloperExperience-module--circle--c48d5";
export var custom = "TrialDeveloperExperience-module--custom--280df";
export var customFont = "TrialDeveloperExperience-module--customFont--69477";
export var detail = "TrialDeveloperExperience-module--detail--8409a";
export var emailForm = "TrialDeveloperExperience-module--emailForm--41a3f";
export var heading = "TrialDeveloperExperience-module--heading--9a0af";
export var imageBadge = "TrialDeveloperExperience-module--imageBadge--02902";
export var inputControl = "TrialDeveloperExperience-module--inputControl--62f1f";
export var inputControlMobile = "TrialDeveloperExperience-module--inputControlMobile--12a5a";
export var learn = "TrialDeveloperExperience-module--learn--f9368";
export var milestoneCard = "TrialDeveloperExperience-module--milestoneCard--f5a4f";
export var parent = "TrialDeveloperExperience-module--parent--01f50";
export var paymentImage = "TrialDeveloperExperience-module--paymentImage--9dbdb";
export var play = "TrialDeveloperExperience-module--play--e4141";
export var sketch = "TrialDeveloperExperience-module--sketch--f48f7";
export var sketchParent = "TrialDeveloperExperience-module--sketchParent--1fb95";
export var skill = "TrialDeveloperExperience-module--skill--e75ac";
export var testimonialCard = "TrialDeveloperExperience-module--testimonialCard--089b3";
export var testimonialOwner = "TrialDeveloperExperience-module--testimonialOwner--fd188";
export var whyCrioImage = "TrialDeveloperExperience-module--whyCrioImage--b34c7";