// extracted by mini-css-extract-plugin
export var card = "WinterOfDoingDetailPage-module--card--668c7";
export var companyDescription = "WinterOfDoingDetailPage-module--companyDescription--112c8";
export var companyImage = "WinterOfDoingDetailPage-module--companyImage--b1c34";
export var companyLeft = "WinterOfDoingDetailPage-module--companyLeft--5b5ed";
export var detail = "WinterOfDoingDetailPage-module--detail--2522c";
export var header = "WinterOfDoingDetailPage-module--header--7ef4e";
export var learnMore = "WinterOfDoingDetailPage-module--learnMore--8b8df";
export var navMobile = "WinterOfDoingDetailPage-module--navMobile--dab11";
export var parent = "WinterOfDoingDetailPage-module--parent--ea8f5";
export var projectCard = "WinterOfDoingDetailPage-module--projectCard--2c7e4";
export var startup = "WinterOfDoingDetailPage-module--startup--f22fb";