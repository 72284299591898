import { getImage } from 'gatsby-plugin-image';

export const getCurrentMasterclassEventDetail = (
  masterClassEvents,
  pathName,
) => {
  return masterClassEvents.find((event) =>
    pathName.includes(event?.attributes?.eventSlug),
  )?.attributes;
};

export const filterImagesByEventSlug = (images, eventSlug) => {
  return images.filter((image) => image.relativePath.includes(eventSlug));
};

export const filterMasterclassImages = (images) => {
  return images.filter((image) =>
    image.relativePath.includes('masterclass-details'),
  );
};

export const findBannerImage = (images, isMobile) => {
  const searchTerm = isMobile ? 'banner_image_mobile' : 'banner_image';

  // Sort images by name to prioritize 'banner_image_mobile' over 'banner_image'
  const sortedImages = images.sort((a, b) => a.name.localeCompare(b.name));

  return sortedImages.find((image) => {
    const imageName = image.name.toLowerCase();
    return (
      imageName.includes(searchTerm.toLowerCase()) &&
      !imageName.includes('large')
    );
  });
};

export const findSessionHostImage = (images) => {
  return images.find((image) =>
    image.name.toLowerCase().includes('session_host'),
  );
};

export const findEventCardSessionImage = (images) => {
  return images.find((image) =>
    image.name.toLowerCase().includes('event_card'),
  );
};

export const findAboutCrioImg = (images) => {
  return images.find(
    (image) =>
      image.name.toLowerCase().includes('about_crio') &&
      !image.name.includes('large'),
  );
};

export const formatDate = (date) => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);

  // Replace slashes with dashes
  return formattedDate.replace(/\//g, '-');
};

export const formatTime = (date) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const getDynamicSeatCount = (seatCount, createdAt, sessionStartDateTime) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);

  // Calculate the total duration from createdAt to sessionStartDateTime
  const totalDurationMs = sessionStartDate - creationDate; // Total time from creation to session start
  const totalDurationDays = totalDurationMs / (1000 * 60 * 60 * 24); // Convert to days

  // Dynamic thresholds
  const thresholds = [
    { percentage: 20, seatFactor: 0.4, targetDays: totalDurationDays * 0.2 },
    { percentage: 60, seatFactor: 0.8, targetDays: totalDurationDays * 0.6 },
    { percentage: 100, seatFactor: 1.0, targetDays: totalDurationDays },
  ];

  const today = new Date();

  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    if (today < targetDate) {
      // Gradual seats for current threshold
      const daysPassed = Math.floor(
        (today - creationDate) / (1000 * 60 * 60 * 24),
      );
      const dailyIncrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );
      const gradualSeats = Math.min(
        sessionSeats * threshold.seatFactor,
        dailyIncrement * daysPassed,
      );

      return Math.floor(gradualSeats);
    }
  }

  return sessionSeats; // If past the final threshold
};

const getDynamicSeatCountDecrease = (
  seatCount,
  createdAt,
  sessionStartDateTime,
) => {
  const sessionSeats = seatCount;
  const creationDate = new Date(createdAt);
  const sessionStartDate = new Date(sessionStartDateTime);

  // Calculate the total duration from createdAt to sessionStartDateTime
  const totalDurationMs = sessionStartDate - creationDate; // Total time from creation to session start
  const totalDurationDays = totalDurationMs / (1000 * 60 * 60 * 24); // Convert to days

  // Dynamic thresholds
  const thresholds = [
    { percentage: 20, seatFactor: 0.4, targetDays: totalDurationDays * 0.2 },
    { percentage: 60, seatFactor: 0.8, targetDays: totalDurationDays * 0.6 },
    { percentage: 100, seatFactor: 1.0, targetDays: totalDurationDays },
  ];

  const today = new Date();

  for (let i = 0; i < thresholds.length; i++) {
    const threshold = thresholds[i];
    const targetDate = new Date(creationDate);
    targetDate.setDate(targetDate.getDate() + Math.floor(threshold.targetDays));

    if (today < targetDate) {
      // Gradual seat reduction for the current threshold
      const daysPassed = Math.floor(
        (today - creationDate) / (1000 * 60 * 60 * 24),
      );
      const dailyDecrement = Math.floor(
        (sessionSeats * threshold.seatFactor) / threshold.targetDays,
      );
      const gradualSeats = Math.max(
        sessionSeats - dailyDecrement * daysPassed,
        sessionSeats * (1 - threshold.seatFactor),
      );

      return Math.ceil(gradualSeats);
    }
  }

  return 0; // If past the final threshold, all seats are reduced
};

export const getSessionDetails = (eventDetails) => {
  const sessionStartDateTime =
    eventDetails?.sessionDetails?.sessionStartDateTime;
  const sessionDate = sessionStartDateTime
    ? formatDate(new Date(sessionStartDateTime))
    : null;
  const sessionTime = sessionStartDateTime
    ? formatTime(new Date(sessionStartDateTime))
    : null;
  const createdAt = eventDetails?.createdAt;

  const isSeatTypeHyped =
    eventDetails?.sessionDetails?.sessionSeatType === 'Registered';
  return {
    sessionName: eventDetails?.sessionDetails?.sessionName,
    sessionDate,
    sessionTime,
    createdAt,
    sessionStartDateTime,
    sessionHostName: eventDetails?.sessionDetails?.sessionHostName,
    sessionHostDesignation:
      eventDetails?.sessionDetails?.sessionHostDesignation,
    isSeatTypeHyped,
    isPastEvent: eventDetails?.everWebinarDetails?.isPastEvent,
    sessionSlug: eventDetails?.eventSlug,
    sessionProgram: eventDetails?.program,
    sessionSeats: isSeatTypeHyped
      ? getDynamicSeatCount(
          eventDetails?.sessionDetails?.sessionSeats,
          eventDetails?.createdAt,
          eventDetails?.sessionDetails?.sessionStartDateTime,
        )
      : getDynamicSeatCountDecrease(
          eventDetails?.sessionDetails?.sessionSeats,
          eventDetails?.createdAt,
          eventDetails?.sessionDetails?.sessionStartDateTime,
        ),
  };
};

export const getShareInfo = (eventDetails) => {
  return eventDetails?.shareInformation;
};

export const getIsPastEvent = (sessionDate) => {
  const eventDateTime = new Date(sessionDate);
  const currentDateTime = new Date();
  return eventDateTime < currentDateTime;
};

export const getSessionCardImg = (allMasterclassImg, eventSlug) => {
  return getImage(
    allMasterclassImg.find(
      (node) =>
        node.relativePath.includes(eventSlug) &&
        node.name.toLowerCase().includes('event_card') &&
        !node.name.includes('large'),
    ),
  );
};

export const sanitizeEventDetails = (eventDetails, allMasterclassImg) => {
  const {
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionStartDateTime,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
    isPastEvent,
    createdAt,
    sessionProgram,
    sessionSlug,
  } = getSessionDetails(eventDetails);
  const shareInfo = getShareInfo(eventDetails);
  const sessionCardImg = getSessionCardImg(
    allMasterclassImg,
    eventDetails?.eventSlug,
  );

  return {
    sessionName,
    sessionDate,
    sessionTime,
    sessionHostName,
    sessionSlug,
    sessionStartDateTime,
    sessionHostDesignation,
    isSeatTypeHyped,
    sessionSeats,
    createdAt,
    shareInfo,
    sessionProgram,
    isPastEvent,
    sessionCardImg,
  };
};

export const getClipedHref = (href) => {
  return href.length > 30 ? `${href.slice(0, 30)}...` : href;
};
