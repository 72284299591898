// import useResizer from '@components/extra/useResizer';
import React from 'react';
// import { useState } from 'react';
import { CustomAccordion } from '../FspCurriculumSection/index';

const JobGuaranteeCard = ({ data }) => {
  return (
    <>
      <section
        className="z-[999] flex justify-center py-10 sm:py-12"
        id="job-guarantee"
        style={{ scrollMarginTop: '100px' }}
      >
        <div
          className={`container relative z-[999] mx-4 max-w-[850px] rounded-[30px] bg-v5-green-500 py-8 px-4 text-white md:py-12 md:px-8`}
        >
          <h2 className="mb-5 text-center font-manrope text-[28px] font-black text-neutral-200 md:mb-10 md:text-4xl">
            Crio’s Assured Referrals Explained
          </h2>
          <p className="mb-4 text-center text-sm leading-6 text-v5-neutral-300 md:mb-8 md:text-base md:leading-8">
            Crio is a partner in our learners’ career transformation and as part
            of our offering, our learners are eligible for assured referrals and
            job assistance based on their profile.
          </p>
          <div
            className={`flex w-full flex-wrap items-stretch justify-center gap-y-4 gap-x-4 text-v5-neutral-200`}
          >
            {data?.map((ele, idx) => {
              return (
                <div
                  className="w-full max-w-[800px]"
                  key={idx}
                  id={ele?.anchor}
                >
                  <CustomAccordion data={ele} variant={'FAQ'}></CustomAccordion>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default JobGuaranteeCard;
