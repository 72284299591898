import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';
import { useContext } from 'react';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';

export const clickEventEntityType = {
  BUTTON: 'BUTTON',
  TEXT_FIELD: 'TEXT_FIELD',
  ANCHOR_TAG: 'ANCHOR_TAG',
};

export const useWebEngage = () => {
  const location = useLocation();
  const state = useContext(GlobalStateContext);
  const email = state?.email;

  /**
   * Wrapper function for all web-engage click events
   * @function
   * @param {string} entityName - Name of the entity
   * @param {object} payload - Custom data keys to be sent in click event payload
   * @param {keyof clickEventEntityType} entityType - Custom data keys to be sent
   */
  const captureUserClickEvent = (
    entityName,
    payload = {},
    entityType = clickEventEntityType.BUTTON,
  ) => {
    const clickEventPayload = {
      event_entityName: entityName,
      event_entityType: entityType,
      event_pageUrlFull: location.href,
      event_pathName: location.pathname,
      ...payload,
    };

    if (email) clickEventPayload.event_userEmail = email;

    GTM.track(gtmEvents.USER_CLICK_EVENT, {
      clickEventPayload,
    });
  };

  return {
    captureUserClickEvent,
  };
};
