import React from 'react';

const Timer = ({ timer }) => {
  if (!timer?.isVisible) {
    return null;
  }

  return (
    <img
      src={timer.timerLink}
      alt="motionmailapp.com"
      className="w-[150px] md:w-[185px]"
    />
  );
};

export default Timer;
