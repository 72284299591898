import React from 'react';

const HamburgerIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="3.05176"
        width="3.05128"
        height="17"
        rx="1"
        transform="rotate(-90 0 3.05176)"
        fill="black"
      />
      <rect
        y="10.0254"
        width="3.05128"
        height="17"
        rx="1"
        transform="rotate(-90 0 10.0254)"
        fill="black"
      />
      <rect
        y="17"
        width="3.05128"
        height="17"
        rx="1"
        transform="rotate(-90 0 17)"
        fill="black"
      />
    </svg>
  );
};

export default HamburgerIcon;
