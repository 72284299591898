import React from 'react';
import SeatCount from './SeatCount'; // Importing the SeatCount component
import DefaultStrip from './DefaultStrip';
import StripContent from './StripContent';
import CTA from './CTA';
import Timer from './Timer';
const ReusableTopStrip = ({ isStrip, stripData, isActiveStrip }) => {
  if (!isStrip || new Date() > new Date(stripData?.endDateAndTime)) {
    return <DefaultStrip />;
  }

  const {
    TopStripBackgroundStrip,
    TopStripContent,
    TopStripCTA,
    TopStripSeatsCount,
    TopStripTimer,
    startDateAndTime,
    endDateAndTime,
  } = stripData;

  const SeatsCountComponent = ({ seatsCount }) => {
    if (!seatsCount.isVisible) {
      return null;
    }

    const textColor = seatsCount.color || 'white';
    return (
      <SeatCount
        startDateAndTime={startDateAndTime}
        endDateAndTime={endDateAndTime}
        seats={seatsCount.seats}
        isDynamic={seatsCount.isDynamic}
        isBold={seatsCount.isBold}
        color={seatsCount.color}
      />
    );
  };

  // Check if the current time is before startDateAndTime or after endDateAndTime
  const currentTime = new Date();
  const startTime = new Date(startDateAndTime);
  const endTime = new Date(endDateAndTime);

  if (!isActiveStrip || currentTime < startTime || currentTime > endTime) {
    return <DefaultStrip />;
  }

  const stripBackground =
    TopStripBackgroundStrip?.isChangedColor && TopStripBackgroundStrip?.color
      ? TopStripBackgroundStrip.color
      : '#2DF8C5';

  return (
    <div
      style={{
        background: stripBackground,
        color: TopStripContent?.stripContentColor
          ? TopStripContent?.stripContentColor
          : '#000000',
      }}
      className="text-[12px flex min-h-[44px] w-screen cursor-pointer items-center justify-between  font-rubik  md:items-center md:justify-between md:gap-6 md:px-0 md:py-0 md:text-center md:text-[18px]"
    >
      <div className="flex w-full flex-col items-center justify-center gap-[6px] py-[9px] px-5 text-center md:flex-row md:gap-[18px]">
        <div className="flex flex-wrap items-center justify-center text-xs font-normal md:text-lg">
          <StripContent
            content={TopStripContent.stripContent}
            stripContentColor={TopStripContent.stripContentColor}
          />
          {TopStripSeatsCount && (
            <SeatsCountComponent seatsCount={TopStripSeatsCount} />
          )}
          {TopStripCTA?.isVisible && (
            <CTA cta={TopStripCTA} timer={TopStripTimer} />
          )}
        </div>
        {TopStripTimer && <Timer timer={TopStripTimer} />}
      </div>
    </div>
  );
};

export default ReusableTopStrip;
