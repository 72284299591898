import { cn } from '@/lib/utils';
import RawHTML from '@components/Common/RawHTML';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import Layout from '@components/v5/layouts/Layout';
import {
  landingPageDefaultSubtitle,
  landingPageDefaultTitle,
} from '@src/constants/masterclass';
import useHomePageMetadata from '@src/hooks/Masterclass/useHomePageMetadata';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function HomePageHero() {
  const {
    getHeroSectionImg,
    getHeroSectionContent,
    isLeadGenFormEnabledFromStrapi,
    onSubmitOtp,
  } = useHomePageMetadata();
  const isLeadGenEnabled = isLeadGenFormEnabledFromStrapi();

  const heroSectionImg = getHeroSectionImg();
  const { title, subTitle } = getHeroSectionContent();
  return (
    <Layout className={`relative w-screen pt-14`}>
      {/* Hero Section Background */}
      <GatsbyImage
        image={heroSectionImg}
        className={cn(
          'h-[170px] object-cover',
          isLeadGenEnabled ? 'md:h-[600px]' : 'md:h-[500px]',
        )}
      />

      {/* Container for Hero Section Content */}
      <div className={cn(isLeadGenEnabled ? 'container' : '')}>
        <div
          className={cn(
            'absolute h-full items-center gap-x-20',
            isLeadGenEnabled
              ? 'top-3 left-0 flex w-full justify-center text-center md:left-auto md:top-2 md:grid md:w-auto md:grid-cols-2 md:justify-start md:text-left'
              : 'top-3 mx-auto flex w-full justify-center text-center',
          )}
        >
          {/* Hero Section Title and subtitle */}
          <div className="mt-10 flex max-w-[280px] flex-col gap-y-4 md:mt-0 md:max-w-[600px] md:gap-y-8">
            <RawHTML className="font-manrope text-[20px] font-bold leading-[24px] md:text-[50px] md:leading-[60px]">
              {title ?? landingPageDefaultTitle}
            </RawHTML>
            <RawHTML className="font-rubik text-[8px] leading-[14px] md:text-[24px] md:leading-[36px]">
              {subTitle ?? landingPageDefaultSubtitle}
            </RawHTML>
          </div>

          {/* Lead Gen Form */}
          {isLeadGenEnabled ? (
            <LeadGenerationOpenForm
              isOpenForm={true}
              pageTwoButtonText="Sign Up"
              applicationPage="masterclass"
              onSubmitPageTwo={onSubmitOtp}
              hasProgramInterestedField={true}
              hideLastName={true}
              wrapperClassName={`md:block hidden`}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
