// extracted by mini-css-extract-plugin
export var circleArrow = "Registered-module--circleArrow--f84ec";
export var circleImage = "Registered-module--circleImage--7b0ef";
export var customFont = "Registered-module--customFont--46bac";
export var heading = "Registered-module--heading--284ab";
export var list = "Registered-module--list--78672";
export var listBreak = "Registered-module--listBreak--6c06f";
export var pill = "Registered-module--pill--b2c82";
export var sketch = "Registered-module--sketch--9b5e1";
export var sketchParent = "Registered-module--sketchParent--9881b";
export var skills = "Registered-module--skills--dd739";
export var testimonialCard = "Registered-module--testimonialCard--6ae11";
export var testimonialParent = "Registered-module--testimonialParent--f75eb";
export var userImage = "Registered-module--userImage--b2ca6";
export var whyCrioImage = "Registered-module--whyCrioImage--bc02b";