import { navigate } from 'gatsby'; //import navigate from gatsby
import React, { useEffect } from 'react';

export default function RedirectToRegionalSite(props) {
  useEffect(() => {
    navigate(props.url);
  }, []);
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <p className="font-light text-gray-600">
        Redirecting to regional site...
      </p>
    </div>
  );
}
