const downloadableProjects = [
  'ME_PROJECT_CHATBOT_PYTHON',
  'ME_PROJECT_AMAZON_CLONE_REACT',
  'ME_PROJECT_CRIC_ALERTS',
  'ME_PROJECT_YOUTUBE_TRANSCRIPT',
  'ME_PROJECT_LIBRARY_MANAGEMENT',
  'ME_PROJECT_NOTE_PASS',
  'ME_PROJECT_STOCKS_DASH',
  'ME_PROJECT_BREAKOUT_JAVA',
  'ME_PROJECT_STUDENT_RESULT',
  'ME_PROJECT_EDITOR_REACT',
  'ME_PROJECT_CONTACT_APP',
  'ME_PROJECT_CRM_SPRING',
  'ME_PROJECT_INTAL_C',
  'ME_PROJECT_SORTING_VISUALISER',
  'ME_PROJECT_CONNECT4_PYTHON',
  'ME_PROJECT_COMPANION_APP',
  'ME_PROJECT_INSTAGRAM_AUTO',
  'ME_PROJECT_QUIZUP',
  'ME_PROJECT_CODECHEF_NOTIFIER',
  'ME_PROJECT_WEATHER_APP',
  'ME_PROJECT_SOCIALMEDIA_NODEJS',
  'ME_PROJECT_RESUME_BUILDER',
  'ME_PROJECT_GEODATA_ANALYSIS',
  'ME_PROJECT_WHATSAPP_CLONE',
  'ME_PROJECT_WHATSAPP_EMAIL_ALERTS',
  'ME_PROJECT_CLI_CHAT',
  'ME_PROJECT_BOSTON',
];

export default downloadableProjects;
