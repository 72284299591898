// import Button from '@components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { navigate } from 'gatsby'; //import navigate from gatsby
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import * as styles from './ApplyNowCTA.module.scss';
import useResizer from '@components/extra/useResizer';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';

export default function ApplyNowCTA(props) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const ref = useRef();
  const isMobile = useResizer();
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   if (!isVisible) {
  //     dispatch({
  //       type: 'USER_IS_APPLYING',
  //       payload: false,
  //     });
  //   } else {
  //     if (isOpen && isMobile) {
  //       dispatch({
  //         type: 'USER_IS_APPLYING',
  //         payload: true,
  //       });
  //     } else {
  //       dispatch({
  //         type: 'USER_IS_APPLYING',
  //         payload: false,
  //       });
  //     }
  //   }
  // }, [isVisible]);

  useEffect(() => {
    if (isOpen) {
      // ref?.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (props?.open === true || props?.open === false) {
      setIsOpen(props.open);
    }
  }, [props?.open]);

  if (props.noEmail) {
    return (
      <div className=" flex justify-center">
        <>
          <Button
            className={`md shimmer-btn  flex items-center bg-crio-yellow-500 text-center font-bold capitalize text-crio-green-900 ${props.className} ${styles.applyButtonDefault} `}
            type="contained"
            onClick={(e) => {
              let hash = btoa(props.type + props.link);

              if (
                state.registrationDetails &&
                state.registrationDetails[hash] &&
                !props.allowMultipleFill
              ) {
                navigate(
                  `/registered/?id=${state.registrationDetails[hash].id}`,
                );
              } else {
                if (props.link) {
                  navigate('/registration', {
                    state: {
                      link: props.link,
                      type: props.type,
                      location: props.location,
                      program: props.program,
                    },
                  });
                }
              }
            }}
            style={{
              minWidth: props.width ? props.width : '200px',
            }}
          >
            {props.text ? props.text : 'Apply Now'}
            <FontAwesomeIcon
              icon={['fas', 'arrow-up']}
              className="ml-2 rotate-45"
              style={{ fontSize: '17px' }}
            ></FontAwesomeIcon>
          </Button>
        </>
      </div>
    );
  }

  return (
    <div className=" flex justify-center" ref={ref}>
      {isOpen ? (
        <div className="flex items-center">
          <form
            className="mt-2 flex h-[50px]  items-center justify-center text-black md:flex-nowrap "
            id="my-form"
            onSubmit={(e) => {
              let email = e.target.email.value;
              e.preventDefault();

              dispatch({
                type: 'SET_EMAIL',
                payload: email,
              });
              GTM.setEmail(email);

              GTM.login({
                userId: email,
                email: email,
              });

              if (props.overrideSubmit) {
                props.overrideSubmit(email);
                if (props.link) {
                  navigate('/registration', {
                    state: {
                      link: props.link,
                      type: props.type,
                      location: props.location,
                      program: props.program,
                    },
                  });
                } else {
                  setIsOpen(false);
                }
                return;
              }
              //GTM Event for Email Capture
              if (!state.isEmailEventSubmitted) {
                GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                  type: props.type,
                  location: props.location,
                });
                dispatch({
                  type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
                  payload: true,
                });
              }
              if (process.env.GATSBY_ENV == 'production') {
                fetch(
                  `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${email
                    .trim()
                    .toLowerCase()}&country=${state.country}&type=${
                    props.type
                  }&location=${props.location}&utm=${encodeURIComponent(
                    state.utm,
                  )}&adblocker=${
                    typeof window.webengage == 'undefined' ? true : false
                  }&url=${window.location.href}`,
                );
              }

              if (props.onSubmit) {
                props.onSubmit(email);
              }
              if (props.link) {
                navigate('/registration', {
                  state: {
                    link: props.link,
                    type: props.type,
                    location: props.location,
                    program: props.program,
                  },
                });
              } else {
                setIsOpen(false);
              }
            }}
          >
            <FormControl style={{ height: '50px' }}>
              <InputBase
                sx={{ p: 1 }}
                inputProps={{ 'aria-label': 'Apply to Crio' }}
                color="yellow"
                size="normal"
                className="h-full flex-1 rounded-l-lg rounded-r-none border border-gray-300 bg-white  text-black"
                ref={ref}
                id="filled-basic"
                // autoFocus
                placeholder="Email"
                type="email"
                name="email"
                required
              />
            </FormControl>

            <Button
              size="small"
              type="contained"
              className={`subtitle1 h-full whitespace-nowrap  rounded-l-none rounded-r-lg px-2 text-sm font-bold capitalize text-black ${props.applyNowInsideClassName}`}
              style={{
                background:
                  'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
              }}
            >
              {props.innerText || 'Apply Now'}
            </Button>
          </form>

          <div
            className="cursor-pointer"
            onClick={(e) => {
              dispatch({
                type: 'USER_IS_APPLYING',
                payload: false,
              });
              if (props.isClosed) props.isClosed();
              if (props?.onClose) {
                props?.onClose();
                return;
              }
              setIsOpen(false);

              dispatch({
                type: 'USER_IS_APPLYING',
                payload: false,
              });
              let event = props.closeEvent;
              GTM.track(gtmEvents[event], {
                type: props.type,
                meId: props.meId,
                location: props.location,
              });
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'times']}
              className="ml-2 text-sm text-gray-400"
            ></FontAwesomeIcon>
          </div>
        </div>
      ) : (
        <>
          <Button
            type="contained"
            className={`  ${props.className}  md shimmer-btn  flex items-center bg-gradient-to-r from-crio-yellow-500 to-crio-yellow-700 text-center font-bold capitalize text-crio-green-900`}
            onClick={(e) => {
              let event = props.openEvent;
              GTM.track(gtmEvents[event], {
                type: props.type,
                meId: props.meId,
                location: props.location,
              });

              setTimeout(() => {
                dispatch({
                  type: 'USER_IS_APPLYING',
                  payload: true,
                });
              }, 500);
              if (state.email.length > 0) {
                GTM.login({
                  userId: state.email,
                  email: state.email,
                });
                if (props.overrideSubmit) {
                  props.overrideSubmit(state.email);
                  if (
                    state.registrationDetails &&
                    state.registrationDetails[hash]
                  ) {
                    navigate(
                      `/registered/?id=${state.registrationDetails[hash].id}`,
                    );
                  } else {
                    if (props.link) {
                      navigate('/registration', {
                        state: {
                          link: props.link,
                          type: props.type,
                          location: props.location,
                          program: props.program,
                        },
                      });
                    } else {
                      setIsOpen(false);
                    }
                  }
                  return;
                }

                //GTM Event for Email Capture
                if (!state.isEmailEventSubmitted) {
                  GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                    type: props.type,
                    location: props.location,
                  });
                  dispatch({
                    type: 'SET_IS_EMAIL_EVENT_SUBMITTED',
                    payload: true,
                  });
                }

                let hash = btoa(props.type + props.link);

                if (props.onSubmit) {
                  props.onSubmit();
                }

                if (
                  state.registrationDetails &&
                  state.registrationDetails[hash]
                ) {
                  navigate(
                    `/registered/?id=${state.registrationDetails[hash].id}`,
                  );
                } else {
                  if (props.link) {
                    navigate('/registration', {
                      state: {
                        link: props.link,
                        type: props.type,
                        location: props.location,
                        program: props.program,
                      },
                    });
                  } else {
                    setIsOpen(false);
                  }
                }
              } else {
                if (props?.onOpen) {
                  props?.onOpen();
                  return;
                }
                setIsOpen(true);
                if (props.isOpen) props.isOpen();
              }
            }}
            style={{
              minWidth: props.width ? props.width : '200px',
            }}
          >
            {props.text ? props.text : 'Apply Now'}
            <FontAwesomeIcon
              icon={['fas', 'arrow-up']}
              className="ml-2 rotate-45"
              style={{ fontSize: '17px' }}
            ></FontAwesomeIcon>
          </Button>
        </>
      )}
    </div>
  );
}
ApplyNowCTA.propTypes = {
  openEvent: PropTypes.string.isRequired,
};
