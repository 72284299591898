import React from 'react';

const DefaultStrip = () => {
  return (
    <div className="flex min-h-[44px] w-screen cursor-pointer items-center justify-between bg-[#2DF8C5] pl-4 font-rubik text-[12px] text-[#013437] md:items-center md:justify-between md:gap-6 md:px-0 md:py-0 md:text-center md:text-[18px]">
      <div className="w-full text-center">
        <div className="text-center">
          Transform your career with Crio.Do | Grab{' '}
          <br className="block md:hidden" /> scholarships worth{' '}
          <strong>₹60,000</strong> |&nbsp;
          <strong className="underline">Apply Now</strong>
        </div>
      </div>
    </div>
  );
};

export default DefaultStrip;
