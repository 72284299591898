import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';
import {
  SaleFellowshipHeroImage,
  SaleQAHeroImage,
} from '../Sale/Homepage/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { LEAD_GEN_HERO } from '../../../constants/LeadGenerationConstants/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
// import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import Skeleton from '@mui/material/Skeleton';
import useCrioStat from '@src/hooks/useCrioStats';
// import scrollTo from 'gatsby-plugin-smoothscroll';
import { useLocation } from '@reach/router';
import { EPpcRoutes } from '@src/constants/routes/index';
import useSale from '@src/hooks/useSale';
import { cn } from '@/lib/utils';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import { getProgramTypeFromPath } from '@src/constants/pages';

const FspStylePageHero = (props) => {
  const { isDataSciencePage, isDataSciencePpcPage } = useDataScienceProgram();

  const showOpenForm =
    (typeof window !== 'undefined' &&
      window.location.search.includes('static_form=true')) ||
    isDataSciencePage ||
    props.showOpenForm;

  const { className } = props;

  const location = useLocation();
  const isFullStackPpcVl = location.pathname.includes(
    EPpcRoutes.FULL_STACK_PPC_ADS_VL,
  );
  const [isLoading, setIsLoading] = useState(true);

  const { sale } = useSale();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, []);

  return (
    <div
      className={cn(
        `container px-4 pb-10 ${
          sale && !isDataSciencePage
            ? 'pt-60 xxs:pt-[160px]'
            : isDataSciencePpcPage
            ? 'pt-[140px] md:pt-[160px]'
            : 'pt-[160px]'
        } ${className}`,
      )}
    >
      {
        <DesktopHero
          {...props}
          showOpenForm={showOpenForm}
          isLoading={isLoading}
          isFullStackPpcVl={isFullStackPpcVl}
        />
      }
      {
        <MobileHero
          {...props}
          showOpenForm={showOpenForm}
          isLoading={isLoading}
          isFullStackPpcVl={isFullStackPpcVl}
        />
      }
    </div>
  );
};

export const PPCStylePageHero = (props) => {
  return (
    <div className="container pb-10 pt-[140px]">
      {<DesktopHero {...props} showOpenForm={true} isLoading={false} />}
      {<MobileHero {...props} showOpenForm={true} isLoading={false} />}
    </div>
  );
};

export const PPCFullStackStylePageHero = (props) => {
  const location = useLocation();
  const isFullStackPpcVl = location.pathname.includes(
    EPpcRoutes.FULL_STACK_PPC_ADS_VL,
  );

  const { sale } = useSale();

  return (
    <div
      className={cn(
        `container ${sale ? 'pt-60 pb-10 xxs:pt-40' : 'pt-[160px]'}`,
      )}
    >
      {
        <DesktopHero
          {...props}
          showOpenForm={true}
          isFullStackPpc
          isLoading={false}
          isFullStackPpcVl={isFullStackPpcVl}
        />
      }
      {
        <MobileHero
          {...props}
          showOpenForm={true}
          isFullStackPpc
          isLoading={false}
          isFullStackPpcVl={isFullStackPpcVl}
        />
      }
    </div>
  );
};

const getHeroImage = (
  sale,
  imageUrl,
  program,
  showOpenForm,
  isFullStackPpcVl,
) => {
  const image = getImage(imageUrl);
  switch (sale) {
    case 'true':
      return program === EProgramIDs.FELLOWSHIP ? (
        <div className="sm:w-[340px] md:w-[360px] lg:w-[400px] xl:w-[450px]">
          <SaleFellowshipHeroImage
            independenceSale={true}
            image={
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="w-full md:hidden"
              />
            }
            desktopImage={
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="hidden md:block"
              />
            }
          />{' '}
        </div>
      ) : (
        <div className="sm:w-[450px]">
          <SaleQAHeroImage
            image={
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="w-full md:hidden"
              />
            }
            desktopImage={
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="hidden md:block"
              />
            }
          />
        </div>
      );
    default:
      return (
        <div className="flex w-full items-center justify-center">
          {showOpenForm ? (
            <LeadGenerationOpenForm
              isOpenForm={true}
              isFullStackPpcVl={isFullStackPpcVl}
              wrapperClassName={'flex justify-center'}
            />
          ) : (
            <>
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="w-full md:hidden"
              />
              <GatsbyImage
                loading="lazy"
                image={image}
                alt={'Hero'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                className="hidden md:block"
              />
            </>
          )}
        </div>
      );
  }
};

const DesktopHero = (props) => {
  const { program, customBanner, isFullStackPpcVl } = props || {};

  const { isDataSciencePage } = useDataScienceProgram();

  return (
    <div className="hidden w-full items-start justify-between lg:flex">
      <div className="flex max-w-[600px] flex-col space-y-1 md:space-y-6">
        <HeroContent {...props} />

        {props.isLoading ? (
          <Skeleton
            className="h-[100px] w-[250px] rounded-lg"
            animation="wave"
          />
        ) : props.showOpenForm ? (
          <></>
        ) : (
          <div className=" flex justify-start pt-4">
            <LeadGenerationButton
              type="Apply & Start Free"
              buttonLocation={LEAD_GEN_HERO}
              isFullStackPpcVl={isFullStackPpcVl}
            />
          </div>
        )}
        {props.isLoading ? (
          <Skeleton
            className="h-[200px] w-[700px] rounded-lg"
            animation="wave"
          />
        ) : customBanner ? (
          customBanner
        ) : isDataSciencePage ? (
          <GreenBannerV2 {...props} />
        ) : (
          <div className={`flex gap-3`}>
            <GreenBanner {...props}></GreenBanner>
            <DetailsBanner {...props}></DetailsBanner>
          </div>
        )}
      </div>
      {props.isLoading ? (
        <Skeleton className="h-[400px] w-[450px] rounded-lg" animation="wave" />
      ) : (
        <div className="mx-2">
          {getHeroImage(
            props.sale,
            props.image,
            program.PrimaryProgramID,
            props.showOpenForm,
            isFullStackPpcVl,
          )}
        </div>
      )}
    </div>
  );
};

const MobileHero = (props) => {
  const { program, customBanner, isFullStackPpcVl } = props || {};

  const { isDataSciencePage } = useDataScienceProgram();

  return (
    <div className="justify-center lg:hidden">
      <div className="">
        <div className="flex flex-col space-y-6 text-center">
          <HeroContent {...props} />
          <div className="mb-4 flex justify-center">
            {props.isLoading ? (
              <Skeleton className="h-[100px] w-[250px]" animation="wave" />
            ) : props.showOpenForm ? (
              <></>
            ) : (
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_HERO}
                isFullStackPpcVl={isFullStackPpcVl}
              />
            )}
          </div>
          {props.isLoading ? (
            <Skeleton className="h-[400px] w-full" animation="wave" />
          ) : (
            <>
              <div className="flex w-full justify-center">
                {getHeroImage(
                  props.sale,
                  props.image,
                  program.PrimaryProgramID,
                  props.showOpenForm,
                  isFullStackPpcVl,
                )}
              </div>
              {customBanner ? (
                customBanner
              ) : isDataSciencePage ? (
                <GreenBannerV2 {...props} />
              ) : (
                <div className="flex flex-col items-center justify-center gap-3 pt-5">
                  <GreenBanner {...props}></GreenBanner>
                  <DetailsBanner {...props}></DetailsBanner>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HeroContent = ({
  title,
  subTitle,
  description,
  isFullStackPpc,
  dark,
}) => {
  // const { isDataSciencePpcPage } = useDataScienceProgram();
  if (isFullStackPpc) {
    return (
      <div>
        <h1 className="mb-6 font-manrope text-4xl font-black leading-tight text-v5-neutral-200 md:text-[44px] ">
          <RawHTML>{title}</RawHTML>
        </h1>
        <h2 className="mb-6 font-manrope text-3xl font-black capitalize leading-tight text-v5-green-100 md:text-[34px]">
          <RawHTML>{subTitle}</RawHTML>
        </h2>
        <div className="text-left text-sm md:text-lg">
          <RawHTML>{description}</RawHTML>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1
        className={cn(
          'mb-6 font-manrope text-4xl font-black leading-tight sm:text-[44px]',
          `${dark ? 'text-v5-neutral-200' : 'text-v5-neutral-600'}`,
        )}
      >
        <RawHTML>{title}</RawHTML>
      </h1>
      <h2 className="mb-6 font-manrope text-3xl font-black capitalize leading-tight text-v5-green-100 sm:text-[34px]">
        <RawHTML>{subTitle}</RawHTML>
      </h2>
      <div
        className={cn(
          'font-manrope  md:text-[24px] [&_strong]:font-bold ',
          `${
            dark
              ? 'text-v5-neutral-300'
              : 'text-v5-neutral-500 [&_strong]:text-v5-neutral-600'
          }`,
        )}
      >
        <RawHTML>{description}</RawHTML>
      </div>
    </div>
  );
};

export const GreenBanner = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const programType = getProgramTypeFromPath(pathname);

  const { averageCTCRange } = useCrioStat();

  const dreamJobHead = averageCTCRange[0].label;
  const dreamJobRange =
    programType === 'FSD' || programType === 'QA'
      ? '6 - 15 LPA'
      : averageCTCRange[0].content || '6-15 LPA';
  const superDreamJobRange =
    programType === 'FSD'
      ? '15 - 40 LPA'
      : programType === 'QA'
      ? '8 - 20 LPA'
      : averageCTCRange[1].content || '15-40 LPA';
  const superDreamJobHead = averageCTCRange[1].label;
  const placementGuaranteeDescription =
    props.placementGuaranteeDescription ||
    'Placements start at 6 months or earlier';
  const placementHeading = props.placementHeading || 'Assured Referrals';

  const { dark, hideAssuredReferrals } = props;

  return (
    <div>
      <div
        className={cn(
          'h-full w-full rounded-[20px] p-4 text-v5-neutral-600 md:p-8 md:py-8',
          `${dark ? 'bg-v5-green-100' : 'bg-[#E6F4F2]'}`,
        )}
      >
        <div className="flex  flex-col flex-nowrap items-stretch justify-between gap-4">
          <div className="h-full text-center">
            <h5 className="mb-2 font-manrope leading-tight">
              {placementHeading}
              {hideAssuredReferrals ? '' : '*'}
            </h5>{' '}
          </div>

          <div
            className={cn(
              'min-h-[2px] w-full border-r-2',
              `${dark ? 'bg-v5-green-200' : 'bg-v5-neutral-300'}`,
            )}
          ></div>
          <div className="flex h-full flex-1 gap-2 ">
            <div className="mb-3 h-full px-4 text-center">
              <h6 className="font-rubik text-xs font-semibold text-v5-green-400">
                {dreamJobHead}
              </h6>
              <h6 className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl">
                {dreamJobRange}
              </h6>
            </div>
            <div
              className={cn(
                'h-full min-h-[40px] border-r-2 ',
                `${dark ? 'border-v5-green-200' : 'border-v5-neutral-300'}`,
              )}
            ></div>
            <div className="h-full px-4 text-center">
              <h6 className="font-rubik text-xs font-semibold text-v5-green-400">
                {superDreamJobHead}
              </h6>
              <h6 className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl">
                {superDreamJobRange}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GreenBannerV2 = ({ dark }) => {
  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <div
        className={cn(
          'flex w-full max-w-[450px] flex-col items-center justify-center gap-3 rounded-[12px] p-3 md:rounded-[20px] md:p-6',
          `${dark ? 'bg-v5-green-100' : 'bg-[#E6F4F2]'}`,
        )}
      >
        <div className="text-center">
          <p className="font-manrope text-[16px] font-extrabold text-black md:text-[18px]">
            Assured Career Assistance
          </p>
          <p className="text-[10px] font-normal italic text-v5-green-400 md:text-[12px]">
            Career Assistance starts upon completion of mandatory sprints*
          </p>
        </div>
        <div
          className={cn(
            'min-h-[2px] w-full border-r-2',
            `${dark ? 'bg-v5-green-200' : 'bg-v5-neutral-300'}`,
          )}
        ></div>
        <div className="mx-4 flex items-center gap-5">
          <InfoCardLabel title={'Fully Online'} subTitle={'9 Months'} />
          <div
            className={cn(
              'h-full min-h-[40px] border-r-2 ',
              `${dark ? 'border-v5-green-200' : 'border-v5-neutral-300'}`,
            )}
          ></div>
          <InfoCardLabel title={'Trial Workshop'} subTitle={'Free'} />
        </div>
      </div>
    </div>
  );
};

const InfoCardLabel = ({ title, subTitle, className }) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center text-center',
        className,
      )}
    >
      <p className="text-[10px] font-medium uppercase text-v5-green-400 md:text-[12px]">
        {title}
      </p>
      <p className="font-manrope text-[16px] font-extrabold text-black md:text-[18px]">
        {subTitle}
      </p>
    </div>
  );
};

export const DetailsBanner = ({ dark, className }) => {
  return (
    <div className={className}>
      <div
        className={cn(
          'h-full w-full rounded-[20px] p-4 text-v5-neutral-300 sm:w-max md:p-8',
          `${dark ? 'bg-v5-green-100' : 'bg-[#E6F4F2]'}`,
        )}
      >
        <div className="flex h-full flex-col flex-nowrap items-center justify-between gap-4">
          <div className=" text-center">
            <h6 className="font-rubik text-xs font-semibold text-v5-green-400">
              FULLY ONLINE
            </h6>
            <h6 className="font-manrope font-extrabold  text-v5-neutral-600 md:text-xl">
              9 months
            </h6>
          </div>
          <div
            className={cn(
              'min-h-[2px] w-full border-r-2  sm:block',
              `${dark ? 'bg-v5-green-200' : 'bg-v5-neutral-300'}`,
            )}
          ></div>

          <div className="text-center">
            <h6 className="font-rubik text-xs font-semibold text-v5-green-400">
              TRIAL SESSION
            </h6>
            <h6 className="font-manrope font-extrabold text-v5-neutral-600 md:text-xl">
              Free
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FspStylePageHero;
