import React from 'react';
import CertGenHeroLeftSection from './CertGenHeroLeftSection/index';
import CertGenHeroRightSection from './CertGenHeroRightSection/index';

export default function CertGenHero({ program, VideoTitle, VideoDescription }) {
  return (
    <div className="container grid grid-cols-[1fr] justify-center gap-10 py-3 font-manrope md:grid-cols-[1fr_1fr] md:gap-24 md:py-20">
      <CertGenHeroLeftSection
        VideoTitle={VideoTitle}
        VideoDescription={VideoDescription}
      />
      <CertGenHeroRightSection program={program} />
    </div>
  );
}
