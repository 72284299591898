import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { useQuery } from '@components/extra/useQuery';
import CrioButton from '@components/v5/CrioButton/index';
import {
  LEAD_GEN_FORM_OPEN,
  LEAD_GEN_NAVIGATION,
} from '@src/constants/LeadGenerationConstants/index';
import useLogin from '@src/hooks/useLogin';
import React from 'react';

/**
 * AuthButtons component for rendering authentication-related buttons.
 * @param {boolean} hideCTA - Whether to hide the call-to-action buttons.
 * @param {string} className - Custom class name for styling purposes.
 * @returns {JSX.Element} - AuthButtons component JSX.
 */

export default function AuthButtons({ hideCTA, signInBtnClassName }) {
  // Custom hook to handle login functionality
  const handleLogin = useLogin();
  // Hook to check if lead form should be opened
  const shouldOpenLeadForm = useQuery('leadform_open') === 'true';

  return (
    <>
      {/* Sign In button */}
      <div>
        <CrioButton
          variant="secondary"
          className={`h-[38px] ${signInBtnClassName}`}
          onClick={handleLogin}
        >
          {'Sign In'}
        </CrioButton>
      </div>
      {/* Lead Generation button */}
      {!hideCTA && (
        <LeadGenerationButton
          buttonLocation={
            shouldOpenLeadForm ? LEAD_GEN_FORM_OPEN : LEAD_GEN_NAVIGATION
          }
          type="Navigation"
          shouldOpenLeadForm={shouldOpenLeadForm}
        />
      )}
    </>
  );
}
