import React, { useMemo } from 'react';
import NavigationV2 from '@components/NavigationV2';
import MasterclassHomePageHero from '@components/Masterclass/HomePageHero';
import ProgramSelectionTab from '@components/Masterclass/ProgramSelectionTab';
import useHomePageMetadata from '@src/hooks/Masterclass/useHomePageMetadata';
import EventList from '@components/Masterclass/EventList';
import {
  EEventType,
  PAST_EVENT_SECTION_ID,
  programTabOptions,
  UPCOMING_EVENTS_SECTION_ID,
} from '@src/constants/masterclass';
import FooterV2 from '@components/FooterV2';
import useResizer from '@components/extra/useResizer';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import ScrollButtonComponent from '@components/ScrollButtonComponent';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_FLOATING_CTA } from '@src/constants/LeadGenerationConstants';
import useMasterclassHomePageSeo from '@src/hooks/Masterclass/useMasterclassHomePageSeo';
import SEO from '@components/Common/SEO';
import BottomStrip from '@components/PPCNavbar/BottomStrip';

export default function MasterclassHomePage() {
  const { getEventList, selectedTab, handleTabClick, onSubmitOtp } =
    useHomePageMetadata();
  const isMobile = useResizer();
  const seoData = useMasterclassHomePageSeo();

  /**
   * Filter events based on selected tab
   * Then filter events based on whether they are upcoming or past
   */
  const { upcomingEvents, pastEvents } = useMemo(() => {
    const events = getEventList();
    let filteredEvents =
      selectedTab === programTabOptions?.[0]?.id
        ? events
        : events.filter((event) => event.sessionProgram === selectedTab);
    return {
      upcomingEvents: [...filteredEvents.filter((event) => !event.isPastEvent)],
      pastEvents: [...filteredEvents.filter((event) => event.isPastEvent)],
    };
  }, [getEventList, selectedTab]);

  return (
    <>
      {/* Navigation Component */}
      <SEO
        title={seoData?.title}
        description={seoData?.metaDescription}
        meta={seoData?.meta}
        canonicalUrl={seoData?.canonicalUrl}
      >
        {seoData?.script}
      </SEO>
      <NavigationV2 hideCTA hideTopStrip />
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Register"
            text="Sign Up"
            onClick={() => {
              document.body.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={300}
      />

      {/* Masterclass Home Page Hero */}
      <MasterclassHomePageHero />

      {/* Program Selection Tab */}
      <ProgramSelectionTab
        selectedTab={selectedTab}
        handleTabClick={handleTabClick}
        isPastEventsAvailable={pastEvents.length > 0}
      />

      <LeadGenerationOpenForm
        isOpenForm={true}
        pageTwoButtonText="Sign Up"
        applicationPage="masterclass"
        hasProgramInterestedField={true}
        hideLastName={true}
        onSubmitPageTwo={onSubmitOtp}
        wrapperClassName={'container pb-10 pt-8 block md:hidden'}
      />

      <EventList
        events={upcomingEvents}
        title={EEventType.UPCOMING_EVENTS}
        displayType={isMobile ? 'swiper' : 'grid'}
        wrapperClassName={
          pastEvents.length > 0 ? 'md:pb-10 pb-0' : 'md:pb-20 pb-6'
        }
        sectionId={UPCOMING_EVENTS_SECTION_ID}
      />
      <EventList
        events={pastEvents}
        title={EEventType.PAST_EVENTS}
        shouldShowSection={pastEvents.length > 0}
        displayType={isMobile ? 'swiper' : 'grid'}
        sectionId={PAST_EVENT_SECTION_ID}
      />
      <FooterV2 />
      <BottomStrip />
    </>
  );
}
