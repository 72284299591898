import ProjectCategoryCard from '@components/ProjectCategoryCard/index';
import React, { useContext } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { getImage } from 'gatsby-plugin-image';

export default function ProjectCategories(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  return (
    <div className={`pt-14 `}>
      <div className="container   pb-20">
        <h3 className="mb-4">Explore our Categories</h3>
        <div className="flex flex-wrap justify-center">
          <div className="row w-full">
            {props.categories.map((ele, idx) => {
              const projectImage =
                props.images.find((val) => val.name == ele.category) ||
                props.images.find((ele) => ele.name == 'generic');
              const image = getImage(projectImage.childImageSharp);
              const url = ele.url.replace('c++', 'cpp');
              return (
                <>
                  <div className="col-6 col-lg-3 m-0 mb-3 p-0 " key={idx}>
                    <ProjectCategoryCard
                      url={url}
                      image={image}
                      meCategoryId={
                        ele.meCategoryId == 'c++' ? 'cpp' : ele.meCategoryId
                      }
                    ></ProjectCategoryCard>
                  </div>{' '}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
