import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserTie from '../../../../images/sde2/user-tie.svg';
import Users from '../../../../images/sde2/users.svg';
import Clipboard from '../../../../images/sde2/clipboard.svg';
import Camera from '../../../../images/sde2/camera.svg';
import Briefcase from '../../../../images/sde2/briefcase.svg';
import { cn } from '@/lib/utils';

export default function Highlight(props) {
  const { highlight } = props;
  const { svgType, icon, title, description } = highlight;

  const getSvg = () => {
    switch (svgType) {
      case 'fa-briefcase':
        return <Briefcase />;
      case 'fa-user-tie':
        return <UserTie />;
      case 'fa-screen-users':
        return <Users />;
      case 'fa-clipboard-list-check':
        return <Clipboard />;
      case 'fa-video':
        return <Camera />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={cn(
        'flex flex-1 items-start justify-start gap-4 lg:items-start',
      )}
    >
      <div className="h-8 w-8">
        {svgType ? (
          getSvg()
        ) : (
          <FontAwesomeIcon
            className="h-full w-full text-v5-green-100"
            icon={icon}
          />
        )}
      </div>
      <div className="flex flex-1 flex-col gap-3 text-left">
        <div className="text-v5-crio-neutral-200 font-manrope text-[20px] font-bold leading-none">
          {title}
        </div>
        <RawHTML className="text-sm italic text-v5-neutral-300">
          {description}
        </RawHTML>
      </div>
    </div>
  );
}
