import React from 'react';
import CurriculumDetails from '../CurriculumDetails/index';
import useAccelerate from '@src/hooks/useAccelerate';
import { cn } from '@/lib/utils';

export default function DreamJobCurriculum({
  curriculum,
  type,
  dreamJobRange,
}) {
  const { isAcceleratePage } = useAccelerate();

  return (
    <>
      <h1 className="mt-10 mb-12 text-center font-manrope text-2xl font-extrabold capitalize leading-tight sm:text-4xl">
        {isAcceleratePage ? (
          <>Curriculum to crack dream jobs</>
        ) : (
          <>
            Curriculum to crack dream jobs <br className="hidden sm:block"></br>
            ({dreamJobRange}) in top Product-Based{' '}
            <br className="hidden sm:block"></br>companies
          </>
        )}
      </h1>
      <div
        className={cn(
          'mb-16 rounded-[20px] bg-v5-green-500 text-white',
          isAcceleratePage && 'mb-0',
        )}
      >
        <div className="flex w-full flex-wrap items-stretch gap-y-4  gap-x-4 pt-4 pb-7 text-v5-neutral-200  sm:px-6 sm:pb-7">
          <CurriculumDetails curriculumData={curriculum} type={type} />
        </div>
      </div>
    </>
  );
}
