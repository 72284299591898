import React from 'react';
import FormContextProvider from '@src/context/FormContextProvider';
import LeadGenerationFormComponent from './LeadGenerationFormComponent';

// Web view wrapper for lead generation form
export default function LeadGenerationFormWebView({
  closeDialog,
  buttonLocation,
  programType,
  isOnePageForm,
  onSubmitPageOne,
  pageOneButtonText,
  onSubmitPageTwo,
  pageTwoButtonText,
  applicationPage,
  formHeading,
  pageOneGradYear,
  pageOneWorkingProfessional,
  isFullStackPpcVl,
  customUtmParams,
}) {
  return (
    <FormContextProvider>
      <div className="m-3 flex max-h-[85vh] w-[580px] flex-col items-stretch  rounded-[45px] bg-v5-green-700 px-16 pb-6 pt-4">
        <LeadGenerationFormComponent
          mobile={false}
          closeDialog={closeDialog}
          buttonLocation={buttonLocation}
          programType={programType}
          isOnePageForm={isOnePageForm}
          onSubmitPageOne={onSubmitPageOne}
          pageOneButtonText={pageOneButtonText}
          onSubmitPageTwo={onSubmitPageTwo}
          pageTwoButtonText={pageTwoButtonText}
          applicationPage={applicationPage}
          formHeading={formHeading}
          pageOneGradYear={pageOneGradYear}
          pageOneWorkingProfessional={pageOneWorkingProfessional}
          isFullStackPpcVl={isFullStackPpcVl}
          customUtmParams={customUtmParams}
        />
      </div>
    </FormContextProvider>
  );
}
