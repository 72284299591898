import {
  LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_PROGRAM_ID_QA,
} from '@src/constants/LeadGenerationConstants';
import { EPathname } from '@src/constants/routes/index';

const getDemoSlotByProgram = (program) => {
  switch (program) {
    case 'Software Development':
      return 'https://rebrand.ly/crio-sd-on-demand';
    case 'QA Automation':
      return 'https://rebrand.ly/crio-qa-on-demand';
    case 'Data Analytics':
      return 'https://rebrand.ly/crio-data-analytics-on-demand';
    case 'Accelerate':
      return 'https://rebrand.ly/crio-accelerate-on-demand';
    default:
      return 'https://rebrand.ly/crio-sd-on-demand';
  }
};

export const demoSlotRedirectUrlBasedOnRoute = (
  route,
  defaultRedirect,
  program,
) => {
  if (route.includes('campaign')) {
    return getDemoSlotByProgram(program);
  }

  switch (route) {
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM:
    case EPathname.SOFTWARE_DEVELOPMENT_FELLOWSHIP_PROGRAM_PPC_PAGE:
    case EPathname.SYSTEM_DESIGN:
      return 'https://rebrand.ly/crio-sd-on-demand';
    case EPathname.QA_AUTOMATION_TESTING:
    case EPathname.QA_PPC_PAGE:
      return 'https://rebrand.ly/crio-qa-on-demand';
    case EPathname.DATA_ANALYTICS:
    case EPathname.DATA_ANALYTICS_PPC_PAGE:
      return 'https://rebrand.ly/crio-data-analytics-on-demand';
    case EPathname.ACCELERATE:
      return 'https://rebrand.ly/crio-accelerate-on-demand';
    case '/':
      return getDemoSlotByProgram(program);
    default:
      return defaultRedirect;
  }
};

export const isYearGreaterThanCurrent = (selectedYear) => {
  const currentYear = new Date().getFullYear();
  return selectedYear >= currentYear;
};

export const getProgramIdMasterclass = (program) => {
  switch (program) {
    case 'Software Development':
      return LEAD_GEN_PROGRAM_ID_FULL_STACK;
    case 'QA Automation':
      return LEAD_GEN_PROGRAM_ID_QA;
    case 'Data Analytics':
      return LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS;
    default:
      return LEAD_GEN_PROGRAM_ID_FULL_STACK;
  }
};

const getProgramID = ({
  isMasterclassRegisterPage,
  getCurrentProgram,
  trialProgramId,
  isMQALead,
  isDataSciencePage,
}) => {
  if (isMasterclassRegisterPage) {
    return getProgramIdMasterclass(getCurrentProgram());
  }
  if (trialProgramId) {
    return trialProgramId;
  }
  if (isMQALead) {
    return LEAD_GEN_PROGRAM_ID_QA;
  }
  if (isDataSciencePage) {
    return LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS;
  }
  return LEAD_GEN_PROGRAM_ID_FULL_STACK;
};
