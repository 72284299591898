import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Testimonial = ({ dark }) => {
  const testimonials = [
    {
      image: (
        <StaticImage
          loading="lazy"
          width={200}
          src="../../../images/v5/Testimonial/Piyush.png"
          placeholder="blurred"
          alt="Linkedin"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
      name: 'Piyush Goel',
      role: 'VP - Engineering',
      companyImage: (
        <StaticImage
          width={200}
          loading="lazy"
          src="../../../images/v5/Testimonial/Capillary.png"
          placeholder="blurred"
          alt="Linkedin"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
      testimonial:
        "Their cutting edge platform and pedagogical methods are a class apart and help groom engineers not only on the technical skills but also the tools necessary for today's software development industry",
    },
    {
      image: (
        <StaticImage
          loading="lazy"
          src="../../../images/v5/Testimonial/Ramesh.png"
          placeholder="blurred"
          alt="Linkedin"
          width={200}
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
      name: 'Ramesh Kumar Katreddi',
      role: 'VP - Engineering',
      companyImage: (
        <StaticImage
          loading="lazy"
          width={200}
          src="../../../images/v5/Testimonial/Jumbotail.png"
          placeholder="blurred"
          alt="Linkedin"
          imgStyle={{ objectFit: 'contain' }}
        />
      ),
      testimonial:
        'Crio trains developers to have a hunger for self-learning along with good knowledge of tools and methodologies used in day-to-day development, which are THE MOST important qualities for fast-paced and value-driven companies like Jumbotail. Looking forward to hiring more engineers from Crio.',
    },
  ];

  return (
    <>
      {testimonials.map((el) => {
        return (
          <div className="sm:w-full" key={el.name}>
            <div className="flex items-center justify-center rounded-[30px] bg-v5-green-100 font-rubik sm:h-[250px] md:rounded-[50px]">
              <p className="mb-5 px-8 py-8 text-center leading-relaxed sm:mb-0 sm:py-2 md:px-6">
                "{el.testimonial}"
              </p>
            </div>
            <div className="flex -translate-y-[36px] flex-col items-center justify-center">
              <div className="h-[50px] w-[50px] rotate-45 rounded-[10px] bg-v5-green-100"></div>

              <div className={`mt-2 h-[80px] w-[80px]`}>{el.image}</div>

              <div className="mt-2 flex flex-col items-center justify-center">
                <div className="flex items-center justify-center gap-2">
                  <h3
                    className={`font-manrope text-lg ${
                      dark ? 'text-v5-neutral-200' : 'text-black'
                    }`}
                  >
                    {el.name}
                  </h3>
                </div>
                <h4
                  className={`text-base  ${
                    dark ? 'text-v5-neutral-300' : 'text-v5-neutral-400'
                  }    font-manrope`}
                >
                  {el.role}
                </h4>
                <div className={`h-[60px] w-[100px]`}>{el.companyImage}</div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Testimonial;
