// extracted by mini-css-extract-plugin
export var bannerContainer = "PostOnLinkedIn-module--bannerContainer--4c13e";
export var bottomLeftDesign = "PostOnLinkedIn-module--bottomLeftDesign--e7fcb";
export var cameraContainer = "PostOnLinkedIn-module--cameraContainer--6671b";
export var canMasterLogo = "PostOnLinkedIn-module--canMasterLogo--26bfb";
export var captureButton = "PostOnLinkedIn-module--captureButton--492ad";
export var container = "PostOnLinkedIn-module--container--76b2d";
export var content = "PostOnLinkedIn-module--content--8b29d";
export var contentContainer = "PostOnLinkedIn-module--contentContainer--75896";
export var extraContent = "PostOnLinkedIn-module--extraContent--c9029";
export var photo = "PostOnLinkedIn-module--photo--97454";
export var photoButton = "PostOnLinkedIn-module--photoButton--246fb";
export var photoPreview = "PostOnLinkedIn-module--photoPreview--a4efa";
export var postButton = "PostOnLinkedIn-module--postButton--5a643";
export var reloadButton = "PostOnLinkedIn-module--reloadButton--ad5de";
export var retakeButton = "PostOnLinkedIn-module--retakeButton--57b90";
export var rotate = "PostOnLinkedIn-module--rotate--6f3c3";
export var success = "PostOnLinkedIn-module--success--cec8b";
export var textArea = "PostOnLinkedIn-module--textArea--e13d8";
export var textAreaContainer = "PostOnLinkedIn-module--textAreaContainer--a18aa";
export var topRightDesign = "PostOnLinkedIn-module--topRightDesign--21670";
export var video = "PostOnLinkedIn-module--video--dcf25";
export var viewPostButton = "PostOnLinkedIn-module--viewPostButton--e45d1";