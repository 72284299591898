import React, { useState } from 'react';
import { useStyles } from '../index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpecializationOptions from './SpecializationOptions';
import RawHTML from '@components/Common/RawHTML/index';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Accordion } from '../index';
import Card from './Card';
import { useLocation } from '@reach/router';
import { cn } from '@/lib/utils';
import useAccelerate from '@src/hooks/useAccelerate';

const CustomAccordion = ({
  open,
  data,
  gradient,
  variant,
  isInnerAccordion,
  currentAccordionIndex,
  currentTabName,
}) => {
  const [expanded, setExpanded] = useState(open || false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [selectedSpecialization, setSelectedSpecialization] = useState(0);
  const [prevSelectedTab, setPrevSelectedTab] = useState(currentTabName);
  const location = useLocation();
  const classes = useStyles({ isExpanded: expanded });
  let cards = data?.cards || [];
  let subCards = data?.subCards || [];

  const { isAcceleratePage } = useAccelerate();

  // Display a specified number of cards for aspiring and senior developers from the subCards array.
  const { specializationType } = data;
  let sliceFrom = 0;
  let sliceTill = subCards.length;

  if (specializationType && selectedSpecialization === 0) {
    sliceTill = specializationType[0]?.numberOfCards || sliceTill;
  } else if (specializationType && selectedSpecialization === 1) {
    sliceFrom = specializationType[0]?.numberOfCards || sliceFrom;
  }

  const image = getImage(data?.image);

  function extractTextFromHTML(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  }

  const handleChange = (event, isExpanded) => {
    if (isExpanded && !dropdownOpened) {
      const extractedTitle = extractTextFromHTML(data?.title);

      // Track GTM event
      GTM.track(gtmEvents.CURRICULLUM_DROPDOWN_CLICK, {
        curriculumSection: extractedTitle,
        action: 'open',
        type: 'FELLOWSHIP',
      });

      // Set dropdownOpened to true to avoid tracking multiple times
      setDropdownOpened(true);
    }

    //store currently opened accordions in state to avoid losing open state
    setExpanded(isExpanded);
  };

  if (variant === 'FAQ') {
    return (
      <FAQCustomAccordion
        data={data}
        expanded={expanded}
        handleChange={handleChange}
        classes={classes}
        image={image}
      />
    );
  }

  return data ? (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[20px] md:rounded-[30px] ${
        gradient
          ? 'border-gradient-radius30 border-gradient bg-black bg-opacity-30'
          : `${isInnerAccordion ? 'bg-v5-green-500' : 'bg-v5-green-700'}`
      }`}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div
            className={`flex h-[24px] w-[24px] items-center justify-center rounded-full ${
              isInnerAccordion ? 'bg-v5-green-700' : 'bg-v5-green-500'
            } text-v5-neutral-300`}
          >
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-start gap-x-4">
            {image ? (
              <div className="w-[40px]  min-w-[40px]">
                <GatsbyImage
                  placeholder="blurred"
                  loading="lazy"
                  image={image}
                  alt={'Curriculum'}
                  style={{ maxHeight: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            ) : (
              data?.image && (
                <div className="w-[40px] min-w-[40px]">
                  <img
                    src={
                      require(`@src/images/v5/CurriculumIconsV2/${data.image}`)
                        .default
                    }
                    alt={'Curriculum'}
                    style={{
                      maxHeight: '100%',
                      objectFit: 'contain',
                      width: '40px',
                    }}
                  />
                </div>
              )
            )}
            <div>
              <h6
                className={`transition-all  sm:w-full ${
                  isInnerAccordion ? '' : 'md:text-[18px]'
                }`}
              >
                <RawHTML>{data?.title}</RawHTML>
              </h6>
              <p className="text-sm text-v5-neutral-300">{data?.duration}</p>
            </div>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails
        className={`${
          gradient
            ? ''
            : `${isInnerAccordion ? 'bg-v5-green-500' : 'bg-v5-green-700'}`
        }  rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        {isInnerAccordion && (
          <hr className="mb-5 border-b border-crio-green-400 opacity-20" />
        )}
        <div
          className={`${
            isInnerAccordion ? 'mt-2' : specializationType || 'md:pl-[54px]'
          }`}
        >
          {/* SPECIALIZATION TAB VIEW */}
          <SpecializationOptions
            specializationType={specializationType}
            selectedSpecialization={selectedSpecialization}
            setSelectedSpecialization={setSelectedSpecialization}
          />

          {/* DESCRIPTION */}
          {data.description && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description}</RawHTML>
            </div>
          )}
          {selectedSpecialization === 0 && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description1}</RawHTML>
            </div>
          )}
          {selectedSpecialization === 1 && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description2}</RawHTML>
            </div>
          )}

          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                >
                  <h6 className="text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
        {cards.length > 0 && (
          <>
            <div className="md:pl-[54px] md:pt-1">
              <h6 className="font-manrope text-white">{data.cardTitle}</h6>

              <div className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4 ">
                {cards.map(
                  (ele, idx) =>
                    ele?.data !== null && (
                      <div
                        className={`${
                          idx === 0 && !data?.description
                            ? 'mt-[-30px]'
                            : 'mt-[-10px]'
                        } w-full`}
                        key={idx}
                        id={ele?.anchor}
                      >
                        <CustomAccordion
                          data={ele}
                          open={location.hash == '#' + ele?.anchor}
                          isInnerAccordion
                          currentInnerAccordionIndex={idx}
                          currentAccordionIndex={currentAccordionIndex}
                        ></CustomAccordion>
                      </div>
                    ),
                )}
              </div>
            </div>
          </>
        )}

        {subCards.length > 0 && (
          <>
            <div className={`md:pt-1`}>
              {/* <hr className="my-3 border-b border-crio-green-400 opacity-20" /> */}
              <h6 className="font-manrope text-white">{data?.cardTitle}</h6>

              <div
                className={cn(
                  'mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2',
                  `${isAcceleratePage ? 'md:grid-cols-1' : ''}`,
                )}
              >
                {subCards.slice(sliceFrom, sliceTill).map(
                  (ele, idx) =>
                    ele?.data !== null && (
                      <div
                        className={`${
                          (sliceTill - sliceFrom) % 2 !== 0 &&
                          idx === sliceTill - sliceFrom - 1
                            ? 'col-span-full'
                            : ele?.isCardLarge
                            ? 'col-span-full'
                            : ''
                        }
                        w-full`}
                        key={idx}
                        id={ele?.anchor}
                      >
                        <Card
                          data={ele}
                          specializationType={specializationType}
                        />
                      </div>
                    ),
                )}
              </div>
            </div>
          </>
        )}
        {/* FOOTER */}
        {!isInnerAccordion && isAcceleratePage ? (
          <p className="my-4 text-[14px] italic text-[#B9CFCA] md:pl-[54px] md:text-[16px]">
            * Personalized learning journeys come with 6 Live Modules (1 month
            each) and 3 Self Paced Modules. Career Services would be for a
            duration of 3 months. The entire journey has to be completed within
            12 months of starting the Program.
          </p>
        ) : (
          <></>
        )}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

const FAQCustomAccordion = ({
  data,
  expanded,
  handleChange,
  classes,
  image,
}) => {
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[10px] bg-v5-green-700`}
      // {...(gradient && { style: { border: '1px solid #07634C' } })}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-center gap-x-4">
            <div className="w-[40px]  min-w-[40px]">
              <GatsbyImage
                placeholder="blurred"
                loading="lazy"
                image={image}
                alt={'Curriculum'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div>
              <h6 className={`text-base transition-all sm:w-full`}>
                <RawHTML>{data?.question}</RawHTML>
              </h6>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <div className="md:pl-[20px]">
          <p>
            <RawHTML>{data?.answer}</RawHTML>
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
