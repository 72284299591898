import { cn } from '@/lib/utils';
import React from 'react';

export default function GlassContainer(props) {
  const { children, className } = props;

  return (
    <div className={cn('flex items-center', className)}>
      <div
        className={`border-gradient-radius30 border-gradient to-[rgba(66, 70, 102, 0.4)] z-[999] mx-4 mt-0 flex w-full -translate-y-[6px] flex-col items-start justify-center gap-4 rounded-[30px] bg-gradient-to-r from-crio-green-1050 px-10 py-7 text-center shadow-md  backdrop-blur md:mx-0 md:flex-row lg:mt-0 lg:flex-row lg:items-start lg:justify-evenly lg:px-10 lg:text-left`}
      >
        {children}
      </div>
    </div>
  );
}
