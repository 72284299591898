import EventCard from '@components/Masterclass/EventCardV2';
import React from 'react';

export default function EventGrid({ events }) {
  return (
    <div className="grid grid-cols-3 gap-9">
      {events.map((event, index) => (
        <EventCard key={index} event={event} />
      ))}
    </div>
  );
}
