import topStripDb from '../data/top_strip.json';

const useTopStripValidPages = () => {
  //Top strip data
  const { top_strip } = topStripDb;
  const pages = top_strip?.top_strip_valid_pages?.data;
  const top_strip_valid_pages = pages.map((page) => page?.attributes?.PagePath);

  return top_strip_valid_pages;
};

export default useTopStripValidPages;
