import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * MobileMenuToggle component renders a toggle button for mobile menu.
 * Parent Component: Navbar
 *
 * @param {boolean} open - Flag indicating whether the mobile menu is open or closed.
 * @param {Function} setOpen - Function to set the state of the mobile menu.
 */

export default function MobileMenuToggle({ open, setOpen }) {
  return (
    <div className="px-3 py-1 pr-1 md:hidden">
      {/* Conditionally render the menu icon based on whether the mobile menu is open */}
      {!open ? (
        // Render the menu icon if the menu is closed
        <FontAwesomeIcon
          size="1x"
          icon={['far', 'bars']}
          onClick={() => {
            // Toggle the state of the mobile menu to open when clicked
            setOpen(true);
          }}
        />
      ) : (
        // Render the close icon if the menu is open
        <FontAwesomeIcon
          size="1x"
          icon={['far', 'times']}
          onClick={() => {
            // Toggle the state of the mobile menu to close when clicked
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}
