import React from 'react';
import * as styles from './FAQsV2.module.scss';

export default function FAQsV2(props) {
  return (
    <>
      <div className="flex flex-col items-center justify-center text-left">
        <div className="container">
          <div className={`${styles.accordion} custom`}>
            <ul>
              {props.faqsData.map((ele, idx) => (
                <li key={idx}>
                  <input type="checkbox" defaultChecked={true} />
                  <i />
                  <div className="subtitle1 font-light ">{ele.Question}</div>
                  <p className="font-light">
                    <div className={`pt-2`}>
                      <ul>
                        <li>{ele.Answer}</li>{' '}
                      </ul>
                    </div>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
