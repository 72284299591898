import React from 'react';
import EventGrid from './EventGrid';
import { cn } from '@/lib/utils';
import EventSwiper from './EventSwiper';
import { EEventDisplayType } from '@src/constants/masterclass';

export default function EventList({
  events,
  displayType, // ['grid' | 'swiper' ]
  title,
  titleClassName,
  wrapperClassName,
  shouldShowSection = true,
  sectionId,
}) {
  // Get the list of events based on the display type:
  const getListBasedOnDisplayType = () => {
    switch (displayType) {
      case EEventDisplayType.GRID:
        return <EventGrid events={events} />;
      case EEventDisplayType.SWIPER:
        return <EventSwiper events={events} id={sectionId} />;
      default:
        return <EventGrid events={events} />;
    }
  };

  return shouldShowSection ? (
    <div
      className={cn(
        'container flex scroll-mt-16 flex-col gap-y-6 pb-6 md:gap-y-9 md:pb-20',
        wrapperClassName,
      )}
      id={sectionId ?? ''}
      key={sectionId}
    >
      {/* Event List Section Heading */}
      <div
        className={cn(
          'text-center font-manrope text-[24px] font-bold md:text-left md:text-[40px]',
          titleClassName,
        )}
      >
        {title}
      </div>

      {/* Display event list */}
      {events.length > 0 ? (
        getListBasedOnDisplayType()
      ) : (
        <div className="relative -top-5 mt-5 text-center text-v5-neutral-500 md:mt-auto md:text-left">
          No events are available right now. <br /> Want to stay updated? Sign
          up for the Crio Masterclass to get the latest announcements!
        </div>
      )}
    </div>
  ) : null;
}
