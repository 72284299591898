import { useLocation } from '@reach/router';

const useAccelerate = () => {
  const location = useLocation();

  const isAcceleratePage = location.pathname === '/accelerate/';

  return { isAcceleratePage };
};

export default useAccelerate;
