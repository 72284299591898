import { cn } from '@/lib/utils';
import { CampaignProgramOptions } from '@src/constants/campaign';
import React from 'react';

export default function SelectProgram({ selectedProgram, setSelectedProgram }) {
  return (
    <div className="absolute -top-24 w-full text-center lg:top-5">
      <div className="inline-block rounded-md bg-[#E6F4F2] py-2 px-2 lg:rounded-[20px] lg:py-3 lg:px-4">
        <div className="flex flex-col gap-2 rounded-md bg-white px-2 py-2 font-rubik text-sm font-semibold leading-4 lg:flex-row lg:gap-4 lg:rounded-[10px] lg:px-3 lg:text-[20px] lg:leading-6">
          {CampaignProgramOptions.map((program) => (
            <div
              key={program.id}
              className={cn(
                'cursor-pointer rounded-lg p-4 ',
                selectedProgram.id === program.id
                  ? 'bg-v5-green-100'
                  : 'bg-[#E6F4F2]',
              )}
              onClick={() => setSelectedProgram(program)}
            >
              {program.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
