import React from 'react';
import GreenBanner from '../GreenBanner/index';

export default function PersonalizedPlan({ heading, postContent }) {
  return (
    <div className="my-6 flex w-full items-center justify-center px-4">
      <GreenBanner
        quote={
          heading || 'Land your dream job with a proven personalized plan!'
        }
        postContent={React.cloneElement(postContent)}
      ></GreenBanner>
    </div>
  );
}
