import React, { useContext, useEffect, useState } from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import Skeleton from '@mui/material/Skeleton';
import loadable from '@loadable/component';
import { motion, useAnimation } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import pMinDelay from 'p-min-delay';

import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import GreenBanner from '../GreenBanner/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_DOWNLOAD_PLACEMENT_STATS } from '@src/constants/LeadGenerationConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';
import useCrioStat from '@src/hooks/useCrioStats';
import useCrioDownload from '@src/hooks/useCrioDownload';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

const VideoTestimonialCarousel = loadable(
  () => pMinDelay(import('../VideoTestimonialCarousel/index'), 300),
  {
    fallback: (
      <div className="flex h-[500px] justify-center">
        <LoadingSkeleton></LoadingSkeleton>
      </div>
    ),
  },
);

const VideoTestimonialSection = ({
  disableGreenBanner,
  disableCareerHandbook,
  disableSectionHeading,
  headingClassName,
  ...props
}) => {
  const state = useContext(GlobalStateContext);
  const { communityNumbers } = useCrioStat();
  const communityHeading = communityNumbers[0].label;

  const {
    triggerCareerHandbookWebengageFlow,
    triggerPlacementStatsWebengageFlow,
  } = useCrioDownload();

  const { isDataSciencePage } = useDataScienceProgram();

  return (
    <SectionLayout
      heading={
        disableSectionHeading
          ? ''
          : isDataSciencePage
          ? 'Join a community of 10000+ Professionals'
          : communityHeading
      }
      headingClassName={headingClassName}
      description={
        disableSectionHeading ? (
          ''
        ) : (
          <>
            <strong className={`font-bold ${props.dark ? '' : 'text-black'}`}>
              Meet and grow together
            </strong>{' '}
            with a community of passionate{' '}
            {isDataSciencePage ? 'professionals' : 'developers'} who have taken
            their careers to new heights with Crio.
          </>
        )
      }
      {...props}
    >
      <div className="container">
        <div className="flex justify-center">
          {!disableGreenBanner && (
            <GreenBanner
              gradient={props.gradient}
              quote={
                props.quote || (
                  <>
                    Get more insights on how Crio can help you crack a{' '}
                    <br className="hidden sm:block"></br>full stack or backend
                    role in top tech companies
                  </>
                )
              }
              postContent={
                <>
                  {!disableCareerHandbook && (
                    <LeadGenerationButton
                      type="Download"
                      text="Download Career Handbook"
                      isOnePageForm
                      formHeading="Career Handbook"
                      pageOneButtonText="Download Now"
                      buttonLocation="Download_Career_Handbook"
                      onSubmitPageOne={triggerCareerHandbookWebengageFlow}
                      {...(state.email &&
                        state.phone && {
                          onClick: () => {
                            triggerCareerHandbookWebengageFlow(
                              state.email,
                              state.phone,
                              EButtonType.DOWNLOAD_CAREER_SERVICES,
                              'CAREER_SERVICES',
                            );
                          },
                        })}
                    ></LeadGenerationButton>
                  )}
                  <LeadGenerationButton
                    type="Download"
                    text="Download Placement Stats"
                    isOnePageForm
                    formHeading="Placement Stats"
                    buttonLocation={LEAD_GEN_DOWNLOAD_PLACEMENT_STATS}
                    pageOneButtonText="Download Now"
                    onSubmitPageOne={triggerPlacementStatsWebengageFlow}
                    {...(state.email &&
                      state.phone && {
                        onClick: () => {
                          triggerPlacementStatsWebengageFlow(
                            state.email,
                            state.phone,
                            EButtonType.DOWNLOAD_PLACEMENT_STATS,
                            'DOWNLOAD_PLACEMENT_STAT_CARD',
                          );
                        },
                      })}
                  ></LeadGenerationButton>
                </>
              }
            ></GreenBanner>
          )}
        </div>
        <div className="mt-10 md:min-h-[620px]">
          <FadeInWhenVisible
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <VideoTestimonialCarousel
              gradient={props.gradient}
              dark={props.dark}
              isTypeQa={props.isTypeQa}
              isTypeFellowShipPlus={props.isTypeFellowShipPlus}
              numberOfTestimonials={props.numberOfTestimonials}
            ></VideoTestimonialCarousel>
          </FadeInWhenVisible>
        </div>
      </div>
    </SectionLayout>
  );
};

function LoadingSkeleton() {
  const classes = useStyles();

  return (
    <div className=" flex h-full w-full justify-between gap-x-6 overflow-hidden ">
      {Array(3)
        .fill()
        .map((ele, idx) => (
          <div key={idx} className={`${classes.avatarSkeletonContainer}`}>
            <Skeleton className={classes.avatarLoader} animation="wave" />
          </div>
        ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  avatarSkeletonContainer: {
    overflow: 'hidden',
    position: 'relative',
    minWidth: '300px',
    width: '100%',
    height: '100%',
    borderRadius: '50px',
  },
  avatarLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'scale(1)',
  },
}));

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [hasComeInViewOnce, setHasComeInViewOnce] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setHasComeInViewOnce(true);
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      className="w-full"

      //   exit="hidden"
    >
      {hasComeInViewOnce ? props.children : ''}
    </motion.div>
  );
}
export default VideoTestimonialSection;
