import React from 'react';
import MasterclassSectionLayout from '../Layouts/MasterclassSectionLayout';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import useResizer from '@components/extra/useResizer';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';
import EventCard from '../EventCardV2';

export default function UpcomingEvents() {
  const { getUpcomingEvents, getEventDetails } = useRegisterMetadata();
  const isMobile = useResizer();
  const isLessThan1024 = useResizer(1024);
  const isLessThan1200 = useResizer(1200);
  const { events, isShowingPreviousEvents } = getUpcomingEvents() || {};

  const getSlidesPerView = () => {
    if (isLessThan1024) {
      if (isMobile) {
        return 1.5;
      }
      return 2;
    }
    return isLessThan1200 ? 1.5 : 2;
  };

  return events.length > 0 ? (
    <MasterclassSectionLayout
      className="masterclass-event-list container relative -top-8 grid grid-cols-1 md:static md:pt-[32px] md:pb-[100px] lg:grid-cols-[58%_1fr] xl:grid-cols-[58%_1fr]"
      heading={isShowingPreviousEvents ? 'Previous Events' : 'Upcoming Events'}
      isHeadingWithoutBackgroundOnMobile={true}
    >
      <div className="flex items-start justify-center md:block">
        <Swiper
          grabCursor={true}
          spaceBetween={20}
          id="upcoming-events"
          slidesPerView={getSlidesPerView()}
          className="mx-auto p-2 md:mx-0 lg:max-w-[650px]"
          pagination={{ el: '.swiper-pagination', clickable: true }}
          modules={[Pagination, Navigation]}
        >
          {events.slice(0, 2).map((event) => {
            event = getEventDetails(event.attributes);

            return (
              <SwiperSlide
                key={event?.sessionSlug}
                className="h-auto w-[180px] items-start md:w-[300px]"
              >
                <div className="">
                  <EventCard event={event} />
                </div>
              </SwiperSlide>
            );
          })}

          <div className="slider-controler ">
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </MasterclassSectionLayout>
  ) : null;
}
