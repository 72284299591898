/**
 * OTPPage Component
 *
 * This component handles phone number verification through OTP using the OTPless service.
 * It supports both OTP verification and phone number editing workflows.
 *
 * Features:
 * - OTP verification with 6 digits
 * - Phone number editing
 * - Retry limit (3 attempts)
 * - Resend cooldown timer (15 seconds)
 * - Error handling and success states
 * - Integration with LeadSquared for activity tracking
 */

import React, { useState, useEffect, useContext } from 'react';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '@src/context/GlobalContextProvider';
import MobileIcon from '../../../images/LeadGenForm/mobile-icon.png';
import CrioButton from '@components/v5/CrioButton';
import Cookies from 'js-cookie';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { getCountryCodeAndPhone } from '@src/utils/helper';
import LeadGenerationService from '../../../../utils/lead-generation';
import {
  OTPInput,
  PhoneEditInput,
  useOTPInput,
  useResendTimer,
} from './OTP/utils';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

export default function OTPPage({
  onOTPVerificationSuccess,
  onOTPVerificationError,
  isDark,
}) {
  const { phone, email } = useContext(GlobalStateContext);

  const dispatch = useContext(GlobalDispatchContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const {
    otp,
    inputRefs,
    handleChange,
    handleBackspace,
    resetOTP,
    handleBulkChange,
  } = useOTPInput(6);
  const { showTimer, timeLeft, startTimer } = useResendTimer(15);

  const [OTPlessSignin, setOTPlessSignin] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [OTPLessResult, setOTPLessResult] = useState(null);
  const [retryCount, setRetryCount] = useState(() => {
    const savedCount = Cookies.get('retryCount');
    return savedCount ? parseInt(savedCount, 10) : -1;
  });
  const [editedPhone, setEditedPhone] = useState('');

  const { pathname } = useLocation();

  const isMasterclassRegisterPage = pathname.includes('/masterclass/register');

  const initializeSDK = () => {
    if (window.OTPless) {
      setOTPlessSignin(new window.OTPless(window.otpless));
    }
  };

  /**
   * Initializes the OTPless SDK by injecting the script into the document head
   * Sets up the callback handler for OTPless user data
   */
  useEffect(() => {
    console.log('OTPlessSignin', OTPlessSignin);
  }, [OTPlessSignin]);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'otpless-sdk';
    script.src = 'https://otpless.com/v2/headless.js';
    script.setAttribute('data-appid', 'NMKOH3MB90BPT9IRDN6M');
    script.onload = () => {
      window.otpless = (otplessUser) => {
        console.log(otplessUser);
      };
      initializeSDK();
    };
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById('otpless-sdk');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  /**
   * Monitors retry count and handles exhaustion
   * - Persists retry count in cookies
   * - Triggers exhaustion handling when limit reached
   * - Logs activity to LeadSquared
   */
  useEffect(() => {
    if (retryCount > 0) {
      Cookies.set('retryCount', retryCount.toString(), { expires: 365 });
    }
    if (retryCount >= 3) {
      onRetryCountExhaust();
    }
  }, [retryCount]);

  const onRetryCountExhaust = async () => {
    const leadAndActivityPayload = {
      LeadDetails: [{ Attribute: 'EmailAddress', Value: email }],
      Activity: {
        ActivityEvent: ELeadSquaredActivityCode.OTP_VERIFICATION,
        Fields: [
          { SchemaName: 'mx_Custom_1', Value: '' },
          { SchemaName: 'mx_Custom_2', Value: 'FAILURE' },
          { SchemaName: 'mx_Custom_3', Value: 'OTP Limit Exhausted' },
        ],
      },
    };
    await LeadGenerationService.queueLeadAndActivity(leadAndActivityPayload);
  };

  const handleOTPSubmit = () => {
    const finalOTP = otp.join('');
    if (finalOTP.length === 6) verifyOTP(finalOTP);
  };

  /**
   * Sends OTP to the provided phone number
   * @param {string} phoneNumber - Phone number in international format
   * Increments retry count and handles error states
   * Integrates with OTPless SDK for OTP delivery
   */
  const sendOTP = async (phoneNumber) => {
    if (!OTPlessSignin) return;
    const { countryCode, phoneNum } = getCountryCodeAndPhone(phoneNumber);
    setRetryCount((prev) => prev + 1);

    try {
      const res = await OTPlessSignin.initiate({
        channel: 'PHONE',
        phone: phoneNum,
        countryCode: countryCode,
      });
      console.log('OTP sent successfully:', res);
    } catch (e) {
      setErrorMessage('Failed to send OTP. Please try again.');
    }
  };

  /**
   * Automatically triggers OTP sending when SDK is ready
   * - Checks retry count limit
   * - Adds delay for better UX
   * - Handles initial OTP sending
   */
  useEffect(() => {
    if (OTPlessSignin && retryCount < 3) {
      console.log('Sending OTP');
      setTimeout(() => {
        sendOTP(phone);
      }, 4000);
    }
  }, [OTPlessSignin]);

  /**
   * Verifies the OTP entered by the user
   * @param {string} finalOTP - 6-digit OTP entered by user
   *
   * Workflow:
   * 1. Validates OTP format
   * 2. Calls OTPless verification API
   * 3. Updates verification status in cookies
   * 4. Logs activity to LeadSquared
   * 5. Handles success/error callbacks
   */
  const verifyOTP = async (finalOTP) => {
    if (finalOTP.length === 6) {
      const { countryCode, phoneNum } = getCountryCodeAndPhone(phone);
      const leadAndActivityPayload = {
        LeadDetails: [{ Attribute: 'EmailAddress', Value: email }],
        Activity: {
          ActivityEvent: ELeadSquaredActivityCode.OTP_VERIFICATION,
        },
      };

      try {
        const res = await OTPlessSignin.verify({
          channel: 'PHONE',
          phone: phoneNum,
          otp: finalOTP,
          countryCode: countryCode,
        });

        setOTPLessResult(res);
        const currPhone = localStorage.getItem('oldPhone');

        if (res.success) {
          console.log('OTP verification successful');
          setSuccess(true);
          setError(false);
          setErrorMessage('');
          leadAndActivityPayload.Activity.Fields = [
            { SchemaName: 'mx_Custom_1', Value: finalOTP },
            {
              SchemaName: 'mx_Custom_2',
              Value:
                !currPhone || currPhone === phone
                  ? 'SUCCESS'
                  : 'Verified with new number',
            },
            { SchemaName: 'mx_Custom_3', Value: res.response.verification },
          ];
          localStorage.removeItem('oldPhone');
          Cookies.set('isPhoneVerified', 'true', { expires: 36500 });
          await onOTPVerificationSuccess();
        } else {
          Cookies.set('isPhoneVerified', 'false', { expires: 36500 });
          await onOTPVerificationError();
          setError(true);
          setErrorMessage('The entered OTP seems to be incorrect.');
          leadAndActivityPayload.Activity.Fields = [
            { SchemaName: 'mx_Custom_1', Value: '' },
            { SchemaName: 'mx_Custom_2', Value: 'FAILURE' },
            { SchemaName: 'mx_Custom_3', Value: res.response.errorMessage },
          ];
        }
        await LeadGenerationService.queueLeadAndActivity(
          leadAndActivityPayload,
        );
      } catch (e) {
        console.error('Error verifying OTP:', e);
        setError(true);
        setSuccess(false);
        setErrorMessage('Incorrect OTP entered');
      }
    }
  };

  /**
   * Handles OTP resend requests
   * - Checks retry count limit (max 3 attempts)
   * - Enforces cooldown period (15 seconds)
   * - Resets OTP input fields
   * - Triggers new OTP sending
   */
  const handleResend = () => {
    if (retryCount < 3 && !showTimer) {
      startTimer();
      sendOTP(phone);
      resetOTP();
      setError(false);
      setErrorMessage('');
    }
  };

  /**
   * Processes phone number updates
   * - Resets retry count and OTP state
   * - Updates phone in global context
   * - Triggers new OTP sending to updated number
   */
  const handlePhoneUpdate = () => {
    resetOTP();
    setRetryCount(-1);
    Cookies.set('retryCount', '-1', { expires: 365 });
    setEditPhone(false);
    dispatch({
      type: 'SET_PHONE',
      payload: `+${editedPhone}`,
    });
    sendOTP(`+${editedPhone}`); // Trigger OTP sending
  };

  const handlePhoneEdit = () => {
    setError(false);
    setErrorMessage('');
    setEditPhone(true);
  };

  //Web OTP API for autofill
  useEffect(() => {
    if ('OTPCredential' in window) {
      const abortController = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: abortController.signal,
        })
        .then((otpCredential) => {
          if (otpCredential && otpCredential.code) {
            const receivedOtp = otpCredential.code.match(/\d{6}/); // Extract 6-digit OTP
            if (receivedOtp) {
              const otpArray = receivedOtp[0].split(''); // Split OTP into digits
              handleBulkChange(otpArray); // Use the new bulk update function
            }
          }
        })
        .catch((err) => {
          console.error('Web OTP API Error:', err);
        });

      return () => {
        abortController.abort();
      };
    }
  }, [handleBulkChange]);

  /**
   * UI Components rendered:
   * 1. Phone display/edit section
   * 2. OTP input fields (6 digits)
   * 3. Resend timer and button
   * 4. Error messages
   * 5. Action buttons (Continue/Receive OTP)
   * 6. Retry exhaustion message and sign-in link
   *
   * States handled:
   * - Loading states
   * - Error states
   * - Success states
   * - Edit mode
   * - Timer display
   */
  return (
    <div className="mx-auto flex h-full w-full flex-col items-center justify-evenly p-6 font-manrope">
      <div className="flex h-16 items-center justify-center">
        <img src={MobileIcon} alt="Mobile Icon" className="mb-4 h-16" />
      </div>

      <div className="space-y-1 text-center">
        <p className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
          {editPhone ? (
            <>Enter your Registered Mobile Number</>
          ) : (
            <>
              We've sent you a 6-digit OTP on <strong>{phone}</strong>
            </>
          )}
        </p>
        {editPhone ? (
          <p
            className={`text-xs ${isDark ? 'text-gray-400' : 'text-gray-600'}`}
          >
            You will receive an OTP here shortly
          </p>
        ) : (
          <p
            className={`cursor-pointer text-xs ${
              isDark ? 'text-gray-400' : 'text-gray-600'
            }`}
          >
            Not the right phone number?{' '}
            <button
              onClick={handlePhoneEdit}
              className="text-emerald-600 hover:underline"
            >
              Edit
            </button>
          </p>
        )}
      </div>

      <div className="my-4 flex gap-2">
        {editPhone ? (
          <PhoneEditInput
            error={error}
            onPhoneChange={(value) => setEditedPhone(value)}
          />
        ) : (
          <OTPInput
            otp={otp}
            inputRefs={inputRefs}
            handleChange={handleChange}
            handleBackspace={handleBackspace}
            error={error}
            success={success}
          />
        )}
      </div>

      {!editPhone && (
        <>
          <p
            className={`text-sm ${isDark ? 'text-gray-300' : 'text-gray-600'}`}
          >
            Did not receive OTP?
            <button
              className={`ml-1 mb-4 hover:underline ${
                showTimer || retryCount >= 3
                  ? 'cursor-not-allowed text-gray-400'
                  : 'text-emerald-600'
              }`}
              onClick={handleResend}
              disabled={showTimer || retryCount >= 3}
            >
              Resend
            </button>
          </p>

          {showTimer && (
            <div className="text-lg font-bold text-emerald-600">
              {`00:${timeLeft.toString().padStart(2, '0')}`}
            </div>
          )}
        </>
      )}

      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}

      {editPhone ? (
        <CrioButton
          variant="primary"
          className={`w-full rounded py-3 px-4 font-semibold transition-colors `}
          onClick={handlePhoneUpdate}
        >
          Receive OTP
        </CrioButton>
      ) : (
        <CrioButton
          variant="primary"
          className={`w-full rounded py-3 px-4 font-semibold transition-colors `}
          onClick={handleOTPSubmit}
          disabled={otp.length !== 6}
        >
          Continue
        </CrioButton>
      )}

      {retryCount >= 3 && (
        <div
          className={`mt-4 text-center text-sm ${
            isDark ? 'text-gray-300' : 'text-gray-600'
          }`}
        >
          <p>Maximum number of attempts has been exhausted.</p>
          <p>
            Click here to{' '}
            <button
              onClick={() =>
                navigate(
                  isMasterclassRegisterPage
                    ? '/level-tech-postapp-sd/'
                    : '/registered/v2',
                )
              }
              className="text-emerald-600 hover:underline"
            >
              Sign-In
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
