import {
  filterMasterclassImages,
  sanitizeEventDetails,
} from '@src/utils/Masterclass';
import useGraphqlImage from './useGraphqlImage';
import useResizer from '@components/extra/useResizer';
import masterClassData from '@src/data/masterclass_db.json';
import { getImage } from 'gatsby-plugin-image';
import { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import {
  programTabOptions,
  UPCOMING_EVENTS_SECTION_ID,
} from '@src/constants/masterclass';

export default function useHomePageMetadata() {
  // Determine if the device is mobile
  const isMobile = useResizer();
  // Fetch image nodes using GraphQL
  const { masterclassEventsImgNode } = useGraphqlImage(); // This returns all images available across masterclass pages.
  const state = useContext(GlobalStateContext);
  const leadFormProgramInterested = state.programInterested;
  const [selectedTab, setSelectedTab] = useState(programTabOptions[0].id);

  // Filter out event-specific images to get only general images
  const filteredMasterclassImages = filterMasterclassImages(
    masterclassEventsImgNode?.Thumbnail?.nodes,
  );

  // FUNCTIONS:
  const getImageFromKeyWord = (keyword) => {
    const sortedImages = filteredMasterclassImages.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    // Filter images based on the keyword and exclude those with 'large' in the name to maintain image quality.
    const imgNode = sortedImages.find(
      (node) =>
        node.name.toLowerCase().includes(keyword) &&
        !node.name.toLowerCase().includes('large'),
    );

    return imgNode;
  };

  // Get the hero section image
  const getHeroSectionImg = () => {
    const keyword = isMobile
      ? 'hero_section_image_mobile'
      : 'hero_section_image';
    const imgNode = getImageFromKeyWord(keyword);
    return getImage(imgNode?.childImageSharp);
  };

  // Get the hero section image for SEO
  const getHeroSectionImgSeo = () => {
    const keyword = 'hero_section_image_seo';
    const imgNode = getImageFromKeyWord(keyword);
    return imgNode?.relativePath.split('/').pop();
  };

  // Get the hero section content
  const getHeroSectionContent = () => {
    return {
      title:
        masterClassData?.strapi_metadata?.masterClassDetails?.[0]?.attributes
          ?.landingPageDetails?.heroTitle,
      subTitle:
        masterClassData?.strapi_metadata?.masterClassDetails?.[0]?.attributes
          ?.landingPageDetails?.heroSubtitle,
    };
  };

  // Get the event list
  const getEventList = () => {
    const strapiEventList = masterClassData?.strapi_metadata?.masterClassEvents;

    if (!strapiEventList) {
      return [];
    }

    const sanitizedEventList = strapiEventList.map((event) => {
      // Find the corresponding image node for the event
      const allMasterclassImg = masterclassEventsImgNode?.Thumbnail?.nodes;
      return sanitizeEventDetails(event?.attributes, allMasterclassImg);
    });

    return sanitizedEventList;
  };

  // Check if the lead generation form is enabled from strapi
  const isLeadGenFormEnabledFromStrapi = () => {
    return masterClassData?.strapi_metadata?.masterClassDetails?.[0]?.attributes
      ?.landingPageDetails?.isLeadGenFormEnabled;
  };

  //Handle tab click:
  const handleTabClick = (id) => {
    setSelectedTab(id);
  };

  const onSubmitOtp = () => {
    const upcomingEventsElement = document.getElementById(
      UPCOMING_EVENTS_SECTION_ID,
    );
    if (upcomingEventsElement) {
      upcomingEventsElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Based on program interested selected in lead gen form, set the selected tab to that program
  useEffect(() => {
    setSelectedTab(
      leadFormProgramInterested
        ? leadFormProgramInterested
        : programTabOptions[0].id,
    );
  }, [leadFormProgramInterested]);

  return {
    getHeroSectionImg,
    getHeroSectionContent,
    getEventList,
    isLeadGenFormEnabledFromStrapi,
    selectedTab,
    onSubmitOtp,
    handleTabClick,
    getHeroSectionImgSeo,
  };
}
