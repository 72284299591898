export const calculateSessionEndTime = (sessionStartDateTime) => {
  const startDate = new Date(sessionStartDateTime);
  if (isNaN(startDate.getTime())) {
    return '';
  }
  const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000);
  return endDate.toISOString();
};

export const generateTitle = (sessionName, isPastEvent) => {
  return `Register for ${sessionName} -  ${
    isPastEvent ? 'Upcoming' : ''
  } Masterclass by Crio.Do`;
};

export const generateMetaDescription = (sessionName, program, isPastEvent) => {
  return `Join our ${
    isPastEvent ? '' : 'upcoming '
  }${sessionName} masterclass with Crio. Learn from experts in ${program} and advance your skills in a hands-on session. Earn a certification post the session completion. Limited seats available, register now!`;
};

export const generateMeta = (eventCardSessionImage, eventSlug) => {
  return [
    {
      property: `og:image`,
      content: `https://strapi.internal.crio.do/uploads/${eventCardSessionImage}`,
    },
    {
      property: 'og:url',
      content: `https://www.crio.do/masterclass/register/${eventSlug}`,
    },
    {
      name: `twitter:image`,
      content: `https://strapi.internal.crio.do/uploads/${eventCardSessionImage}`,
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ];
};

export const generateScript = (
  sessionName,
  isPastEvent,
  program,
  sessionStartDateTime,
  sessionEndTime,
  eventSlug,
  eventCardSessionImage,
  createdAt,
  sessionHostName,
) => {
  const script = `{
        "@context": "https://schema.org",
        "@type": "Event",
        "name": "${sessionName} - Free Masterclass",
        "description": "Join our ${
          isPastEvent ? '' : 'upcoming'
        } ${sessionName} masterclass with Crio. This is a free, hands-on learning session led by industry experts in ${program}. Enhance your skills, gain practical knowledge, and earn a certificate upon completion. Limited seats available, register now to secure your spot!",
        "startDate": "${sessionStartDateTime}", 
        "endDate": "${sessionEndTime}", 
        "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "location": {
          "@type": "VirtualLocation",
          "url": "https://www.crio.do/masterclass/register/${eventSlug}"
        },
        "image": "https://strapi.internal.crio.do/uploads/${eventCardSessionImage}",
        "offers": {
          "@type": "Offer",
          "url": "https://www.crio.do/masterclass/register/${eventSlug}",
          "price": "0",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock",
          "validFrom": "${createdAt}",
          "category": "Free"
        },
         "performer": {
          "@type": "Person",
          "name": "${sessionHostName}",
          "description": "${sessionHostName} is a seasoned industry expert with extensive experience in ${program}. They have a passion for teaching and sharing knowledge in ${program}."
        },
        "organizer": {
          "@type": "Organization",
          "name": "Crio",
          "url": "https://www.crio.do",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.crio.do/images/logo.png",
            "width": 250,
            "height": 60
          },
          "sameAs": [
            "https://www.facebook.com/criodo",
            "https://twitter.com/crio_do",
            "https://www.instagram.com/crio.do/",
            "https://www.youtube.com/@CrioDo",
            "https://www.linkedin.com/company/criodo/"
          ]
        }
      }`;
  return script;
};
