import React from 'react';
import DeliverableTile from './DeliverableTile/index';
import { Link } from 'gatsby';
import CrioButton from '@components/v5/CrioButton/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_PROGRAM_CARD,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_PROGRAM_ID_QA,
  LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS,
} from '@src/constants/LeadGenerationConstants/index';
import { EProgramIDs } from '@src/constants/data/programs/index';

export default function AsideSection({ deliverables, type }) {
  const route =
    type === EProgramIDs.FELLOWSHIP
      ? '/software-development-fellowship-program'
      : type === EProgramIDs.DATA_ANALYTICS
      ? '/ai-data-analytics'
      : '/qa-automation-testing';

  return (
    <div className="col-span-1 flex w-full flex-col justify-evenly gap-4 lg:col-span-2">
      <div className="flex w-full flex-col gap-4">
        {deliverables.map((deliverable) => (
          <DeliverableTile deliverable={deliverable} />
        ))}
      </div>

      <div className="flex flex-col gap-4">
        <Link to={route} className="w-full">
          <CrioButton
            variant="secondary"
            className="w-full border-white text-white"
          >
            Learn More
          </CrioButton>
        </Link>

        <div className="w-full">
          <LeadGenerationButton
            text="Apply Now"
            buttonLocation={LEAD_GEN_PROGRAM_CARD}
            programType={
              route === '/qa-automation-testing'
                ? LEAD_GEN_PROGRAM_ID_QA
                : route === '/ai-data-analytics'
                ? LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS
                : LEAD_GEN_PROGRAM_ID_FULL_STACK
            }
          />
        </div>
      </div>
    </div>
  );
}
