import React from 'react';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebEngage } from '@src/hooks/useWebEngage';

const SaleTopStrip = ({ program }) => {
  const { captureUserClickEvent } = useWebEngage();

  const backgroundColor =
    program === EProgramIDs.FELLOWSHIP ||
    program === EProgramIDs.QA_AUTOMATION_TESTING
      ? 'bg-sale-orange-400'
      : 'bg-sale-blue-300';

  const textColor =
    program === EProgramIDs.FELLOWSHIP ||
    program === EProgramIDs.QA_AUTOMATION_TESTING
      ? 'text-black'
      : 'text-white';

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'SALE_TOP_STRIP',
    });
  };

  return (
    <div
      className={`${backgroundColor} ${textColor} flex h-[44px] w-full items-center px-2 text-sm sm:text-base md:h-[40px] md:px-6`}
    >
      <div className="flex-1 text-center">
        <p className="">
          <span className="font-rubik">
            Find out where Crio grads get placed.
          </span>{' '}
          <br className="block md:hidden" />
          <a
            href="/placements"
            className="font-rubik font-bold underline"
            onClick={onPlacementButtonClick}
          >
            Get our Placement Stats{' '}
            <span className="ml-2">
              <FontAwesomeIcon icon={['fas', 'angle-right']}></FontAwesomeIcon>
            </span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default SaleTopStrip;
