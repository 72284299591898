import CertGenFooter from '@components/CertGen/CertGenFooter/index';
import CertGenProjectsSection from '@components/CertGen/CertGenHero/CertGenProjectsSection';
import CertGenHero from '@components/CertGen/CertGenHero/index';
import CertGenNavigation from '@components/CertGen/CertGenNavigation/index';
import CertGenProgramsSection from '@components/CertGen/CertGenProgramsSection';
import SEO from '@components/Common/SEO/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection';
import React from 'react';

export default function Certificate(props) {
  const { slug, programType, VideoTitle, VideoDescription } =
    props.pageContext?.pageData;

  return (
    <>
      <SEO title="Certificate Share" />
      <CertGenNavigation />
      <CertGenHero
        VideoTitle={VideoTitle}
        VideoDescription={VideoDescription}
        program={slug}
      />
      <CertGenProgramsSection />
      <VideoTestimonialSection
        isTypeQA={false}
        heading={
          'Hear from some of our learners who took their careers to new heights'
        }
        headingClassName={'text-[42px]'}
        description={''}
        disableGreenBanner={true}
        disableCareerHandbook={true}
      />
      <div className="bg-gradient-to-b from-transparent to-[#2DF8C5] pb-20">
        <CertGenProjectsSection programType={programType} />
      </div>

      <CertGenFooter />
    </>
  );
}
