import React from 'react';
import RawHTML from '@components/Common/RawHTML/index';

const StripContent = ({ content, stripContentColor }) => {
  const textColor = stripContentColor || '#000000';
  return (
    <div style={{ color: textColor }}>
      <RawHTML>{content}</RawHTML>
    </div>
  );
};

export default StripContent;
