// extracted by mini-css-extract-plugin
export var active = "FellowshipProgramCard-module--active--04b17";
export var banner = "FellowshipProgramCard-module--banner--750b7";
export var careerFooter = "FellowshipProgramCard-module--careerFooter--66c11";
export var customFont = "FellowshipProgramCard-module--customFont--244f8";
export var emailForm = "FellowshipProgramCard-module--emailForm--eb043";
export var fontInter = "FellowshipProgramCard-module--fontInter--83a02";
export var innerDot = "FellowshipProgramCard-module--innerDot--594e8";
export var innerDotActive = "FellowshipProgramCard-module--innerDotActive--09769";
export var inputControl = "FellowshipProgramCard-module--inputControl--748d5";
export var line = "FellowshipProgramCard-module--line--3cd38";
export var milestoneCard = "FellowshipProgramCard-module--milestoneCard--8ae60";
export var programCard = "FellowshipProgramCard-module--programCard--e7983";
export var skill = "FellowshipProgramCard-module--skill--1736a";
export var toggle = "FellowshipProgramCard-module--toggle--c20f3";
export var toggleButton = "FellowshipProgramCard-module--toggleButton--9904c";
export var toggleMobile = "FellowshipProgramCard-module--toggleMobile--4c9ba";