import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';
import InfoCard from '../InfoCard/index';
import RawHTML from '@components/Common/RawHTML/index';

const WhyCrioSection = React.memo(function WhyCrioSection(props) {
  return (
    <SectionLayout
      heading={'An All-round Effective Way to Supercharge your Tech Career'}
      description={
        <>
          <strong className="font-bold text-black">
            Hands-on, work experience-based learning
          </strong>{' '}
          is at the heart of everything you do at Crio. Build software projects
          like the top 1% Tech Professionals and{' '}
          <strong className="font-bold text-black">learn all the skills</strong>{' '}
          you need to{' '}
          <strong className="font-bold text-black">land the best</strong>{' '}
          Fullstack/Backend Developers or SDET Jobs.
        </>
      }
      {...props}
    >
      <div className="container relative z-[999]">
        {props.quote && (
          <div className="my-4 mb-12 flex w-full justify-center ">
            <div className="w-max rounded-[30px] bg-v5-green-600 p-10 text-center font-manrope leading-relaxed text-v5-green-200">
              <h5 className="relative">
                <RawHTML className="z-10 capitalize">{props.quote}</RawHTML>
                <div className="absolute left-2 -top-3 z-0">
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/quoteLeft.png"
                    placeholder="blurred"
                    alt="QuoteLeft"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
                <div className="absolute -right-4 -bottom-4 z-0">
                  <StaticImage
                    width={40}
                    loading="lazy"
                    src="../../../images/v5/quoteRight.png"
                    placeholder="blurred"
                    alt="QuoteLeft"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
              </h5>
            </div>
          </div>
        )}
        <div className="flex flex-wrap justify-center gap-x-4 gap-y-4">
          {(props.data || data).map((ele, idx) => (
            <InfoCard
              gradient={props.gradient}
              dark={props.dark}
              key={idx}
              icon={ele.image}
              title={ele.title}
              description={ele.description}
              fellowship={ele.fellowship}
            ></InfoCard>
          ))}
        </div>
      </div>
    </SectionLayout>
  );
});

export default WhyCrioSection;

let data = [
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointOne.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Learn by Working like<br></br>Tech Professionals
      </>
    ),
    description:
      'Learn Full Stack Development, Backend Development, QA Automation through an immersive project-based curriculum focused on practical developer skills and real-world scenarios.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointTwo.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Gain Work-ex on Problems<br></br>Given by Real Tech Companies
      </>
    ),
    description:
      'Gain the work experience of building professional software products for India’s top tech companies like CRED, Playment, Jumbotail, Vicara, and others.',
    fellowship: true,
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointThree.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: 'Learn From Seasoned Industry Professionals in Real Time',
    description:
      'Participate in activity-based live working sessions facilitated by industry mentors from companies like Microsoft, Flipkart, Nilenso.',
  },

  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointFour.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Get career support to break<br></br>through into ambitious roles
      </>
    ),
    description:
      'Be prepared for every interview and land exciting development jobs with structured planning and personalised guidance & support from Crio career coaches.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointFive.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Build a GitHub portfolio<br></br> that impresses recruiters
      </>
    ),
    description:
      'Go beyond just certificates with a Crio verified project portfolio on GitHub and impress any recruiter at a product based company with your skills and experience.',
  },
  {
    image: (
      <StaticImage
        width={200}
        loading="lazy"
        src="../../../images/v5/USP/PointSix.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),

    title: (
      <>
        Stand out at interviews <br></br>with real proof of work
      </>
    ),
    description:
      'Validate your learnings and let your skills shine with a work experience certificate that makes you stand out at interviews.',
  },
];
