import Button from '@components/Button/index';
// const endpointURL = config.backendUrl;
// import HeaderDropdown from "./HeaderDropdown";
import useOuterClick from '@components/useOuterClick/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globalHistory as history } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './Header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

const FellowshipHeader = (props) => {
  const [dropdown, setDropdown] = useState(null);
  const [mobileNavActive, setmobileNavActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { location, navigate } = history;

  useEffect(() => {
    // setScrollTop(window.pageYOffset);
    if (window.pageYOffset > 50) {
      setTimeout(() => {
        setScrolled(true);
      }, 1);
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  const innerRef = useOuterClick((ev) => {
    if (dropdown != null) setDropdown(null);
  });

  const items = [
    {
      name: 'FAQs',
      link: '#faqs',
    },
    {
      name: 'Testimonials',
      link: '#testimonials',
    },
    {
      name: 'Scholarships',
      link: '#scholarships',
    },
    {
      name: 'Program Details',
      link: '#program-details',
    },

    {
      name: 'Why Crio',
      link: '#why-crio',
    },
  ];

  function onScroll() {
    // setScrollTop(window.pageYOffset);
    if (window.pageYOffset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }
  function handleLogin() {
    let temp = window.location.hostname;
    temp = temp.replace('www.', '');
    window.location = 'https://learn.' + temp;
  }

  function getDropdown(dropdown) {
    switch (dropdown) {
      case 'programs':
        return (
          <div
            ref={innerRef}
            className="programs dropdown"
            onMouseLeave={() => {
              setDropdown(null);
            }}
          >
            <Link to="/full-stack-developer-track">
              <div className={'programItem'}>
                <h6 className="mb-1">Full-Stack Web Developer Program</h6>
                <p className="bodyText2 " style={{ color: '#7A7D81' }}>
                  Become a skilled Full-Stack developer with hands-on experience
                  in Javascript and MERN stack.
                </p>
              </div>{' '}
            </Link>
            <hr></hr>
            <Link to="/backend-developer-track">
              <div className={`programItem pt-3`}>
                <h6 className="mb-1">Backend Web Developer Program</h6>
                <p className="bodyText2" style={{ color: '#7A7D81' }}>
                  Become a skilled Backend developer with hands-on experience in
                  building a scalable web backend.
                </p>
              </div>
            </Link>
          </div>
        );
      case 'business':
        return (
          <div
            ref={innerRef}
            className="business dropdown"
            onMouseLeave={() => {
              setDropdown(null);
            }}
          >
            <Link to="/recruit">
              <div className={`programItem pt-3 pb-2`}>
                <h6>Crio Recruit</h6>
              </div>
            </Link>
          </div>
        );
    }
  }

  return (
    <>
      <div className={styles.customFont}>
        <div
          className={`navbar subtitle1 scrolled flex items-center justify-between md:px-3 lg:px-6`}
          onMouseLeave={() => {
            setDropdown(null);
          }}
        >
          <Link to="/">
            <div
              className={styles.logo}
              onClick={() => {
                setTimeout(
                  () => window.scrollTo({ top: 0, behavior: 'smooth' }),
                  200,
                );
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../../images/Crio_Dark.png"
                placeholder="blurred"
                alt="Crio.Do"
              />
              {/* <Image filename={'Crio_Dark.png'}></Image> */}
            </div>
          </Link>
          <div className="flex flex-row-reverse items-center">
            <div className="mx-4 block md:mr-0 xl:hidden">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={['fas', 'bars']}
                color="#000"
                size="lg"
                onClick={() => {
                  setmobileNavActive(true);
                }}
              />
            </div>

            <div
              style={{ position: 'relative' }}
              className="mr-3 flex flex-row-reverse items-center"
            >
              <Button
                onClick={() => {
                  navigate('/registration', {
                    state: {
                      link: props.typeform,
                      type: props.type,
                      location: 'HEADER',
                      program: props.program,
                    },
                  });
                }}
                // color="#fff"
                className="sm  shimmer-btn ml-2 hidden items-center bg-crio-yellow-500 text-crio-green-900 md:flex"
                type="primary"
                style={{
                  background:
                    'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                }}
              >
                Book Your Trial
                <FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="ml-2 rotate-45"
                  style={{ marginBottom: '2px' }}
                ></FontAwesomeIcon>
              </Button>{' '}
              {items
                .filter((item) => !props.disable?.includes(item.name))
                .map((ele, idx) => (
                  <a href={ele.link ? ele.link : undefined} key={idx}>
                    <div
                      style={{ position: 'relative' }}
                      onClick={ele.onClick}
                      onMouseOver={ele.onMouseOver}
                      className={`mx-4 hidden cursor-pointer  items-center
             font-semibold hover:text-gray-500 md:block
          `}
                    >
                      <div className=" whitespace-nowrap">{ele.name}</div>
                    </div>
                  </a>
                ))}
              {getDropdown(dropdown)}
            </div>
          </div>
        </div>
        <AnimatePresence>
          {mobileNavActive && (
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5, type: 'tween', stiffness: 100 }}
              exit={{ y: 1000 }}
              className={`${styles.mobileNavbar} bg-crio-green-800`}
            >
              <section>
                <div className="absolute bottom-0 right-0 w-5/12">
                  <StaticImage
                    loading="lazy"
                    src="../../../images/home/menu_mobile.png"
                    placeholder="blurred"
                    alt="Crio.Do"
                  />
                  {/* <Image filename="home/menu_mobile.png"></Image> */}
                </div>
                <div className="inset-center  absolute flex flex-col-reverse items-center text-center">
                  <Button
                    // color="#fff"
                    onClick={() => {
                      navigate('/registration', {
                        state: {
                          link: props.typeform,
                          type: props.type,
                          location: 'HEADER',
                          program: props.program,
                        },
                      });
                    }}
                    className="md  shimmer-btn mt-2 flex items-center bg-crio-yellow-500 text-crio-green-900 md:hidden"
                    type="primary"
                    style={{
                      background:
                        'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                    }}
                  >
                    Book Your Trial
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className="ml-2 rotate-45"
                      style={{ marginBottom: '2px' }}
                    ></FontAwesomeIcon>
                  </Button>{' '}
                  {items
                    .filter((item) => !props.disable?.includes(item.name))
                    .map((ele, idx) => (
                      <a
                        href={ele.link ? ele.link : undefined}
                        key={idx}
                        onClick={() => {
                          setmobileNavActive(false);
                        }}
                      >
                        <div
                          className={`mx-3 my-5 flex cursor-pointer  items-center
              font-normal text-white`}
                        >
                          <div>
                            <div
                              className={`flex items-center justify-center `}
                            >
                              <h5
                                className={`whitespace-nowrap text-2xl font-light`}
                              >
                                {ele.name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}{' '}
                  <FontAwesomeIcon
                    className="mb-5 cursor-pointer"
                    icon={['fal', 'times']}
                    size="2x"
                    color="#fff"
                    onClick={() => {
                      setmobileNavActive(false);
                    }}
                  />
                </div>
              </section>{' '}
              {/* <div className="mobile-mascot">
                <Image filename="Menu.png"></Image>
              </div> */}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <style>
        {`
       
        .navbar {
    
        // position: fixed !important;
        left: 0;
        right: 0;
        background-color: transparent;
        z-index: 3;
        padding: 5px 0px;
        box-sizing: border-box;
        height:65px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
      }
      .cross{
        position:absolute;
        right:30px;
      }
      .navbar.scrolled {
        -webkit-backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        background-color: #fff;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
      }
     
      .dropdown{
      
        position: absolute;
        top: 48px;
        border-radius: 5px;
        margin-left: 16px;
        background-color: #fff;
        padding: 20px 20px 10px 20px;
        text-align: left;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
        left:0px;
      }
      .dropdown.business{left:120px;}
      .mobile-mascot
      {
        position:absolute;
        bottom:0px;
        // transform: translate3d(50%,-25%,0);
        width:50%;
        z-index:0;
      }
      .programItem {
     
        color: #000;
        padding-bottom: 20px;
      }
      .programItem:hover {
        color: #7bba8c;
      }
      `}
      </style>
    </>
  );
};
export default FellowshipHeader;
