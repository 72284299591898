import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@mui/material';
import React from 'react';
import WhatsappIcon from '@src/images/whatsapp-icon.svg';
import WhatsappMobileIcon from '@src/images/whatsapp-icon-mobile.svg';
import SmsIcon from '@src/images/sms-icon.svg';
import { useLocation } from '@reach/router';
import { getClipedHref } from '@src/utils/Masterclass';
import CopyButton from './CopyButton';
import SocialShareIcon from '@components/SocialShareIcon';
import useRegisterMetadata from '@src/hooks/Masterclass/useRegisterMetadata';

export default function ShareDialog({ closeDialog }) {
  const { getEventDetails } = useRegisterMetadata();
  const location = useLocation();
  const shareInfo = getEventDetails()?.shareInfo;

  return (
    <div className="flex flex-col bg-v5-neutral-200 p-[24px] md:p-[32px]">
      {/* Share Dialog Header */}
      <div className="flex items-center justify-between gap-[42px]">
        <div className="font-manrope text-[10px] font-bold leading-normal md:text-[24px]">
          Invite Your Friends To The Masterclass!
        </div>
        <FontAwesomeIcon
          icon={faX}
          onClick={closeDialog}
          className="cursor-pointer text-[10px] lg:text-[20px]"
        />
      </div>

      {/* Divider to separate Dialog Header and Body */}
      <Divider className="mt-[6px] text-v5-neutral-400 md:mt-[20px]" />

      <div className="mt-[20px] flex flex-col items-center gap-5 md:mt-[42px] md:flex-row">
        <div className="flex h-[50px] gap-6">
          <SocialShareIcon
            Icon={WhatsappIcon}
            MobileIcon={WhatsappMobileIcon}
            className="gap-1"
            contentToShare={`${shareInfo.whatsappMessage} ${location.href}`}
            label="WhatsApp"
            shareUrl="https://wa.me/?text="
          />
          <SocialShareIcon
            MobileIcon={SmsIcon}
            label="SMS"
            contentToShare={`${shareInfo.smsMessage} ${location.href}`}
            shareUrl="sms:?&body="
            iconClassName="relative top-1"
          />
        </div>
        <div className="flex w-full gap-3">
          <div className="flex w-full flex-1 items-center justify-between truncate rounded-[4px] border border-v5-neutral-300 bg-white py-[6px] px-3 font-rubik text-[8px] md:h-[55px] md:rounded-[8px] md:text-[14px]">
            {getClipedHref(location.href)}
            <CopyButton textToCopy={location.href} className="hidden md:flex" />
          </div>
          <CopyButton textToCopy={location.href} className="md:hidden" />
        </div>
      </div>
    </div>
  );
}
