import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { useLocation } from '@reach/router';
import { EPpcRoutes } from '@src/constants/routes/index';
import ReusableTopStrip from './ReusableTopStrip/index';
// import useSale from '@src/hooks/useSale';
import useTopStripData from '@src/hooks/useTopStripData';

const UrgencyTopStrip = () => {
  const location = useLocation();
  const isFullStackPpcVl = location.pathname.includes(
    EPpcRoutes.FULL_STACK_PPC_ADS_VL,
  );

  //Get top strip data for current page
  const top_strip_data = useTopStripData();
  const top_strip_page = top_strip_data?.Strip.filter((st) =>
    st?.top_strip_pages?.data?.some(
      (page) => page?.attributes?.PagePath === location.pathname,
    ),
  );

  // const { sale } = useSale();

  return (
    <LeadGenerationButton
      buttonLocation="URGENCY_TOP_STRIP"
      formHeading={'Book your Slot'}
      pageOneButtonText={'Register Now'}
      pageTwoButtonText={'Register Now'}
      type={'HiddenButton'}
      isFullStackPpcVl={isFullStackPpcVl}
    >
      {/* <ConditionalTopStrip
        campaign={sale ? 'independenceDay' : ''}
        leftIcon={false ? 'flag-left' : ''}
        rightIcon={false ? 'flag-right' : ''}
      /> */}
      <ReusableTopStrip
        stripData={top_strip_page[0]}
        isActiveStrip={top_strip_data?.isActiveStrip}
        isStrip={top_strip_page.length > 0}
      />
    </LeadGenerationButton>
  );
};

/*
"Unlock your career potential in 2024 & avail exclusive benefits up to ₹1,20,000 | <> Seats Left"
*/
export default UrgencyTopStrip;
