import {
  HOME_PAGE_CANONICAL_URL,
  HOME_PAGE_SCRIPT,
  HOME_PAGE_SEO_DESCRIPTION,
  HOME_PAGE_SEO_TITLE,
} from '@src/constants/masterclass/seo';
import useHomePageMetadata from './useHomePageMetadata';
import { generateMeta } from '@src/utils/Masterclass/homePageSeo';
import React from 'react';

export default function useMasterclassHomePageSeo() {
  const { getHeroSectionImgSeo } = useHomePageMetadata();
  const heroSectionImgSeo = getHeroSectionImgSeo();

  return {
    title: HOME_PAGE_SEO_TITLE,
    metaDescription: HOME_PAGE_SEO_DESCRIPTION,
    meta: generateMeta(heroSectionImgSeo),
    canonicalUrl: HOME_PAGE_CANONICAL_URL,
    script: <script type="application/ld+json">{HOME_PAGE_SCRIPT}</script>,
  };
}
