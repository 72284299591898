import React, { useRef, useState } from 'react';
import MenuHeading from './MenuHeading/index';
import { ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import MenuLinks from '../../MenuLinks/index';

/**
 * MenuPopover component renders a popover menu.
 *
 * @param {Object} props - The props passed to the component.
 * @param {string} props.name - The name of the menu.
 * @param {Array} props.programLinks - Array of links for programs.
 * @param {Array} props.placementLinks - Array of links for placements.
 * @returns {JSX.Element} - Returns the JSX for the MenuPopover component.
 */
const MenuPopover = ({ name, linksArray }) => {
  // Create a reference for the menu
  const ref = useRef(null);
  // State to manage the menu state(open or close)
  const [state, setState] = useState();

  return (
    <>
      {/* Render the menu heading */}
      <MenuHeading name={name} setState={setState} ref={ref} />

      <ControlledMenu
        state={state}
        anchorRef={ref}
        onMouseLeave={() => setState('closed')}
        onClose={() => setState('closed')}
        className="menuItem"
        menuStyles={{
          borderRadius: '15px',
          backgroundColor: '#F2FBF7',
          marginTop: '0.5rem',
          maxWidth: '450px',
        }}
      >
        {/* Render menu links based on name */}
        <MenuLinks linksArray={linksArray} />
      </ControlledMenu>
    </>
  );
};

export default MenuPopover;
