import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { EScholarship } from '@src/constants/sale/index';
import { EProgramIDs } from '@src/constants/data/programs/index';

export const ProgramCardFooter = () => {
  return (
    <div className="relative mb-[60px] flex h-[130px] items-start justify-center rounded-b-[20px] bg-sale-blue-300 md:h-[150px]">
      <div className="mx-2 mt-6 text-center font-manrope text-white">
        <h2 className="font-rubik text-base font-bold italic text-sale-orange-100 md:text-lg">
          #AddProjectsToResume & crack interviews.
        </h2>
        <p className="text-sm font-black">
          Get a Rs.{EScholarship[EProgramIDs.FULL_STACK]} scholarship after your
          free trial.
          <br />
          <span className="text-xs font-normal">Limited Time Offer.</span>
        </p>
      </div>
      <div className="absolute -bottom-[39px] left-1/2 h-[78px] w-[78px] -translate-x-1/2">
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Sale/Card-Motif.png"
          placeholder="none"
          alt="Sale Motif"
          width={180}
        />
      </div>
    </div>
  );
};

export const FellowshipProgramCardFooter = ({ program }) => {
  return (
    <>
      <div className="relative mb-[60px] flex h-[130px] items-start justify-center rounded-b-[20px] bg-sale-blue-300 md:hidden  md:h-[150px]">
        <div className="mx-2 mt-6 text-center font-manrope text-white">
          <h2 className="font-rubik text-base font-bold italic text-sale-orange-100 md:text-lg">
            #AddProjectsToResume & crack interviews.
          </h2>
          <p className="text-sm font-black">
            Get a Rs.{EScholarship[program]} scholarship after your free trial.
            <br />
            <span className="text-xs font-normal">Limited Time Offer.</span>
          </p>
        </div>
        <div className="absolute -bottom-[39px] left-1/2 h-[78px] w-[78px] -translate-x-1/2">
          <StaticImage
            loading="lazy"
            src="../../../../images/v5/Sale/Card-Motif.png"
            placeholder="none"
            alt="Sale Motif"
            width={180}
          />
        </div>
      </div>
      {/* DESKTOP */}
      <div className="relative mb-[60px] hidden h-[99px] rounded-b-[20px] bg-sale-blue-300 md:flex">
        <div className="absolute top-0 left-0 w-[116px]">
          <StaticImage
            loading="lazy"
            src="../../../../images/v5/Sale/FellowshipCardCreative.png"
            placeholder="none"
            alt="BVS"
            width={230}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="z-10 mx-2 mt-6 flex w-full justify-between text-center font-manrope text-white">
          <h2 className="ml-12 text-left font-rubik text-xl font-bold italic text-sale-orange-100">
            #AddProjectsToResume <br />& crack interviews.
          </h2>
          <p className="mr-4 text-right text-base font-black">
            Get a Rs.{EScholarship[program]} scholarship after your free trial.
            <br />
            <span className="text-sm font-normal">Limited Time Offer.</span>
          </p>
        </div>
      </div>
    </>
  );
};

export const SaleHomePageHeroImage = (independenceSale) => {
  return (
    <>
      <div className="relative mt-4 md:hidden">
        <StaticImage
          loading="eager"
          src="../../../../images/v5/Sale/Hero.png"
          placeholder="none"
          alt="Hero Image"
          width={350}
          imgStyle={{ objectFit: 'contain' }}
          className={`w-full md:hidden ${independenceSale ? 'mt-10' : ''}`}
        />
        <div
          className={`absolute w-[120px] ${
            independenceSale
              ? '-top-[8%] right-[15%]'
              : '-top-6 right-4 rotate-12'
          }`}
        >
          <StaticImage
            loading="eager"
            src="../../../../images/v5/Sale/All.png"
            placeholder="none"
            alt="Sale Badge"
            width={200}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="relative hidden md:hidden lg:block">
        <StaticImage
          loading="eager"
          src="../../../../images/v5/Sale/Hero.png"
          placeholder="none"
          alt="Hero Image"
          width={500}
          imgStyle={{ objectFit: 'contain' }}
        />
        <div
          className={`absolute ${
            independenceSale
              ? 'top-[50%] left-5 w-[140px] -rotate-2'
              : 'top-1/3 -left-1/4 w-[160px] -rotate-12'
          }`}
        >
          <StaticImage
            loading="eager"
            src="../../../../images/v5/Sale/All.png"
            placeholder="none"
            alt="Sale Badge"
            width={300}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </>
  );
};

export const SaleFellowshipHeroImage = ({
  independenceSale,
  image,
  desktopImage,
}) => {
  return (
    <>
      <div className={`relative md:hidden ${independenceSale ? 'pt-7' : ''}`}>
        {image}
        <div
          className={`absolute w-[150px] ${
            independenceSale ? '-top-0 rotate-6' : '-top-4 -right-4 rotate-6'
          }`}
        >
          <StaticImage
            loading="eager"
            sec="../../../../images/v5/Sale/32K.png"
            placeholder="none"
            alt="Sale Badge"
            width={200}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
      <div className="relative hidden md:block">
        <div className="xl:max-w-[500px]">{desktopImage}</div>
        <div
          className={`absolute w-[150px] ${
            independenceSale ? '-bottom-2 ' : '-bottom-6 left-6 -rotate-6'
          } `}
        >
          <StaticImage
            loading="eager"
            sec="../../../../images/v5/Sale/32K.png"
            placeholder="none"
            alt="Sale Badge"
            width={300}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </>
  );
};

export const SaleQAHeroImage = ({
  independenceSalePageType,
  image,
  desktopImage,
}) => {
  const getBadgeStyle = (device) => {
    if (independenceSalePageType === 'QA') {
      if (device === 'Mobile') {
        return 'top-[45%] w-[120px] -left-2';
      } else {
        return 'bottom-7';
      }
    } else if (independenceSalePageType === 'FDT') {
      if (device === 'Mobile') {
        return '-right-2 -top-8';
      } else {
        return 'right-0 top-3';
      }
    } else {
      if (device === 'Mobile') {
        return 'bottom-0 w-[90px] left-0 -rotate-6';
      } else {
        return 'rotate-12 xl:-top-6 xl:right-20 2xl:-top-4 2xl:right-28';
      }
    }
  };

  return (
    <>
      <div className="relative md:hidden">
        {image}
        <div className={`absolute ${getBadgeStyle('Mobile')}`}>
          <StaticImage
            loading="eager"
            src="../../../../images/v5/Sale/32K.png"
            placeholder="none"
            alt="Sale Badge"
            width={180}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
      <div className="relative hidden md:block">
        <div className="xl:max-w-[500px]">{desktopImage}</div>
        <div className={`absolute md:w-[140px] ${getBadgeStyle('Desktop')}`}>
          <StaticImage
            loading="eager"
            src="../../../../images/v5/Sale/32K.png"
            placeholder="none"
            alt="Sale Badge"
            width={400}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      </div>
    </>
  );
};
