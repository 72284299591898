import React from 'react';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CTA_Arrow from '../../../images/lister/CTA_Arrow.svg';
import GeekTrust_CTA_Arrow from '../../../images/geektrust/CTA_Arrow.svg';

export default function CompanyEventCTAButton({
  className,
  onClick,
  ...props
}) {
  const getButton = (secondaryType, className, props) => {
    switch (secondaryType) {
      case 'PHONEPE':
        return (
          <Button
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            type="submit"
            variant="contained"
            style={{
              borderRadius: '92px',
              height: '45px',
            }}
            className={`group flex items-center gap-x-2 whitespace-nowrap bg-phonepe-accent-300 px-14 font-uni-neue font-bold capitalize text-phonepe-primary-purple-800  ${
              className ? className : ''
            }`}
            disableElevation
            {...props}
          >
            Apply Now{' '}
            <FontAwesomeIcon
              className="text-lg transition-all group-hover:translate-x-1	"
              icon={['fal', 'long-arrow-right']}
            ></FontAwesomeIcon>
          </Button>
        );

      case 'LISTER':
        return (
          <Button
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            type="submit"
            variant="contained"
            style={{
              borderRadius: '92px',
              height: '45px',
            }}
            className={`group flex items-center gap-x-2 whitespace-nowrap bg-lister-other-200 px-4 font-nunito-sans font-bold capitalize text-lister-purple-100  ${
              className ? className : ''
            }`}
            disableElevation
            {...props}
          >
            Apply Now{' '}
            <CTA_Arrow className="text-lg transition-all group-hover:translate-x-1	" />
            {/* <FontAwesomeIcon
              className="text-lg transition-all group-hover:translate-x-1	"
              icon={['fal', 'long-arrow-right']}
            ></FontAwesomeIcon> */}
          </Button>
        );

      case 'GEEK_TRUST':
        return (
          <Button
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            type="submit"
            variant="contained"
            style={{
              borderRadius: '92px',
              height: '45px',
            }}
            className={`group flex items-center gap-x-2 whitespace-nowrap bg-geek-trust-green-200 px-4 font-lato font-bold capitalize text-geek-trust-purple-200  ${
              className ? className : ''
            }`}
            disableElevation
            {...props}
          >
            Apply Now{' '}
            <GeekTrust_CTA_Arrow className="text-lg transition-all group-hover:translate-x-1	" />
            {/* <FontAwesomeIcon
              className="text-lg transition-all group-hover:translate-x-1	"
              icon={['fal', 'long-arrow-right']}
            ></FontAwesomeIcon> */}
          </Button>
        );
      default:
        return <></>;
    }
  };
  return getButton(props.secondaryType, className, props);
}
