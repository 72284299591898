import { Link } from 'gatsby';
import React from 'react';

/**
 * MenuLinks component renders a list of links.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.linksArray - Array of link objects to be rendered.
 * @returns {JSX.Element} - Returns the JSX for the MenuLinks component.
 */
const MenuLinks = ({ linksArray }) => {
  return (
    <div className="flex flex-col gap-2">
      {/* Map through the linksArray to render each link */}
      {linksArray.map((el) => {
        return (
          <Link
            to={el.link}
            key={el.title}
            className="transition-all hover:opacity-70"
          >
            {/* Render each link */}
            <div className="mb-2 flex justify-between gap-2 rounded-lg bg-v5-neutral-200 p-4 last:mb-0">
              <div className="pointer-events-none h-[30px] w-[30px]">
                {/* Render the icon */}
                {el.icon}
              </div>
              <div className="flex-1">
                {/* Render the title */}
                <h5 className="text-sm">{el.title}</h5>
                {/* Render the subtitle */}
                <p className="text-sm text-v5-neutral-400">{el.subtitle}</p>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

// Export MenuLinks component
export default MenuLinks;
