export const EProgramType = {
  SD: 'Software Development',
  QA: 'QA Automation (SDET)',
  DA: 'Data Analytics With AI',
};

export const EProgramTypeId = {
  SD: 'SD',
  QA: 'QA',
  DA: 'DA',
};

export const CampaignProgramOptions = [
  {
    id: 'SD',
    label: EProgramType.SD,
  },
  {
    id: 'QA',
    label: EProgramType.QA,
  },
  {
    id: 'DA',
    label: EProgramType.DA,
  },
];
