// extracted by mini-css-extract-plugin
export var active = "ProgramCardRevamped-module--active--8f934";
export var banner = "ProgramCardRevamped-module--banner--ed86d";
export var careerFooter = "ProgramCardRevamped-module--careerFooter--2d7c3";
export var customFont = "ProgramCardRevamped-module--customFont--158a8";
export var emailForm = "ProgramCardRevamped-module--emailForm--b769f";
export var innerDot = "ProgramCardRevamped-module--innerDot--ba703";
export var innerDotActive = "ProgramCardRevamped-module--innerDotActive--c4b5b";
export var inputControl = "ProgramCardRevamped-module--inputControl--efd60";
export var line = "ProgramCardRevamped-module--line--0e049";
export var milestoneCard = "ProgramCardRevamped-module--milestoneCard--2a4bb";
export var programCard = "ProgramCardRevamped-module--programCard--9225e";
export var skill = "ProgramCardRevamped-module--skill--22c59";
export var toggle = "ProgramCardRevamped-module--toggle--4480c";
export var toggleButton = "ProgramCardRevamped-module--toggleButton--35deb";
export var toggleMobile = "ProgramCardRevamped-module--toggleMobile--52c7a";