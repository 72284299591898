import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  business,
  careerPrograms,
  communityPrograms,
  crioLinks,
  email,
  socialLinks,
} from './data';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

const FooterV2 = ({ className, applicationPage, gradient, variant }) => {
  return (
    <section
      className={`flex flex-col items-center pt-4 pb-16 sm:pt-8 ${
        gradient
          ? 'bg-black text-v5-neutral-200'
          : variant === 'white'
          ? `${className} text-white`
          : className
          ? className
          : 'bg-v5-green-100 text-v5-neutral-600'
      }`}
    >
      <div className="container">
        <div className="mt-4 flex w-full flex-col items-center gap-2 rounded-xl ">
          <div className="flex w-full flex-col gap-4 md:flex-row  ">
            <div className="z-[999] md:w-2/4">
              {applicationPage ? (
                <StaticImage
                  loading="lazy"
                  src="../../images/crio-reboot/CrioLogo.png"
                  placeholder="none"
                  alt="Underline"
                  width={170}
                  imgStyle={{ objectFit: 'contain' }}
                />
              ) : gradient || variant === 'white' ? (
                <StaticImage
                  loading="lazy"
                  src="../../images/placements/FooterLogoWhite.png"
                  placeholder="none"
                  alt="Underline"
                  width={170}
                  imgStyle={{ objectFit: 'contain' }}
                />
              ) : (
                <StaticImage
                  loading="lazy"
                  src="../../images/placements/FooterLogo.png"
                  placeholder="none"
                  alt="Underline"
                  width={170}
                  imgStyle={{ objectFit: 'contain' }}
                />
              )}

              <div className="my-4 flex gap-2">
                <div className={`mt-1 h-[20px] w-[20px]`}>
                  {gradient || variant === 'white' ? (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/HomeWhite.png"
                      placeholder="none"
                      alt="Underline"
                      width={20}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  ) : (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/Home.png"
                      placeholder="none"
                      alt="Underline"
                      width={20}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  )}
                </div>
                <p className="z-[999] flex-1 font-rubik text-base font-normal md:text-sm">
                  20, 100 Feet Rd, Vivek Nagar,
                  <br className="hidden md:inline-block" /> Chandra Reddy
                  Layout, AVS Layout Ejipura,
                  <br className="hidden md:inline-block" /> Bengaluru, Karnataka
                  560095
                </p>
              </div>
              <div className="my-4 flex gap-2 ">
                <div className={`mt-1 h-[15px] w-[22px]`}>
                  {gradient || variant === 'white' ? (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/commentsQuestion-White.png"
                      placeholder="none"
                      alt="Underline"
                      width={22}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  ) : (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/commentsQuestion.png"
                      placeholder="none"
                      alt="Underline"
                      width={22}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  )}
                </div>
                <div>
                  <div className="z-[999] pt-[2px] font-manrope text-base font-bold capitalize md:text-sm">
                    {email.customer_queries_header}
                  </div>
                  <a
                    href={email.customer_queries_email_url}
                    className="z-[999] cursor-pointer font-rubik text-base font-normal underline md:text-sm"
                  >
                    {email.customer_queries_title}
                  </a>
                </div>
              </div>
              <div className="my-4 flex gap-2 ">
                <div className={`mt-1 h-[15px] w-[22px]`}>
                  {gradient || variant === 'white' ? (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/generalEnquiries-White.png"
                      placeholder="none"
                      alt="Underline"
                      width={22}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  ) : (
                    <StaticImage
                      loading="eager"
                      src="../../images/placements/Icons/generalEnquiries.png"
                      placeholder="none"
                      alt="Underline"
                      width={22}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  )}
                </div>
                <div>
                  <div className="z-[999] pt-[2px] font-manrope text-base font-bold capitalize md:text-sm">
                    {email.general_queries_header}
                  </div>
                  <a
                    href={email.general_queries_email_url}
                    className="z-[999] cursor-pointer font-rubik text-base underline md:text-sm"
                  >
                    {email.general_queries_title}
                  </a>

                  <p
                    className="z-[999] cursor-pointer font-rubik text-base md:text-sm"
                    onClick={() => {
                      GTM.track(gtmEvents.LINK_CLICK, {
                        type: 'PHONE_LINK',
                        location: 'FOOTER',
                      });
                    }}
                  >
                    <a href={`tel:${email.phone_number}`}>
                      Ph. {email.phone_number}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col justify-between gap-y-4 md:flex-row">
              <div className="z-[999]">
                {/* <FooterLinksCards title="Learning Programs" links={programs} /> */}
                <FooterLinksCards
                  title="Community Programs"
                  links={communityPrograms}
                />
              </div>
              <div className="z-[999]">
                <FooterLinksCards
                  title="Career Programs"
                  links={careerPrograms}
                />
                <FooterLinksCards title="For Business" links={business} />
              </div>
              <div className="z-[999]">
                <FooterLinksCards title="Crio" links={crioLinks} />
              </div>
            </div>
          </div>
          <div className="flex  w-full justify-center border-t-[1px] border-v5-green-200 py-4 text-sm md:justify-between">
            <p className="z-[999] hidden md:block">
              Copyright © {new Date().getFullYear()} Crio. All rights reserved.
            </p>
            <div className="z-[999] mb-4 flex gap-4 md:mb-0 md:gap-2">
              {socialLinks.map((social) => {
                return (
                  <a
                    href={social.url}
                    key={social.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={`h-[28px] w-[28px]`}>{social.image}</div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden">
        <h2>Placement Review</h2>
        <h2>QA Automation Testing Review</h2>
        <h2>Full Stack Review</h2>
        <h2>Quora Reviews</h2>
        <h2>Reddit Reviews</h2>
        <h2>Automation Testing Fee</h2>
        <h2>Full Stack Fee</h2>
        <h2>QA Automation Fees</h2>
        <h2>Fee Structure</h2>
        <h2>Course Price</h2>
      </div>
    </section>
  );
};

const FooterLinksCards = ({ title, links, className }) => {
  return (
    <div className={`${className} z-[999] mb-2 md:mb-8`}>
      <div className="z-[999] font-manrope text-base font-bold uppercase md:text-sm">
        {title}
      </div>
      <ul className="z-[999] my-2 flex list-none flex-col gap-1 p-0">
        {links.map((link) => (
          <li key={link.url} className="z-[999]">
            <a
              href={link.url}
              key={link.url}
              className="block font-rubik text-base font-normal md:text-sm"
            >
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterV2;
