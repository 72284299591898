import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';

const Card = ({ data, specializationType }) => {
  const image = data.image ? getImage(data.image.childImageSharp) : null;
  const spanToWidth = data?.spanToWidth;

  return (
    <div
      className={`${
        spanToWidth ? 'h-full w-full' : 'md:max-w-[48%]'
      } rounded-[20px] ${
        specializationType ? 'bg-v5-green-500' : 'bg-v5-green-700'
      }   text-v5-neutral-300  ${
        data?.title?.includes('iTunes') ? 'sm:order-last' : ''
      } ${data?.title ? 'p-4' : 'px-4 pb-4 pt-1'}`}
    >
      {image && (
        <div className="mb-4 w-[90px]">
          <GatsbyImage
            placeholder="blurred"
            loading="lazy"
            image={image}
            alt={'Curriculum'}
            style={{ maxHeight: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
      <h6 className="mb-2 text-white">
        <RawHTML>{data?.title}</RawHTML>
      </h6>
      <div className="md:text-md text-sm">
        <div className="mb-2 flex items-start gap-x-2">
          <div className="leading-relaxed">
            <RawHTML>{data?.description}</RawHTML>
          </div>
        </div>

        <div>
          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                >
                  <h6 className=" text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
