const projects = [
  {
    company: 'CRED',
    logo: 'Cred',
    title: 'Credit Card Management System',
    description: `Build a Credit Card Management system which can handle the entire lifecycle of a customer’s credit card, like adding and verifying credit card(s), fetching the credit card statement to generate a summary and extract insights, and making payments for the card. The system should have a UI which gives the customers a clean interface to use.<br><br>Take it to the next level by adding features like reminders for timely payments and rewards.
`,
  },

  {
    company: 'Jumbotail',
    logo: 'Jumbotail',
    title: 'GPS based Asset Tracking Portal',
    description: `Build an application to track the location of a company’s assets (trucks or salespeople) in a given area. The application should have a central dashboard displaying the current geographical location of all the assets, using a map overlay. The person monitoring the dashboard should be able to select a particular asset to view all its details.<br><br>Take it to the next level by implementing features such as geofencing, anomaly detection based on preset routes, and generating location data for pattern analysis.`,
  },
  {
    company: 'Groww',
    logo: 'Groww',
    title: 'Contextual Chat Bot for Investors',
    description: `Build a chat bot that displays FAQ and information based on an investor’s current context on the Groww website. The user can click on the bot on any web page and seek relevant information. The bot should make use of the investor’s id, category of choice (Stock, Mutual Funds, Gold, FD), web page selection (product pages or order pages), and other information to help the investor. The bot should use an extensible REST API based interface to fetch the relevant FAQ and information from a backend server. The bot should also be interactive and display information or FAQs based on the investor’s responses.`,
  },
  {
    company: 'Recko',
    logo: 'Recko',
    title: 'Accounting Integration Service',
    description: `Build an integration service which can connect to multiple accounting software like Xero and Quickbooks to fetch data and consume for financial accounting. There should also be an administrative dashboard to filter, search and display this data.<br><br>Take it to the next level and make the service robust by handling connection failures and other corner cases that need to be handled. Publish this as an Open Source project.`,
  },
  {
    company: 'Slice',
    logo: 'Slice',
    title: 'Doc Library with Digilocker Integration',
    description: `Build a library that supports upload of documents and images from DigiLocker and other popular data sources. The library should provide the necessary integration with DigiLocker. It should also have the ability to send these files to a backend which stores them with appropriate authentication and authorisation.<br><br>Take it to the next level by integrating with other data sources such as your mobile’s photo gallery, Google Drive etc. Add features like doc resizing and image cropping.`,
  },
  {
    company: 'Vicara',
    logo: 'Vicara',
    title: 'Storage Drive for Enterprises',
    description: `Implement a Storage Drive for digital assets which can be adopted by enterprises and integrated with their other systems. Existing services such as Google Drive or DropBox don’t provide this flexibility and also increase the storage cost. You will start by building the basic functionality like authentication, uploads, downloads, organizing (folders), viewing and deleting files on a user friendly interface. You will take it to the next level by making it scalable and implementing additional features like favourites, recent uploads, and link based sharing. You will also implement API based upload/download that enables customers to integrate it with their workflows and other systems.`,
  },
  {
    company: 'Jumbotail',
    logo: 'Jumbotail',
    title: 'Cash Flow Management App',
    description: `Build an application that enables small business owners to track all their credit and debit transactions in one place. The application should have the provision to enter individual transactions for each entity (vendor, customer etc.). It should be capable of pulling up historical transactions related to a particular entity or show the overall cash flow summary.<br><br>Take it to the next level by adding support for payment reminders, report generation, automatic updates when transactions are added etc.`,
  },

  {
    company: 'Recko',
    logo: 'Recko',
    title: 'OAuth Manager',
    description: `Build an OAuth Manager which provides granular permissions for external clients to access APIs offered by a company’s services. Companies will use this OAuth Manager to speed up their integration with Clients and provide them with access to specific APIs. <br><br>Take it to the next level by adding additional features like different authentication methods, rate limiting for requests, etc. Publish this as an Open Source project.`,
  },
  {
    company: 'AWS',
    logo: 'AWS',
    title: 'Task orchestrator & scheduler library',
    description: `Build an open source task orchestrator & scheduler library using AWS Lambda or Fargate. Companies have multiple workflows that consist of a set of asynchronous tasks that need to be executed in a timely manner. This is where a task orchestrator & scheduler comes in handy to execute these tasks without a manual intervention based on a particular schedule or a set of conditions.`,
  },
];

const companyDetails = {
  Slice: {
    company: 'Slice',
    about: `<strong>slice</strong> is a Fintech startup focused on Young India. We are building a smart, simple, and transparent financial platform to redesign the life experience for millennials. There are 400M+ millennials in India seeking experience and convenience in their day to day life. The rise of these digital natives and the strong emotional connections that they maintain with the brands they love gives us opportunities to create a new generation of financial products. Our flagship product, the slice Card, is a payment card designed for customers in the 18 – 29 age group and comes with a pre-approved credit line. Issued in partnership with Rupay, the slice Card can be used to make payments to merchants everywhere; online and offline. Today, there are more than 150,000 individuals who use slice for their daily transactions. 
`,
    industry: 'FinTech',
    website: 'sliceit.com',
    image: 'slice-detail',
  },
  CRED: {
    company: 'CRED',
    about: `CRED is an exclusive community for India’s most trustworthy and creditworthy individuals, where the members are rewarded for good financial behavior. CRED was born out of a need to bring back the focus on a long lost virtue, one of trust, the idea being to create a community centered around this virtue. a community that constantly strives to become more virtuous in this regard till they finally scale their behavior to create a utopia where being trustworthy is the norm and not the exception. to build a community like this requires a community of its own; a community special in its own way, working towards making this vision come true.`,
    industry: 'FinTech',
    website: 'cred.club',
    image: 'cred-detail',
  },
  Vicara: {
    company: 'Vicara',
    about: `At Vicara, we develop technology & solutions that augment the capability of humans to work with their digital surroundings. Instead of developing technologies that replace humans, we develop technologies that augment and improve them. We are currently working on a multitude of products that include an AR Headset, a productivity tracker as well as interfacing devices. The interested candidates will get the opportunity to work directly on these products and see the impact of their work directly on the customers. Additionally, the interested candidates will get the opportunity to take ownership of their work and grow along with the company, both personally as well as financially.`,
    industry: 'Wearable Technologies',
    website: 'vicara.co',
    image: 'vicara-detail',
  },
  Recko: {
    company: 'Recko',
    about: `Recko was started with the mission to help businesses manage their financial operations with agility, simplicity and innovation. Today’s businesses need a collaborative interlock between their finance, product and business functions to grow exponentially and stay ahead of the competition. Be it reconciliations, payment operations or complex commission calculations; Recko does it all.`,
    industry: 'SaaS, Enterprise Software',
    website: 'recko.io',
    image: 'recko-detail',
  },
  Jumbotail: {
    company: 'Jumbotail',
    about: `Jumbotail is a top tier VC funded startup, building an online wholesale marketplace for food and grocery, targeted at wholesale buyers. Our vision is to organise the food and grocery ecosystem in India using technology, data science and design. Jumbotail Marketplace offers a wide selection of products including staples, spices, dry fruits, packaged foods, and FMCG products. Sellers (traders, manufacturers, and brands) can list their food, grocery and FMCG products on Jumbotail Marketplace which is accessible to the customers through a mobile app available in 4 languages – Kannada, Malayalam, Hindi and English. Jumbotail provides full stack services including storefront delivery and payments collection for its sellers, as well as working capital credit from third party credit providers to its customers using rich transactional data and proprietary algorithms.
<br><br>
While our market potential is huge – in the order of hundreds of billions of dollars, the opportunity requires us to solve challenging problems that are so unique to India that copycat models will not work. We are putting our customers first and building technologies, products, platforms and services, and cutting edge supply chains that will work for the next billion Indians who have fundamentally different needs – access, language, selection, supply chain, financial, cultural, operating in distributed, chaotic and unstructured environments.`,
    industry: 'E-commerce, Grocery',
    website: 'jumbotail.com',
    image: 'jumbotail-detail',
  },
  Groww: {
    company: 'Groww',
    about: `Groww is an investing platform where users can find the best mutual funds to invest in and can invest their money without any hassles. Groww provides objective evaluation of mutual funds and does not advise or recommend any mutual fund or portfolios.<br><br>
Our mission is simple: make investing simple and accessible to everyone. When we started investing more than 10 years back, it took a considerable amount of time and effort to get started because of the complexity and the lack of transparency. When we look around today, things haven't changed at all. Investing is still a very complex thing and good financial advice is limited to only a select few. The stats say it all - in a country of 1.3 billion, there are less than 20 million investors, while people are losing billions of rupees by keeping it idle in the banks.<br><br>
Groww helps investors in the following way
<ul><li>By providing objective evaluation of products available on Groww</li>
<li>By bringing up red flags, if any, involved in the products. However Groww does not guarantee to bring out all red flags</li>
<li>By being transparent about fees and charges involved in investing in a product</li>
<li>By clearly representing the risk associated with buying a product</li></ul>
`,
    industry: 'FinTech',
    website: 'groww.in',
    image: 'groww-detail',
  },
  AWS: {
    company: 'AWS',
    about: `Amazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud platform, offering over 175 fully featured services from data centers globally. Millions of customers—including the fastest-growing startups, largest enterprises, and leading government agencies—are using AWS to lower costs, become more agile, and innovate faster.
`,
    industry: ' SaaS, Enterprise Software',
    website: 'aws.amazon.com',
    image: 'aws-detail',
  },
};

module.exports = { projects, companyDetails };
// export { projects, companyDetails };
