import React from 'react';

/**
 * ResponsiveLayout component renders different layouts based on the device type.
 * Parent Component: Navbar
 *
 * @param {boolean} isMobile - Indicates whether the layout is for mobile devices.
 * @param {boolean} dark - Indicates whether the theme is dark or light.
 * @param {JSX.Element} children - The children components to be rendered within the layout.
 * @returns {JSX.Element} - ResponsiveLayout component JSX.
 */

export default function ResponsiveLayout({ children, isMobile, dark }) {
  return isMobile ? (
    // Render layout for mobile devices
    <div className="fixed z-30 w-screen bg-white px-6 pb-6">
      <div className="my-6 flex flex-col gap-4 font-rubik text-xl font-bold">
        {children}
      </div>
    </div>
  ) : (
    // Render layout for non-mobile devices
    <div
      className={`flex h-[60px] w-full items-center justify-between px-6 md:h-[65px]  ${
        !dark ? 'bg-white text-black' : 'bg-v5-green-700 text-white'
      }`}
    >
      {children}
    </div>
  );
}
