import React from 'react';
import TopStrip from '@components/PlacementReportTopStrip/index';
import SaleTopStrip from '@components/v5/Sale/SaleTopStrip';

/**
 * TopStripComponent renders either SaleTopStrip or TopStrip based on the sale prop.
 *
 * @param {boolean} sale - Indicates whether the sale is active.
 * @param {object} program - The program data to be passed to the top strip component.
 * @returns {JSX.Element} - Returns the SaleTopStrip or TopStrip component.
 */

export const TopStripComponent = ({ sale, program }) => {
  return (
    // Render SaleTopStrip if sale is true, otherwise render TopStrip
    sale ? <SaleTopStrip program={program} /> : <TopStrip />
  );
};

export default TopStripComponent;
