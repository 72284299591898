import RoundedLayout from '@components/v5/layouts/RoundedLayout';
import ProjectsSection from '@components/v5/ProjectsSection';
import React from 'react';
import { projectsData } from '@src/constants/projects';

function CertGenProjectsSection({ programType }) {
  const { DATA_ANALYTICS, SOFTWARE_DEVELOPMENT, QA_AUTOMATION } = projectsData;
  const data =
    programType === 'DATA_ANALYTICS'
      ? DATA_ANALYTICS
      : programType === 'QA_AUTOMATION_TESTING'
      ? QA_AUTOMATION
      : SOFTWARE_DEVELOPMENT;

  return (
    <RoundedLayout roundedBg="my-10" className="bg-white text-black" both>
      <div className="min-h-[800px] py-10">
        <ProjectsSection
          heading={data.projectTitle}
          headingClassName="text-[42px]"
          description={data.projectDescription}
          projects={data.projects}
        ></ProjectsSection>
      </div>
    </RoundedLayout>
  );
}

export default CertGenProjectsSection;
