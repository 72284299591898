import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoplay, Navigation } from 'swiper';
import * as styles from './VideoTestimonialCarousel.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import ScrollFade from '@benestudioco/react-scrollfade';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import { useDialog } from '../Dialog/index';
import VideoPopup from '@components/Fellowship/VideoPopup/index';
import { shuffle } from 'lodash';
import useResizer from '@components/extra/useResizer';
import { cn } from '@/lib/utils';

const VideoTestimonialCarousel = React.memo(function VideoTestimonialCarousel(
  props,
) {
  const dark = props.dark;
  const gradient = props.gradient;
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);
  const isTypeQa = props.isTypeQa;
  const isTypeFellowShipPlus = props.isTypeFellowShipPlus;
  const numberOfSlides = props.numberOfTestimonials;

  const data = useStaticQuery(graphql`
    query {
      allStrapiTestimonialWithQuotes {
        ...allStrapiTestimonialWithQuotesFragment
      }
      allYoutubeThumbnail: allFile(
        filter: { relativePath: { regex: "images/strapi/thumbnails/" } }
      ) {
        ...allYoutubeThumbnailFragment
      }
      allStrapiCompanies {
        ...allStrapiCompaniesFragment
      }
    }
  `);

  const getTestimonials = () => {
    if (isTypeQa) {
      return data.allStrapiTestimonialWithQuotes.nodes.filter(
        (testimonial) => testimonial.type === 'QA',
      );
    } else {
      return data.allStrapiTestimonialWithQuotes.nodes;
    }
  };

  const testimonials = getTestimonials() || [];

  const allYoutubeThumbnail = data.allYoutubeThumbnail.nodes;

  const companiesData = data.allStrapiCompanies.nodes.reduce((result, obj) => {
    result.push({
      id: obj.strapiId,
      name: obj.Name,
      url: obj.Logo.localFile,
    });
    return result;
  }, []);

  let testimonialQuotesData = testimonials.reduce((result, obj) => {
    result.push({
      from: obj.graduate.From,
      to: obj.graduate.To,
      name: obj.graduate.Name,
      linkedin: obj.LinkedInLink,
      companyLogo: companiesData.find((val) => val.id == obj.graduate.company)
        ?.url,
      iFrame: obj.YoutubeVideoLink,
      quote: obj.Quote,
      thumbnail: obj.thumbnail,
    });
    return result;
  }, []);

  testimonialQuotesData = testimonialQuotesData.filter((el) => el.thumbnail);

  const settings = {
    slidesPerView: 1,
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      769: {
        slidesPerView: numberOfSlides ? numberOfSlides : 3,
      },
    },
    loop: true,
    spaceBetween: 20,
    speed: 300,
    grabCursor: false,
    noSwiping: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  };

  return (
    <div
      className="relative w-full"
      onMouseLeave={(e) => {
        // swiperRef?.current?.swiper?.autoplay?.start();
      }}
    >
      <Swiper
        ref={swiperRef}
        {...settings}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Autoplay]}
        className={``}
      >
        {shuffle(testimonialQuotesData).map((testimonial, idx) => {
          const image = getImage(testimonial.thumbnail?.localFile);

          const companyLogo = getImage(testimonial.companyLogo);

          return (
            <SwiperSlide
              key={testimonial.name?.trim()}
              className="px-6 sm:px-0"
            >
              <VideoTestimonialSlide
                gradient={gradient}
                dark={dark}
                swiperRef={swiperRef}
                companyLogo={companyLogo}
                image={image}
                youtube={testimonial.iFrame}
                name={testimonial.name?.trim()}
                linkedin={testimonial.linkedin?.trim()}
                quote={testimonial.quote?.trim()}
                from={testimonial.from?.trim()}
                to={testimonial.to?.trim()}
              ></VideoTestimonialSlide>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        onClick={() => swiperRef.current.swiper.slidePrev()}
        ref={navigationPrevRef}
        className={`${
          dark ? 'text-white' : 'text-black'
        } absolute top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer  hover:opacity-75 sm:-left-10`}
      >
        <FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
      </div>{' '}
      <div
        onClick={() => swiperRef.current.swiper.slideNext()}
        ref={navigationNextRef}
        className={`${
          dark ? 'text-white' : 'text-black'
        } absolute top-1/2 right-0 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 sm:-right-10`}
      >
        <FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon>
      </div>
    </div>
  );
});

export default VideoTestimonialCarousel;

const VideoTestimonialSlide = ({
  swiperRef,
  companyLogo,
  name,
  quote,
  image,
  linkedin,
  from,
  to,
  youtube,
  gradient,
  dark,
}) => {
  const [openDialog, closeDialog] = useDialog();
  const isMobile = useResizer();

  const openVideo = (video) => {
    openDialog({
      children: (
        <VideoPopup
          video={video}
          closeDialog={() => {
            closeDialog();
            swiperRef?.current?.swiper?.autoplay?.start();
          }}
        ></VideoPopup>
      ),
    });
  };

  return (
    <div className="relative px-2 pb-10">
      <div
        className={cn(
          `${styles.testimonial} relative overflow-hidden rounded-t-[50px] leading-relaxed sm:h-[500px]`,
          `${dark ? 'text-v5-neutral-300' : 'text-v5-green-700'}`,
        )}
      >
        <div
          className={`flex min-h-[400px] flex-col ${
            gradient
              ? 'gradient-card-dark border-gradient border-gradient-radius50top'
              : dark
              ? 'bg-v5-green-500'
              : 'bg-v5-green-100'
          } p-2  sm:h-[400px]`}
        >
          <div className="mb-2 w-full py-2 px-2">
            <div
              className="group relative h-[180px] w-full cursor-pointer overflow-hidden rounded-[40px]"
              onClick={() => {
                openVideo(youtube);
                swiperRef?.current?.swiper?.autoplay?.stop();
              }}
              style={{
                WebkitMaskImage: '-webkit-radial-gradient(white, black)',
              }}
              // onMouseLeave={(e) => {
              //   swiperRef?.current?.swiper?.autoplay?.start();
              // }}
            >
              <div className={styles.overlay}></div>

              <FontAwesomeIcon
                onMouseEnter={(e) => {
                  swiperRef?.current?.swiper?.autoplay?.stop();
                }}
                icon={['fas', 'play']}
                size="2x"
                className=" inset-center absolute left-0 right-0 z-20 mx-auto text-white transition-all group-hover:opacity-50"
              ></FontAwesomeIcon>

              <GatsbyImage
                loading="lazy"
                image={image}
                layout="fullWidth"
                placeholder="blurred"
                alt={'Video Testimonial of ' + name}
                imgStyle={{ objectFit: 'contain' }}
                style={{ width: '100%', transform: 'scale(1.4)' }}
              />
            </div>
          </div>
          <div className="px-5">
            <div className="mb-2 flex items-start justify-between gap-y-2">
              <h5 className={cn('font-manrope ')}>Meet {name}</h5>
              <a
                onMouseEnter={(e) => {
                  swiperRef?.current?.swiper?.autoplay?.stop();
                }}
                // onMouseLeave={(e) => {
                //   swiperRef?.current?.swiper?.autoplay?.start();
                // }}
                className="mt-1 w-[24px] transition-all hover:opacity-50"
                href={linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  loading="lazy"
                  src="../../../images/v5/linkedin-circle.png"
                  placeholder="blurred"
                  width={50}
                  alt="Linkedin"
                />
              </a>
            </div>
            <div
              className={`mb-6 overflow-y-auto sm:max-h-[110px]`}
              onScroll={(e) => {
                swiperRef?.current?.swiper?.autoplay?.stop();
              }}
            >
              {!isMobile && <ScrollFade />}
              <p>“{quote}”</p>
            </div>
          </div>
        </div>
        <div
          className={cn(
            'flex h-[100px] flex-col items-center justify-center rounded-b-[50px]  px-5 py-3 ',
            `${
              dark
                ? 'bg-v5-green-100 text-v5-neutral-500'
                : 'bg-[#DAF1EA] text-v5-green-700'
            }`,
          )}
        >
          <p className="text-sm"> {name?.split(' ')[0]} cracked a career at </p>
          <div
            style={{ maxHeight: '100%', height: '60px', width: '120px' }}
            className="flex items-center justify-center"
          >
            <GatsbyImage
              loading="lazy"
              image={companyLogo}
              alt={'company'}
              width={120}
              className="z-10"
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>{' '}
      <div className="flex w-full justify-center">
        <div
          className={cn(
            'left-0 right-0 -bottom-4 z-0 mx-auto -mt-[28px] h-[45px]  w-[45px] rotate-45 rounded-[10px]',
            `${dark ? 'bg-v5-green-100' : 'bg-[#DAF1EA]'}`,
          )}
        ></div>
      </div>
      <div className=" mt-[20px]">
        <div className="flex w-full justify-center">
          <div
            className={cn(
              ' left-0 right-0 -bottom-4 z-0 mx-auto -mb-[20px] h-[30px]  w-[30px] rotate-45 rounded-[10px]',
              `${dark ? 'bg-v5-green-100' : 'bg-[#DAF1EA]'}`,
            )}
          ></div>
        </div>
        <div
          className={cn(
            'z-10 flex w-full items-center justify-center gap-x-2 rounded-[55px] p-4 font-bold shadow-xl ',
            `${
              dark ? 'bg-v5-green-100 shadow-v5-green-100/50' : 'bg-[#DAF1EA]'
            }`,
          )}
        >
          <h6 className="z-20 text-right  text-xs xxs:text-sm">{from}</h6>
          <div className="w-[24px]">
            <StaticImage
              loading="lazy"
              src="../../../images/v5/testimonial-arrow.png"
              placeholder="none"
              width={40}
              alt="Journey"
            />{' '}
          </div>
          <h6 className="z-20 text-left text-xs xxs:text-sm">{to}</h6>
        </div>
      </div>
    </div>
  );
};
