import React from 'react';
import SectionLayout from '../layouts/SectionLayout/index';
import FspCarousel from '../FspCurriculumSection/FspCarousel/index';

const SkillSection = React.memo(function SkillSection(props) {
  console.log('🚀 ~ SkillSection ~ props:', props.dreamCompanies);
  const { heading, description } = props;

  return (
    <SectionLayout
      heading={
        <span
          className={props.dark ? 'text-v5-neutral-200' : 'text-v5-neutral-600'}
        >
          {heading ? heading : 'Master Data Insights with Key Analytics Tools'}
        </span>
      }
      description={
        description ? (
          description
        ) : (
          <span className={props.dark ? '' : 'text-v5-neutral-500'}>
            Learn 15+ data analytics tools, including Git, Kafka, SQL, Excel,
            and Python
          </span>
        )
      }
      {...props}
    >
      <div className="container relative z-[999]">
        <FspCarousel data={props.dreamCompanies} {...props}></FspCarousel>
      </div>
    </SectionLayout>
  );
});

export default SkillSection;
