import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';
import { cn } from '@/lib/utils';

const CurriculumHeader = ({ data, isLightTheme, className }) => {
  const image = getImage(data?.image);

  const getBadge = (type) => {
    switch (type) {
      case 'FREE_TRIAL':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-yellow-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Free Trial
          </div>
        );
      case 'OPTIONAL':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-yellow-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Optional
          </div>
        );
      case 'OPTION_1':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-lime-400 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Option 1
          </div>
        );
      case 'OPTION_2':
        return (
          <div className="mb-3 w-max min-w-[80px] rounded-[5px] bg-v5-lime-200 p-1 px-2 text-center text-xs font-semibold uppercase text-v5-neutral-600">
            Option 2
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={cn(className)}>
      <div>{getBadge(data?.tag)}</div>
      <div className="flex items-start gap-x-4">
        <div className="w-[40px]  min-w-[40px]">
          {data?.staticImage ? (
            data.staticImage
          ) : (
            <GatsbyImage
              placeholder="blurred"
              loading="lazy"
              image={image}
              alt={'Curriculum'}
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </div>
        <div>
          <h6
            className={
              (`transition-all  sm:w-full`,
              `${isLightTheme ? 'text-v5-neutral-600' : ''}`)
            }
          >
            <RawHTML>{data?.title}</RawHTML>
          </h6>
          <p
            className={cn(
              'text-sm',
              `${
                isLightTheme
                  ? 'font-medium text-v5-neutral-500'
                  : 'text-v5-neutral-300'
              }`,
            )}
          >
            {data?.duration}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurriculumHeader;
