import React, { useState } from 'react';

const calculateSeatsLeft = (
  startDateAndTime,
  endDateAndTime,
  seats,
  isDynamic,
) => {
  if (!isDynamic) return seats;

  const totalDuration = new Date(endDateAndTime) - new Date(startDateAndTime);
  const currentTime = new Date();
  const timeElapsed = currentTime - new Date(startDateAndTime);

  if (currentTime >= new Date(endDateAndTime)) {
    return 0;
  }

  const initialPhaseDuration = totalDuration * 0.2;
  const intermediatePhaseDuration = totalDuration * 0.6;
  const finalPhaseDuration = totalDuration * 0.2;

  const initialSeatsToReduce = Math.floor(seats * 0.4);
  const intermediateSeatsToReduce = Math.floor(seats * 0.4);
  const finalSeatsToReduce =
    seats - (initialSeatsToReduce + intermediateSeatsToReduce);

  let seatsReduced = 0;

  if (timeElapsed <= initialPhaseDuration) {
    seatsReduced = Math.floor(
      (timeElapsed / initialPhaseDuration) * initialSeatsToReduce,
    );
  } else if (timeElapsed <= initialPhaseDuration + intermediatePhaseDuration) {
    const intermediateTimeElapsed = timeElapsed - initialPhaseDuration;
    seatsReduced =
      initialSeatsToReduce +
      Math.floor(
        (intermediateTimeElapsed / intermediatePhaseDuration) *
          intermediateSeatsToReduce,
      );
  } else if (timeElapsed <= totalDuration) {
    const finalTimeElapsed =
      timeElapsed - initialPhaseDuration - intermediatePhaseDuration;
    seatsReduced =
      initialSeatsToReduce +
      intermediateSeatsToReduce +
      Math.floor((finalTimeElapsed / finalPhaseDuration) * finalSeatsToReduce);
  }

  return Math.max(seats - seatsReduced, 0);
};

const SeatCount = ({
  startDateAndTime,
  endDateAndTime,
  seats,
  isDynamic,
  isBold,
  color,
}) => {
  const [seatsLeft, setSeatsLeft] = useState(() =>
    calculateSeatsLeft(startDateAndTime, endDateAndTime, seats, isDynamic),
  );
  return (
    <span className={isBold ? 'font-bold' : ''} style={{ color: color }}>
      &nbsp;| {seatsLeft} Seats {isDynamic && 'Left'}
    </span>
  );
};

export default SeatCount;
