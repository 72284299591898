import React from 'react';
import * as styles from './InfoCard.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RawHTML from '@components/Common/RawHTML/index';

export default function InfoCard({ icon, title, description, dark, gradient }) {
  let image = '';
  if (icon?.childImageSharp) {
    image = getImage(icon);
  }

  const getCardClass = () => {
    let className =
      'relative w-full p-5 md:p-6 sm:w-auto sm:max-w-[320px] flex md:flex-col gap-3 gap-y-3 rounded-[30px]';

    if (gradient) {
      className = className.concat(
        ' gradient-card-dark border-gradient border-gradient-radius20 shadow-md blur-[5px]',
      );
    } else if (dark) {
      className = className.concat(' bg-v5-green-500 ');
    } else {
      className = className.concat(' bg-[#E6F4F2] ');
    }

    if (styles.card) {
      className = className.concat(styles.card);
    }

    return className;
  };

  return (
    <div className={getCardClass()}>
      <div
        className={`${
          gradient &&
          'flex h-[100px] w-[100px] items-center justify-center rounded-xl bg-black bg-opacity-40 backdrop-blur-lg'
        }`}
      >
        <div
          className={`flex ${
            gradient ? 'h-[60px] w-[60px]' : 'w-[60px]'
          } items-center justify-center`}
        >
          {React.isValidElement(icon) ? (
            icon
          ) : (
            <GatsbyImage
              loading="lazy"
              image={image}
              alt={'Highlight'}
              style={{ maxHeight: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {title && (
          <div
            className={`font-manrope font-semibold ${
              dark ? 'text-v5-green-100' : 'text-v5-neutral-600'
            }`}
          >
            <RawHTML>{title}</RawHTML>
          </div>
        )}
        {description && (
          <p
            className={`${
              dark
                ? 'text-v5-neutral-300'
                : 'text-v5-neutral-500 [&_strong]:text-v5-neutral-600'
            } leading-relaxed`}
          >
            <RawHTML>{description}</RawHTML>
          </p>
        )}
      </div>
    </div>
  );
}
