import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import MenuLinks from '../../MenuLinks/index';
import styled from 'styled-components';

/**
 * AccordionComponent renders an accordion component with customizable content.
 *
 * @param {string} name - The name of the accordion.
 * @param {string} id - The unique identifier of the accordion.
 * @param {string} className - Additional class name for the accordion.
 * @param {array} linksArray - Array of links to be displayed within the accordion.
 * @param {object} style - Inline style object for the accordion.
 * @param {string} expanded - Identifier of the currently expanded accordion.
 * @param {function} handleChange - Function to handle accordion expansion changes.
 * @returns {JSX.Element} - AccordionComponent JSX.
 */
export default function AccordionComponent({
  name,
  id,
  className,
  linksArray,
  style,
  expanded,
  handleChange,
}) {
  return (
    <AccordionLayout>
      <StyledAccordion
        className="programsAccordion"
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary
          expandIcon={
            <FontAwesomeIcon
              icon={['fal', 'angle-down']}
              size={'lg'}
            ></FontAwesomeIcon>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={style}
          className={className}
        >
          <Typography className="font-rubik text-xl font-bold">
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="my-2 p-0">
          <MenuLinks linksArray={linksArray} />
        </AccordionDetails>
      </StyledAccordion>
    </AccordionLayout>
  );
}

/**
 * AccordionLayout component defines the layout for the AccordionComponent.
 *
 * @param {React.ReactNode} children - The child components to be rendered within the AccordionLayout.
 * @returns {JSX.Element} - AccordionLayout component JSX.
 */
function AccordionLayout({ children }) {
  return (
    <div className="programs flex items-center justify-between">{children}</div>
  );
}

/**
 * StyledAccordion component applies custom styles to the MUI Accordion component using styled-components.
 */
const StyledAccordion = styled(Accordion)`
  &.programsAccordion {
    box-shadow: none;
    width: 100%;
  }

  & .programAccordionSummary .MuiAccordionSummary-content {
    margin: 0;
  }

  & .dropdown {
    box-shadow: none;
  }
`;
