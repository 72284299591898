import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from './Accordion';
import RawHTML from '@components/Common/RawHTML/index';
import { GatsbyImage } from 'gatsby-plugin-image';

const FAQCustomAccordion = ({
  data,
  expanded,
  handleChange,
  classes,
  image,
}) => {
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[10px] bg-v5-green-700`}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-center gap-x-4">
            <div className="w-[40px]  min-w-[40px]">
              <GatsbyImage
                placeholder="blurred"
                loading="lazy"
                image={image}
                alt={'Curriculum'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div>
              <h6 className={`text-base transition-all sm:w-full`}>
                <RawHTML>{data?.question}</RawHTML>
              </h6>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <div className="md:pl-[20px]">
          <p>
            <RawHTML>{data?.answer}</RawHTML>
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQCustomAccordion;
