import { CampaignProgramOptions } from '@src/constants/campaign';

export function getSelectedProgram(programInterested) {
  if (programInterested) {
    if (programInterested === 'Software Development') {
      return CampaignProgramOptions[0];
    } else if (programInterested === 'QA Automation') {
      return CampaignProgramOptions[1];
    } else if (programInterested === 'Data Analytics') {
      return CampaignProgramOptions[2];
    } else {
      return CampaignProgramOptions[0];
    }
  } else {
    return CampaignProgramOptions[0];
  }
}
