export const programTabOptions = [
  { id: 'All', name: 'All' },
  { id: 'Software Development', name: 'Software Development' },
  { id: 'QA Automation', name: 'QA Automation' },
  { id: 'Data Analytics', name: 'Data Analytics' },
];

export const EEventType = {
  UPCOMING_EVENTS: 'Upcoming Events',
  PAST_EVENTS: 'Past Events',
};

export const UPCOMING_EVENTS_SECTION_ID = 'upcoming-events';
export const PAST_EVENT_SECTION_ID = 'past-events';

export const EEventDisplayType = {
  GRID: 'grid',
  SWIPER: 'swiper',
};

export const landingPageDefaultTitle =
  '<div class="text-[#ffffff]">Crio Masterclass</div><div class="text-[#2DF8C5]">Learn, Build and Master</div>';

export const landingPageDefaultSubtitle =
  '<div class="text-[#ffffff]">Master the fundamentals with our interactive session and build real-life projects guided by our expert mentors.&nbsp;Also, receive a certificate to highlight in your resume post-project completion.</div>';
